<template>
  <v-container class="home">
    <Carousel></Carousel>
    <RecentNews
      v-if="settings.enable_news && check"
      :notices="notices.data"
      @load-all-news="loadAllNews"
      :loadComplete="allNews"
    ></RecentNews>
    <RelatedEvents
      v-if="settings.enable_events && check"
      :events="events.data"
      @load-all-events="loadAllEvents"
      :loadComplete="allEvents"
    ></RelatedEvents>
    <v-lazy
      :options="{
        threshold: 0.8
      }"
      min-height="200"
      transition="fade-transition"
      origin="center center"
    >
      <CommunityInfo
        v-if="settings.enable_posts && check"
        :CommunityData="CommunityData"
        :posts="posts.data" :events="events"
        @liked="fetchLimitedPosts"
      ></CommunityInfo>
    </v-lazy>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/Carousel.vue'
import RecentNews from '@/components/RecentNews.vue'
import RelatedEvents from '@/components/RelatedEvents.vue'
import CommunityInfo from '@/components/CommunityInfo.vue'
import CommunityData from '@/data/community-info.json'
import { mapActions, mapGetters } from 'vuex'

export default {
  title: 'National Society',
  metaInfo: {
    title: 'National Society'
  },
  name: 'Home',
  components: {
    Carousel,
    RecentNews,
    RelatedEvents,
    CommunityInfo
  },
  data: () => ({
    CommunityData,
    overlay: false,
    allNews: false,
    allEvents: false,
    check:false
  }),
  computed: {
    ...mapGetters('user',{
      user: 'getUser',
      posts: 'getPosts'
    }),
    ...mapGetters('JWT',[
      'loggedIn',
      'access_token'
    ]),
    ...mapGetters('event', {
      events: 'getEvents',
      notices: 'getNotices'
    }),
    ...mapGetters('settings', {
      settings: 'getSettings'
    })
  },
  methods: {
    ...mapActions('user', [
      'fetchUser',
      'fetchPosts',
      'fetchLimitedPosts'
    ]),
    ...mapActions('event', [
      'fetchEvents',
      'fetchLimitedEvents',
      'fetchNotices',
      'fetchLimitedNotices'
    ]),
    loadAllNews() {
      this.fetchNotices().then(() => {
        this.allNews = true
      }).catch(() => {
        this.allNews = false
      })
    },
    loadAllEvents() {
      this.fetchEvents().then(() => {
        this.allEvents = true
      }).catch(() => {
        this.allEvents = false
      })
    },
  },
  mounted() {
    this.overlay = true
    if(this.loggedIn && this.access_token) {
      this.fetchUser()
    }
    this.fetchLimitedNotices()
    this.fetchLimitedPosts()
    this.fetchLimitedEvents().then(()=>{
      this.check=true     
    })
  }
}
</script>

<style scoped>

</style>
