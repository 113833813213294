import Vue from 'vue'
import ApiService from '../../services/api.service'
// import { TokenService } from '@/services/token.service'
// import _ from 'lodash'

const state = {
  payment_verify: false,
  societies: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  society: {}
}

const mutations = {
  setPaymentVerify(state, status) {
    Vue.set(state, 'payment_verify', status)
  },
  setSocieties(state, societies) {
    Vue.set(state, 'societies', {...societies})
  },
  setSociety(state, society) {
    Vue.set(state, 'society', society)
  }
}

const actions = {
  createSociety(_, registration) {
    return ApiService.post('/society-api/v-1/create-society/', registration)
  },
  checkRegistrationValidity(_, { registration }) {
    return ApiService.post('/society-api/v-1/check-registration-validity/', registration)
  },
  checkPaymentVerification({commit}) {
    return ApiService.get('/society-api/v-1/payment-verification/').then((response) => {
      commit('setPaymentVerify', response.data[0].is_paid)
    })
  },
  fetchSocieties({commit}) {
    return ApiService.get('/society-api/v-1/get-all-societies/').then((response) => {
      commit('setSocieties', response.data)
    })
  },
  fetchSocietiesWithPage({ commit }, page) {
    return ApiService.get(`/society-api/v-1/get-all-societies/?page=${page}`).then((response) => {
      commit('setSocieties', response.data)
    })
  },
  fetchSocietiesWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setSocieties', response.data)
    })
  },
  fetchSociety({commit}) {
    return ApiService.get('/society-api/v-1/get-society-details/').then((response) => {
      commit('setSociety', response.data)
    })
  },
  updateSocietyDisable(_, disable) {
    return ApiService.post('/society-api/v-1/update-society-disable/', disable)
  },
}

const getters = {
  getPaymentVerify: state => state.payment_verify,
  getSocieties: state => state.societies,
  getSociety: state => state.society
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
