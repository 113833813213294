import Vue from 'vue'
import ApiService from '../../services/api.service'
// import { TokenService } from '@/services/token.service'
import _ from 'lodash'

const state = {
  events: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  event: {},
  notices: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  notice: {}
}

const mutations = {
  setEvents(state, events) {
    Vue.set(state, 'events', {...events})
  },
  setEvent(state, event) {
    Vue.set(state, 'event', {...event})
  },
  setEventProperty(state, {property, value}) {
    Vue.set(state.event, property, value)
  },
  setNotices(state, notices) {
    Vue.set(state, 'notices', { ...notices })
  },
  setNotice(state, notice) {
    Vue.set(state, 'notice', { ...notice })
  },
  setNoticeProperty(state, {property, value}) {
    Vue.set(state.notice, property, value)
  },
}

const actions = {
  fetchEvents({ commit }) {
    return ApiService.get('/events-api/v-1/events/').then((response) => {
      commit('setEvents', response.data)
    })
  },
  fetchEventsWithPage({ commit }, page) {
    return ApiService.get(`/events-api/v-1/events/?page=${page}`).then((response) => {
      commit('setEvents', response.data)
    })
  },
  fetchEventsWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setEvents', response.data)
    })
  },

  fetchLimitedEvents({ commit }) {
    return ApiService.get('/events-api/v-1/limited-events/').then((response) => {
      commit('setEvents', response.data)
    })
  },

  fetchEventById({ commit }, event_id) {
    return ApiService.get(`/events-api/v-1/events/${event_id}`).then((response) => {
      commit('setEvent', response.data)
    })
  },

  postEvents({ dispatch }, data) {
    return ApiService.post('/events-api/v-1/events/', data).then(() => {
      dispatch('fetchEvents')
    })
  },

  updateEvents({ dispatch }, data) {
    return ApiService.post('/events-api/v-1/update-event/', data).then(() => {
      dispatch('fetchEvents')
    })
  },

  deleteEvents({ dispatch }, data) {
    return ApiService.post('/events-api/v-1/delete-event/', data).then(() => {
      dispatch('fetchEvents')
    })
  },


  // News/Information
  fetchNotices({ commit }) {
    return ApiService.get('/events-api/v-1/notice/').then((response) => {
      commit('setNotices', response.data)
    })
  },
  fetchNoticesWithPage({ commit }, page) {
    return ApiService.get(`/events-api/v-1/notice/?page=${page}`).then((response) => {
      commit('setNotices', response.data)
    })
  },
  fetchNoticesWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setNotices', response.data)
    })
  },

  fetchLimitedNotices({ commit }) {
    return ApiService.get('/events-api/v-1/limited-notice/').then((response) => {
      commit('setNotices', response.data)
    })
  },

  fetchNoticeById({ commit }, news_id) {
    return ApiService.get(`/events-api/v-1/notice/${news_id}`).then((response) => {
      commit('setNotice', response.data)
    })
  },

  addNotice({ dispatch }, data) {
    return ApiService.post('/events-api/v-1/notice/', data).then(() => {
      dispatch('fetchNotices')
    })
  },

  updateNotice({ dispatch, getters }) {
    let formData = new FormData()

    if (typeof getters.getNotice.media_file === 'string') {
      for (let key in _.omit(getters.getNotice, 'media_file')) {
        formData.append(key, getters.getNotice[key])
      }
    } else {
      for (let key in getters.getNotice) {
        formData.append(key, getters.getNotice[key])
      }
    }
    return ApiService.post('/events-api/v-1/update-notice/',formData).then(() => {
      dispatch('fetchNotices')
    })
  },

  deleteNotice({ dispatch }, data) {
    return ApiService.post('/events-api/v-1/delete-notice/', data).then(() => {
      dispatch('fetchNotices')
    })
  },
}

const getters = {
  getEvents: state => state.events,
  getEvent: state => state.event,
  getNotices: state => state.notices,
  getNotice: state => state.notice
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
