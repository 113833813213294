<template>
  <v-container class="pt-8 ml-0 pl-0">
    <v-card elevation="0" class="ml-0 pl-0">
      <v-row no-gutters align="center" justify="start">
        <v-card-title class="text-h5 font-weight-bold text-uppercase ml-0 pl-0" style="color: #62608b">Fees Status
        </v-card-title>
      </v-row>
      <v-row no-gutters justify="space-around" class="">
        <v-col cols="4">
          <span>From Date</span>
        </v-col>
        <v-col cols="4">
          <span>To Date</span>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row no-gutters class="mb-3">
        <v-col cols="4" class="">
          <DatePicker :rounded="'rounded'" :label="'From Date *'" :date_given="firstDateOfCurrentMonth"
            @get-date="selectedFromDate($event)"></DatePicker>
        </v-col>
        <v-col cols="4" class="pl-2">
          <DatePicker :rounded="'rounded'" :label="'To Date *'" @get-date="selectedToDate($event)"></DatePicker>
        </v-col>
        <v-col cols="4" class="pl-2">
          <v-btn @click="checkPaymentStatus" color="#67AC5B" class="white--text rounded mr-2" width="48%">Go</v-btn>
          <v-btn @click="resetPage" color="primary" class="white--text rounded " width="48%">Reset</v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col cols="8">
          <v-expansion-panels focusable flat hover light class="expansion-panels">
            <v-expansion-panel v-for="(due, index) in unpaid" :key="index">
              <v-expansion-panel-header hide-actions class="px-0">
                <v-alert class="ma-0 d-flex justify-center alter-msg" dense text type="error" color="warning" outlined
                  rounded>
                  <span>You have due payment for the month of <strong>{{getMonthString(due.payment_month)}}
                      {{due.payment_year}}</strong>
                  </span>
                  <span class="ml-4">
                    <v-btn class="white--text rounded btn-width mr-2" color="#67AC5B" @click="reisdent_payment(due.id)">
                      Pay Now
                    </v-btn>
                  </span>
                </v-alert>
              </v-expansion-panel-header>
              <!-- <v-expansion-panel-content>
                <v-card elevation="0" v-for="(item, index) in fees" :key="index">
                  <v-row no-gutters justify="center">
                    <v-col cols="6" class="d-flex justify-center">
                      <span class="header-text text-capitalize">{{index}}</span>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center">
                      <span class="header-text text-capitalize">
                        <v-icon v-text="currencyIcon"></v-icon>{{item}}
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content> -->
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <!-- <v-row v-if="payments.length>0" no-gutters align="center" justify="center">
        <v-card-title class="text-h5 font-weight-bold text-uppercase">Your recent payments</v-card-title>
      </v-row>
      <v-row no-gutters align="center" justify="center">
        <v-expansion-panels focusable popout flat hover light class="expansion-panels">
          <v-expansion-panel v-for="(payment, index) in payments" :key="index">
            <v-expansion-panel-header hide-actions class="rounded-pill">
              <v-alert dense class="ma-0 d-flex justify-center" text type="success" outlined rounded="pill">
                <span>{{getMonthString(Number(payment.payment_month))}} {{payment.payment_year}}</span>
              </v-alert>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card elevation="0" v-for="(item, index) in payment.amount_break_up" :key="index">
                <v-row no-gutters justify="center">
                  <v-col cols="6" class="d-flex justify-center">
                    <span class="header-text text-capitalize">{{index}}</span>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-center">
                    <span class="header-text text-capitalize">
                      <v-icon v-text="currencyIcon"></v-icon>{{item}}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row> -->
      <v-row align="start" justify="start" class="mb-12">
        <v-col cols="4">
          <v-btn class="white--text rounded mr-2 payment-btn-width" color="primary" @click="reisdent_payment('Q')">
            Make Quarterly Payment
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn class="white--text rounded mr-2 payment-btn-width" color="primary" @click="reisdent_payment('H')">
            Make Half Yearly Payment
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn class="white--text rounded mr-2 payment-btn-width" color="primary" @click="reisdent_payment('Y')">
            Make Yearly Payment
          </v-btn>
        </v-col>
        <v-col cols="12" align="center" class="mt-4 pb-0">
           <span class="text-h5 font-weight-bold text-uppercase" style="color: black">
            Your Recent Payments
          </span>
        </v-col>
        <v-col cols="12" align="center">
          <v-simple-table class="table text-center">
            <template>
              <thead>
                <tr>
                  <th class="text-center">Payment Date</th>
                  <th class="text-center">Period</th>
                  <th class="text-center">Amount</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Invoice</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payment, index) in payments" :key="index">
                    <td>{{getDate(payment.date)}}</td>
                    <td>{{getMonthString(payment.payment_month)}}, {{payment.payment_year}}</td>
                    <td>INR {{payment.amount}}</td>
                    <td class="text-capitalize" :class="payment.status=='paid'?'green--text':'warning--text'">{{payment.status}}</td>
                    <td>
                      <span v-if="payment.status=='processing'">---</span>
                      <v-icon v-else style="color:#bf3422 !important;font-size:30px" @click="getInvoice(payment.id)">mdi-file-pdf</v-icon>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'
  import DatePicker from '@/components/DatePicker.vue'
  export default {
    metaInfo: {
      title: 'Fees Settings'
    },
    components: {
      DatePicker
    },
    data: () => ({
      overlays: false,
      fromMonth: '',
      toMonth: '',
      fromYear: '',
      toYear: '',
      currencyIcon: 'mdi-currency-inr'
    }),
    computed: {
      ...mapGetters('fees', {
        payments: 'getPayments',
        paymentDetails: 'getPaymentDetails',
        fees: 'getFees',
        unpaid: 'getUnpaid'
      }),
      monthArray() {
        let arr = []
        for (let i = 0; i < 12; i++) {
          const today = new Date(2020, i, 1)
          arr.push({
            text: today.toLocaleString('default', {
              month: 'long'
            }),
            value: today.getMonth() + 1
          })
        }
        return arr
      },
      yearArray() {
        let arr = []
        const today = new Date()
        arr.push({
          text: today.getFullYear(),
          value: today.getFullYear()
        })
        for (let i = 0; i < 4; i++) {
          arr.push({
            text: today.getFullYear() - i,
            value: today.getFullYear() - i
          })
        }
        return arr
      },
      paymentData() {
        let payment = {
          from_month: this.fromMonth,
          from_year: this.fromYear,
          to_month: this.toMonth,
          to_year: this.toYear
        }
        return payment
      },
      firstDateOfCurrentMonth() {
        let currtentDate = new Date()
        if((currtentDate.getMonth() - 4) >= 0) {
          return String(new Date().getFullYear()) + '-' + String((currtentDate.getMonth() - 3) + 1).padStart(2, '0') + '-01'
        } else {
          return String(new Date().getFullYear() - 1) + '-' + String((currtentDate.getMonth() - 3) + 12).padStart(2, '0') + '-01'
        }
      }
    },
    methods: {
      ...mapActions('fees', [
        'fetchUserPayment',
        'getUserPaymentStatus',
        'fetchFees'
      ]),
      ...mapMutations('fees', [
        'removePaymentDetails',
        'removePayments'
      ]),
      fetchFeeData() {
        this.overlays = true
        this.fetchFees().then(() => {
          this.overlays = false
        })
      },
      getMonthString(month) {
        const today = new Date(2020, month - 1, 1)
        return today.toLocaleString('default', {
          month: 'long'
        })
      },
      checkPaymentStatus() {
        this.overlays = true
        this.getUserPaymentStatus(this.paymentData).finally(() => {
          this.overlays = false
        })
      },
      resetPage() {
        this.removePayments()
        this.removePaymentDetails()
        Object.assign(this.$data, this.$options.data.apply(this))
      },
      selectedToDate(date) {
        this.toMonth = new Date(date).getMonth() + 1
        this.toYear = new Date(date).getFullYear()
      },
      selectedFromDate(date) {
        this.fromMonth = new Date(date).getMonth() + 1
        this.fromYear = new Date(date).getFullYear()
      },
      reisdent_payment(id){
        this.$router.push(`/resident-payment/${id}`)
      },
      getDate(date) {
        return `${String(new Date(date).getDate()).padStart(2,'0')}/${String(new Date(date).getMonth()+1).padStart(2,'0')}/${new Date(date).getFullYear()}`
      },
      getInvoice(payment_id) {
        window.open(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/fees-api/v-1/payment/invoice/pdf/?payment_id=${payment_id}`)
      },
    },
    mounted() {
      this.fetchFeeData()
      this.checkPaymentStatus(this.paymentData)
    }
  }
</script>
<style scoped>
  .expansion-panels {
    max-width: fit-content !important;
  }

  .alter-msg>>>.v-icon {
    padding-top: 8px !important;
  }

  .payment-btn-width {
    width: 100%;
  }

  .table thead th {
    font-size: 18px !important;
  }
  .table tbody tr td{
    font-size: 16px !important;
  }
</style>