<template>
  <v-container class="account py-0 my-0">
    <v-row justify="center" v-show="check">
      <v-col cols="3"  xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
          <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
          <v-divider class="mr-2"></v-divider>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled" :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10" class="px-0 py-0 " >
        <v-row v-if="showAlert && societyMonthlyFeesData.status" no-gutters class="px-12 pt-2" justify="center">
          <v-card color="project_red">
            <!-- <v-btn
              class="float-right"
              fab
              text
              small
              color="project-primary"
              @click="showAlert=false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn> -->
            <v-row no-gutters align="center" justify="space-around">
              <v-col cols="2" class="d-flex justify-center">
                <v-icon class="ma-auto" size="50" color="white">mdi-alert</v-icon>
              </v-col>
              <v-col>
                <div class="pa-8">
                  <span class="white--text"> 
                    You have payment due of rupees {{societyMonthlyFeesData.total_amount}}. 
                    <span class="text-decoration-underline text-subtitle-1 btn-underline"
                      @click="$router.push('/society-payment-page')" style="cursor:pointer;">
                      Click here
                    </span> 
                    for more details.
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <router-view></router-view>
      </v-col>
    </v-row>
    <v-overlay v-model="overlays">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  title: 'Account',
  metaInfo: {
    title: 'Account'
  },
  data: () => ({
    selectedListItem: 0,
    settingsListItem: 0,
    bottomListItem: 0,
    profileList: [
      {
        text: 'Profile',
        icon: 'mdi-account',
        disabled: false,
        path: 'profile'
      },
      {
        text: 'Discussions',
        icon: 'mdi-post',
        disabled: false,
        path: 'mydiscussion'
      },
      {
        text: 'Complaints',
        icon: 'mdi-notebook-edit-outline',
        disabled: false,
        path: 'mycomplains'
      },
      {
        text: 'Fees',
        icon: 'mdi-currency-inr',
        disabled: false,
        path: 'fees'
      }
    ],
    settingsList: [
      {
        text: 'Change Password',
        icon: 'mdi-lock',
        disabled: false,
        path: 'password'
      },
    ],
    bottomList: [
      {
        text: 'Home',
        icon: 'mdi-home',
        disabled: false,
        path: ''
      },
    ],
    showAlert: true,
    overlays: false,
    check:false
  }),
  computed: {
    ...mapGetters('JWT', {
      isAdmin: 'isAdmin',
      isDefaultPassword: 'isDefaultPassword'
    }),
    ...mapGetters('settings', {
      settings: 'getSettings'
    }),
    ...mapGetters('society', {
      society: 'getSociety'
    }),
    ...mapGetters('fees', {
      societyMonthlyFeesData: 'getSocietyMonthlyFeesData'
    })
  },
  methods: {
    ...mapActions('settings', [
      'fetchSettings',
    ]),
    ...mapActions('society', [
      'fetchSociety'
    ]),
    ...mapActions('fees', [
      'fetchSocietyMonthlyFeesData'
    ])
  },
  created() {
    this.overlays = true
    this.fetchSocietyMonthlyFeesData().then(() => {
      console.log('JKJFKSFJ', this.societyMonthlyFeesData)
    })
    this.fetchSociety()
    this.fetchSettings().then(() => {
      if(this.isAdmin) {
        this.profileList.shift()
        this.profileList.shift()
        this.profileList.shift()
        this.profileList.shift()
        this.settingsList.unshift({
          'text': 'Settings',
          'icon': 'mdi-cog-outline',
          'disabled': this.isDefaultPassword,
          'path': 'settings'
        })
        this.profileList.unshift({
          'text': 'Fees',
          'icon': 'mdi-currency-inr',
          'disabled': this.isDefaultPassword,
          'path': 'fees-settings'
        })
        this.profileList.unshift({
          'text': 'Securities',
          'icon': 'mdi-security',
          'disabled': this.isDefaultPassword,
          'path': 'security-setting'
        })
        if(this.settings.enable_events) {
          this.profileList.unshift({
            'text': 'Events',
            'icon': 'mdi-calendar-today',
            'disabled': this.isDefaultPassword || !this.settings.enable_events,
            'path': 'events'
          })
        }
        this.profileList.unshift({
          'text': 'Complaints',
          'icon': 'mdi-notebook-edit-outline',
          'disabled': this.isDefaultPassword,
          'path': 'complains'
        })
        if(this.settings.enable_posts) {
          this.profileList.unshift({
            'text': 'Discussions',
            'icon': 'mdi-post',
            'disabled': this.isDefaultPassword || !this.settings.enable_posts,
            'path': 'discussion'
          })
        }
  
        if(this.settings.enable_news) {
          this.profileList.unshift({
            'text': 'Notice Board',
            'icon': 'mdi-information-outline',
            'disabled': this.isDefaultPassword || !this.settings.enable_news,
            'path': 'notice'
          })
        }
        this.profileList.unshift({
          'text': 'Residents',
          'icon': 'mdi-account-check',
          'disabled': this.isDefaultPassword,
          'path': 'residents'
        })
      } else {
        if(!this.settings.enable_posts) {
          this.profileList = this.profileList.filter((value)=>{
              return value.path !== 'myposts'
          })
        }
      }
      this.overlays = false
      this.check=true
    }).finally(() => {
      this.overlays = false
    })
  }
}
</script>
<style scoped>
.left-container-card {
  position: fixed;
  z-index: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.title-size{
  font-size:13px;
  text-transform: uppercase;
}
</style>