<template>
  <v-container class="">
    <v-row class="py-5" justify="center" align="center"
            style="margin-bottom:0 !important;padding-bottom:0px !important">
            <v-col xs="12" sm="12" md="3" lg="3" xl="3" class="d-flex justify-lg-start " style="padding-top:15px;">
                <p class="font-weight-bold text-caption pt-2" style="font-size:18px !important;">
                     Events( {{events.data.length}})
                </p>
            </v-col>
            <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="searchDiv " style="padding-top:15px">
                <v-text-field rounded color="#FFA500" v-model="searchQuery" dense label="Search" outlined
                    append-icon="mdi-magnify" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
                <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.back()" :ripple="false">
                    Back
                </v-btn>
            </v-col>
        </v-row>

    <v-row align="center" justify="start" class="pt-0 mt-0">
      <v-col cols="4" class="mb-3" v-for="(item, index) in filteredEvents" :key="index">
        <EventCard @event-clicked="eventSelected($event)" :item="item" :imageHeight="295"></EventCard>
      </v-col>
    </v-row>

     <v-row justify="center" align="center" v-if="events.data.length>0">
        <v-pagination
          v-model="page"
          :length="events.count"
          :total-visible="7"
          color="primary"
          @input="fetchEventsWithPage($event)"
          @previous="fetchEventsWithUrl(events.previous)"
          @next="fetchEventsWithUrl(events.next)"
        ></v-pagination>
        </v-row>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import EventCard from '@/components/EventCard'
  export default {
    title: 'Events',
    metaInfo: {
      title: 'Events of National Society'
    },
    components: {
      EventCard
    },
    data: () => ({
      showMore: false,
      linkText: 'more',
      searchQuery: '',
      overlay: false,
      loadComplete: false,
      loading: false,
      page:1,
      eventPerPage:10,

    }),
    computed: {
      ...mapGetters('event', {
        events: 'getEvents'
      }),
      filteredEvents() {
        if (this.searchQuery) {
          return this.events.data.filter((event) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return event.title.toLowerCase().includes(search) || event.description.toLowerCase().includes(
                search)
            })
          })
        } else {
          return this.events.data
        }
      },
      // currentPageEvents() {
      //   return this.eventPerPage != null ? this.filteredEvents.slice((this.page - 1) * this.eventPerPage, (
      //     this.page - 1) * this.eventPerPage + this.eventPerPage) : this.filteredEvents
      // }
    },
    methods: {
      ...mapActions('event', [
        'fetchEvents',
        'fetchLimitedEvents',
        'fetchEventsWithPage',
        'fetchEventsWithUrl'
      ]),
      ...mapMutations('event', [
        'setEvent'
      ]),
      eventSelected(event) {
        this.setEvent(event)
        this.$router.push(`/event/${event.title}-${event.id}/`)
      },
      loadAllEvents() {
        this.loading = true
        this.fetchEvents().then(() => {
          this.loading = false
        })
      },
      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'more'
        }
      },
    },
    mounted() {
      this.overlay = true
      this.fetchEvents().then(() => {
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .events {
    padding-top: 64px !important;
  }

  .event-date {
    font-size: 33px !important;
  }

  .event-month {
    font-size: 14px !important;
  }

  .title-left-border {
    border-left: 5px solid #423D3D !important;
    max-width: 0px !important;
  }

  .title {
    font-size: 28px !important;
  }

  .title-line {
    border-color: #423D3D !important;
  }

  .description {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .read-more {
    padding: 0 80px;
  }

  .read-more-btn {
    font-size: 20px !important;
    color: #423D3D !important;
  }
</style>