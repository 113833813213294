<template>
  <v-container class="user my-0 py-0">
    <v-row justify="center" v-show="check">
      <v-col cols="3"  xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled" :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10" class="px-0 py-0">
        <v-row>
          <v-card color="white" class="my-5" elevation="0" width="58%">
            <v-card-title style="font-size:24px;color:#62608b">{{user.full_name}}</v-card-title>
            <v-col>
              <v-img v-if="user.profile_photo" class="pt-2 pb-2" :src="profilePhotoUrl" max-width="30%"
              ></v-img>
            </v-col>
            <v-col class="ml-1 mb-0 pb-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Mobile" :value="user.mobile_number">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Address" :value="user.address">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Email" :value="user.email">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Company" :value="user.company">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Designation" :value="user.designation">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Family Member" :value="user.family_members">
              </v-text-field>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0 mb-0 pb-0" cols="10">
              <v-text-field dense autofocus outlined readonly class="rounded" label="Active Status" :value="user.is_active">
              </v-text-field>
            </v-col>
            <v-col cols="10" class="ml-1">
              <span class="text-h5 font-weight-bold text-uppercase d-flex justify-start px-0" style="color: #62608b">Family Details</span>
            </v-col>
            <v-col class="ml-1 mt-0 pt-0 mb-0 pb-0" cols="10" v-for="(member, index) in user.family_user_id" :key="index">
              <v-row>
                <v-col cols="6">
                  <v-text-field dense autofocus outlined readonly class="rounded" label="Relation" :value="member.relation">
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense autofocus outlined readonly class="rounded" label="Member Name" :value="member.member_name">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-card-actions class="mt-0 pt-0 pb-4">
              <v-btn color="" class="mx-2 my-3 red--text" v-if="user.is_active"
                @click="updateUserStatusProperty('deactive', user.id)">Deactivate</v-btn>
              <v-btn color="" class="mx-2 my-3 green--text" v-else @click="updateUserStatusProperty('active', user.id)">
                Activate</v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import profileImage from '@/assets/profile.jpg'
  import {
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex'
  export default {
    title() {
      return `Resident Details`
    },
    metaInfo: {
      title: 'Resident Details'
    },
    data: () => ({
      profileImage,
      user: {
        mobile_number: '',
        full_name: '',
        email: '',
        address: '',
        company: '',
        designation: ''
      },
      overlay: false,
      selectedListItem: 0,
      settingsListItem: 0,
      bottomListItem: 0,
      profileList: [
        {
          text: 'Profile',
          icon: 'mdi-account',
          disabled: false,
          path: 'profile'
        },
        {
          text: 'My Discussions',
          icon: 'mdi-post',
          disabled: false,
          path: 'mydiscussion'
        },
        {
          text: 'My Complaints',
          icon: 'mdi-notebook-edit-outline',
          disabled: false,
          path: 'mycomplains'
        },
        {
          text: 'Fees',
          icon: 'mdi-currency-inr',
          disabled: false,
          path: 'fees'
        },
        // {
        //   text: 'Change Password',
        //   icon: 'mdi-lock',
        //   disabled: false,
        //   path: 'password'
        // },
      ],
      settingsList: [
        {
          text: 'Change Password',
          icon: 'mdi-lock',
          disabled: false,
          path: 'password'
        },
      ],
      bottomList: [
        {
          text: 'Home',
          icon: 'mdi-home',
          disabled: false,
          path: ''
        },
      ],
      showAlert: true,
      check:false
    }),
    computed: {
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        isDefaultPassword: 'isDefaultPassword'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      id() {
        return this.$route.params.id
      },
      profilePhotoUrl() {
        return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}` + this.user.profile_photo
      }
    },
    methods: {
      ...mapActions('user', [
        'fetchUserById',
        'updateStatus'
      ]),
      ...mapMutations('user', [
        'setUserStatusProperty'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      updateUserStatusProperty(status, user_id) {
        this.setUserStatusProperty({
          status,
          user_id
        })
        this.updateStatus().then(() => {
          location.reload()
        })
      },
    },
    created() {
      this.overlay = true
      if (!this.id) {
        throw 'An id is required'
      }
      this.fetchSettings().then(() => {
        if(this.isAdmin) {
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.settingsList.unshift({
            'text': 'Settings',
            'icon': 'mdi-cog-outline',
            'disabled': this.isDefaultPassword,
            'path': 'settings'
          })
          this.profileList.unshift({
            'text': 'Fees',
            'icon': 'mdi-currency-inr',
            'disabled': this.isDefaultPassword,
            'path': 'fees-settings'
          })
          this.profileList.unshift({
            'text': 'Securities',
            'icon': 'mdi-security',
            'disabled': this.isDefaultPassword,
            'path': 'security-setting'
          })
          if(this.settings.enable_events) {
            this.profileList.unshift({
              'text': 'Events',
              'icon': 'mdi-calendar-today',
              'disabled': this.isDefaultPassword || !this.settings.enable_events,
              'path': 'events'
            })
          }
          this.profileList.unshift({
            'text': 'Complaints',
            'icon': 'mdi-notebook-edit-outline',
            'disabled': this.isDefaultPassword,
            'path': 'complains'
          })
          if(this.settings.enable_posts) {
            this.profileList.unshift({
              'text': 'Discussion',
              'icon': 'mdi-post',
              'disabled': this.isDefaultPassword || !this.settings.enable_posts,
              'path': 'discussion'
            })
          }
    
          if(this.settings.enable_news) {
            this.profileList.unshift({
              'text': 'Notice Board',
              'icon': 'mdi-information-outline',
              'disabled': this.isDefaultPassword || !this.settings.enable_news,
              'path': 'notice'
            })
          }
          this.profileList.unshift({
            'text': 'Residents',
            'icon': 'mdi-account-check',
            'disabled': this.isDefaultPassword,
            'path': 'residents'
          })
        } else {
          if(!this.settings.enable_posts) {
            this.profileList = this.profileList.filter((value)=>{
                return value.path !== 'myposts'
            })
          }
        }
        this.overlays = false
        this.check=true
      })
      this.fetchUserById(this.id).then((data) => {
        this.user = data
        console.log('user', this.user)
      }).finally(() => {
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .user {
    /* padding-top: 64px !important; */
  }
  .left-container-card {
    position: fixed;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  .title-size{
    font-size:13px;
    text-transform: uppercase;
  }
  .v-input >>> input {
    text-transform: capitalize;
  }
</style>