<template>
  <v-container class="add-post py-0 my-0">
    <v-row v-show="check">
      <v-col cols="3"  xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
          <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
          <v-divider class="mr-2"></v-divider>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index" :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled" :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10" class="px-0 py-0 ">
        <v-card class="mx-auto" elevation="0" style="padding-left: 20px; padding-right: 20px">
          <v-card-title class="text-center text-h4 font-weight-bold pb-5" style="color: #62608b">Create Discussions
          </v-card-title>
          <v-row class="mx-1" align="center" justify="start">
            <v-col cols="12" class="mb-4">
              <span v-if="!$v.title.required" class="validation-text red--text text-body-2">Title required.</span>
              <span v-if="!$v.title.maxLength" class="validation-text red--text text-body-2">
                Title should be {{$v.title.$params.maxLength.max}} characters long.
              </span>
              <v-text-field label="Discussion Title *" class="rounded" autofocus outlined dense
                v-model.trim="$v.title.$model">
              </v-text-field>
              <span v-if="!$v.description.maxLength" class="validation-text red--text text-body-2">Description should be
                {{$v.description.$params.maxLength.max}} characters long.</span>
              <span v-if="!$v.description.required" class="validation-text red--text text-body-2">Description required</span>
              <v-textarea label="What's your discussion?" class="rounded" v-model.trim="$v.description.$model" auto-grow
                outlined dense>
              </v-textarea>
              <span v-if="limitExceed" class="red--text">File size limit exceed. File size must not greater than 5
                MB</span>
              <span v-if="typeCheck" class="red--text">File must be image</span>
              <UploadImages :max="1" maxError @changed="handleImages"
                uploadMsg="(Optional) Click to upload or drop your image here" fileError clearAll
                style="height: inherit !important" class="rounded" />
              <v-btn color="#477fca" class="white--text text-uppercase my-5 btn-width" @click="post"
                :disabled="disablePost">Create</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="postComplete" width="500" persistent>
      <v-card justify="center" align="center" class="" rounded style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Discussion created successfully</span><br>
          <span class="text-subtitle-2 text-capitalize">your post will be visible after admin approval</span>
          <br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="finishedPost">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import {
    requiredUnless,
    maxLength
  } from 'vuelidate/lib/validators'
  import UploadImages from "vue-upload-drop-images"
  export default {
    metaInfo: {
      title: 'Add Discussion'
    },
    components: {
      UploadImages
    },
    data: () => ({
      setting_btn: false,
      limitExceed: false,
      typeCheck: false,
      title: '',
      description: '',
      image: undefined,
      imageUrl: '',
      errorImage: '',
      disablePost: false,
      postComplete: false,
      selectedListItem: 0,
      settingsListItem: 0,
      bottomListItem: 0,
      profileList: [
        {
          text: 'Profile',
          icon: 'mdi-account',
          disabled: false,
          path: 'profile'
        },
        {
          text: 'My Discussions',
          icon: 'mdi-post',
          disabled: false,
          path: 'mydiscussion'
        },
        {
          text: 'My Complaints',
          icon: 'mdi-notebook-edit-outline',
          disabled: false,
          path: 'mycomplains'
        },
        {
          text: 'Fees',
          icon: 'mdi-currency-inr',
          disabled: false,
          path: 'fees'
        },
        // {
        //   text: 'Change Password',
        //   icon: 'mdi-lock',
        //   disabled: false,
        //   path: 'password'
        // },
      ],
      settingsList: [
        {
          text: 'Change Password',
          icon: 'mdi-lock',
          disabled: false,
          path: 'password'
        },
      ],
      bottomList: [
        {
          text: 'Home',
          icon: 'mdi-home',
          disabled: false,
          path: ''
        },
      ],
      showAlert: true,
      overlays: false,
      check:false
    }),
    methods: {
      ...mapActions('user', [
        'postStory'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      ...mapActions('society', [
        'fetchSociety'
      ]),
      selectImage() {
        document.getElementById("select-image").click()
      },
      post() {
        this.disablePost = true
        let formData = new FormData()
        if (this.image != undefined) {
          formData.append('title', this.title)
          formData.append('description', this.description)
          formData.append('media_file', this.image)
          this.postStory(formData).then(() => {
            this.postComplete = true
            this.disablePost = false
          }).catch(() => {
            this.disablePost = false
          })
        } else {
          this.$v.$touch()
          // this.$v.description.$touch()
          console.log('$v', this.$v.$invalid)

          if (!this.$v.title.$invalid && !this.$v.description.$invalid && this.title && this.description) {
            formData.append('title', this.title)
            formData.append('description', this.description)
            this.postStory(formData).then(() => {
              this.postComplete = true
              this.disablePost = false
            }).catch(() => {
              this.disablePost = false
            })
          } else {
            this.disablePost = false
          }
        }
      },
      finishedPost() {
        this.postComplete = false
        this.$router.push('/account/mydiscussion')
      },
      onAddImages(file) {
        const reader = new FileReader()
        reader.addEventListener('load', e => this.imageUrl = e.target.result)
        reader.addEventListener('error', () => this.imageUrl = this.errorImage)
        reader.readAsDataURL(file)
      },
      handleImages(file) {

        const fileType = ["image/gif", "image/jpg", "image/jpeg", "image/png", "image/bmp"]
        console.log(file)
        if (file) {
          let file_size = file[0].size / (1024 * 1024);
          console.log(file_size)
          if (file_size > 5) {
            this.disablePost = true
            this.limitExceed = true
            this.typeCheck = false
          } else if (!fileType.includes(file[0].type)) {
            this.disablePost = true
            this.typeCheck = true
            this.limitExceed = false
          } else {
            this.disablePost = false
            this.typeCheck = false
            this.limitExceed = false
          }
        }

        this.image = file[0]
      }
    },
    computed: {
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        isDefaultPassword: 'isDefaultPassword'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      titleRequired() {
        return this.description === ''
      },
      descriptionRequired() {
        return this.title === ''
      },
      ...mapGetters('society', {
        society: 'getSociety'
      })
    },
    validations() {
      return {
        title: {
          required: requiredUnless('titleRequired'),
          maxLength: maxLength(100)
        },
        description: {
          required: requiredUnless('descriptionRequired'),
          maxLength: maxLength(20000)
        }
      }
    },
    created() {
      this.overlays = true
      this.fetchSociety()
      this.fetchSettings().then(() => {
        if(this.isAdmin) {
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.settingsList.unshift({
            'text': 'Settings',
            'icon': 'mdi-cog-outline',
            'disabled': this.isDefaultPassword,
            'path': 'settings'
          })
          this.profileList.unshift({
            'text': 'Fees',
            'icon': 'mdi-currency-inr',
            'disabled': this.isDefaultPassword,
            'path': 'fees-settings'
          })
          this.profileList.unshift({
            'text': 'Securities',
            'icon': 'mdi-security',
            'disabled': this.isDefaultPassword,
            'path': 'security-setting'
          })
          if(this.settings.enable_events) {
            this.profileList.unshift({
              'text': 'Events',
              'icon': 'mdi-calendar-today',
              'disabled': this.isDefaultPassword || !this.settings.enable_events,
              'path': 'events'
            })
          }
          this.profileList.unshift({
            'text': 'Complaints',
            'icon': 'mdi-notebook-edit-outline',
            'disabled': this.isDefaultPassword,
            'path': 'complains'
          })
          if(this.settings.enable_posts) {
            this.profileList.unshift({
              'text': 'Discussion',
              'icon': 'mdi-post',
              'disabled': this.isDefaultPassword || !this.settings.enable_posts,
              'path': 'discussion'
            })
          }
    
          if(this.settings.enable_news) {
            this.profileList.unshift({
              'text': 'Notice Board',
              'icon': 'mdi-information-outline',
              'disabled': this.isDefaultPassword || !this.settings.enable_news,
              'path': 'notice'
            })
          }
          this.profileList.unshift({
            'text': 'Residents',
            'icon': 'mdi-account-check',
            'disabled': this.isDefaultPassword,
            'path': 'residents'
          })
        } else {
          if(!this.settings.enable_posts) {
            this.profileList = this.profileList.filter((value)=>{
                return value.path !== 'myposts'
            })
          }
        }
        this.overlays = false
        this.check=true
      })
    }
  }
</script>
<style scoped>
  /* .add-post {
  padding-top: 64px !important;
} */
  .select-image-card {
    background: #F7F7F7;
    border: 1px dashed #423D3D;
  }

  .select-image {
    height: inherit;
  }

  .input-text-section {
    height: 370px;
    display: grid;
    align-self: center;
  }

  .validation-text {
    /* position: relative;
    bottom: 35px; */
  }
  .left-container-card {
    position: fixed;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
  .title-size{
    font-size:13px;
    text-transform: uppercase;
  }
</style>