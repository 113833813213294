<template>
  <v-container class="">
    <v-row justify="space-between" class="">
      <v-col cols="8" class="mt-0 pt-0">
        <v-card color="white" class="" elevation="0" width="100%">
          <v-card-title v-text="notice.title" class="text-capitalize px-0 mb-0 pb-1 content-title"></v-card-title>

          <v-card-text class="text-caption text-capitalize ml-0 pl-0 py-1 pb-0" style="font-size:15px !important;">
            <span class="text-capitalize font-italic">{{ getDate(notice.added) }}</span>
            <span class="text-capitalize font-italic"> By Admin</span>
          </v-card-text>

          <v-img :src="notice.media_file" max-height="372" class="align-end"></v-img>
          <v-card-text v-text="notice.description" class="text-justify px-0 black--text" style="font-size:15px"></v-card-text>
          
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-row justify="start" class="ma-0">
          <v-col cols="12" class="pa-0">
            <span>
              <v-icon color="#1a77d2" class="mr-0 pr-0" style="font-size:20px">mdi-chevron-double-left</v-icon>
            </span>
            <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0 ml-0 pl-0"
              color="primary" plain @click="$router.back()" :ripple="false">
              Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0" v-show="check">
          <v-col cols="12" class="pa-0">

            <v-card elevation="0" class="">
              <v-card-title class="text-capitalize px-0 pb-0 mb-0" style="font-size:24px;color:black !important">Recent notices</v-card-title>
              <v-divider class="pb-2"></v-divider>

              <v-card elevation="0" class="ml-0 pl-0 mt-0 pt-0 mb-2" v-for="(info, index) in notices.data" :key="index"
                @click="newsClicked(info)">
                <v-card-text class="ml-0 pl-0 py-1 info-title text-truncate font-weight-bold" v-text="info.title">
                </v-card-text>
                <v-card-text class="text-caption text-capitalize ml-0 pl-0 mt-0 pt-0 mb-0 pb-0"
                  style="font-size:15px !important;">
                  <span class="text-capitalize font-italic" style="font-size:13px">{{ getDate(info.added) }}</span>
                </v-card-text>
              </v-card>

              <v-card-actions class="px-0">
                <v-btn class="text-capitalize" color="#d0d0d0" to="/notices">View all notices
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0" v-show="check1">
          <v-col cols="12" class="pa-0">
            <v-card elevation="0" class="my-5">
              <v-card-title class="text-capitalize px-0 mb-0 pb-0" style="font-size:24px;color:black !important" >Upcoming Events</v-card-title>
              <v-divider class="pb-2"></v-divider>
              <v-card elevation="0" class="my-2" v-for="(event, index) in events.data" :key="index"
                @click="eventSelected(event)">
                <div>
                  <v-row justify="space-between" class="ma-0 pa-0">
                    <v-col cols="3" class="px-0 py-0" v-if="event.media_file || event.thumbnail">
                      <v-img class="align-end" max-width="90" height="80" :src="event.thumbnail">
                      </v-img>
                    </v-col>
                    <v-col v-else cols="3" class="px-0 py-0" >
                      <v-img class="align-end" max-width="90" height="80" :src="defaultDiscussionImage" style="background-color:#e9e9e9">
                      </v-img>
                    </v-col>
                    <v-col cols="9" class="px-0 py-0">
                      <v-card-text class="py-0 info-title text-truncate font-weight-bold" v-text="event.title"></v-card-text>
                       <v-card-subtitle class="py-0 info-subtitle"
                        v-text=" formatDate(event.event_date) ">
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <v-card-actions v-if="limitedEvents" class="px-0">
                <v-btn class="text-capitalize" color="#d0d0d0" to="/events">View all events
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  //import backgroundImage from '@/assets/background-image.png'
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import defaultDiscussionImage from '@/assets/default-discussion-image.png'
  export default {
    title() {
      return `News-${this.$route.params.notice_id}`
    },
    metaInfo: {
      title: `Notice of National Society`
    },
    data: () => ({
      defaultDiscussionImage,
      overlay: false,
      check:false,
      check1:false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      //backgroundImage,
      limitedEvents: true,
      limitedNews: true
    }),
    computed: {
      ...mapGetters('event', {
        notice: 'getNotice',
        notices: 'getNotices',
        events: 'getEvents'
      }),
      notice_id() {
        return this.$route.params.notice_id.split('-')[this.$route.params.notice_id.split('-').length - 1]
      }
    },
    methods: {
      ...mapActions('event', [
        'fetchNoticeById',
        'fetchNotices',
        'fetchLimitedNotices',
        'fetchEvents',
        'fetchLimitedEvents'
      ]),
      ...mapMutations('event', [
        'setEvent',
        'setNotice'
      ]),
      formatDate(date) {
        // if (!date) return null
        // const [year, month, day] = date.split('-')
        // return `${day}/${month}/${year}`
        if (!date) return null
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const weekday = ["Sun","Mon","Tue","Wed","Thurs","Fri","Sat"];
        const dt = new Date(date);
        return `${weekday[dt.getDay()]}, ${dt.getDate()} ${ monthNames[dt.getMonth()]} ${dt.getFullYear()} `;

      },
      // getAllEvents() {
      //   this.fetchEvents().then(() => {
      //     this.limitedEvents = false
      //   }).catch(() => {
      //     this.limitedEvents = true
      //   })
      // },
      // getAllNews() {
      //   this.fetchNotices().then(() => {
      //     this.limitedNews = false
      //   }).catch(() => {
      //     this.limitedNews = true
      //   })
      // },
      eventSelected(event) {
        this.setEvent(event)
        this.$router.push(`/event/${event.title.replace(/\s/g, '-')}-${event.id}/`)
      },
      newsClicked(item) {
        this.setNotice(item)
        this.$router.push(`/notice/${item.title.replace(/\s/g, '-')}-${item.id}/`)
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      }
    },
    mounted() {
      this.fetchNoticeById(this.notice_id)
      this.fetchLimitedEvents().then(()=>{
        this.check1 = true
      })
      this.fetchLimitedNotices().then(()=>{
        this.check=true
      })

    }
  }
</script>
<style scoped>
  .news_information {
    padding-top: 64px;
  }

  .upper-title {
    font-size: 45px !important;
  }

  .content-title {
    font-size: 32px !important;
    word-break: break-word;
  }

  .card-content {
    margin: auto;
    width: 70%;
  }
</style>
<style scoped>
  .info-title {
    font-size: 16px !important;
    color:black !important;
  }

  .info-subtitle {
    font-size: 12px !important;
  }
</style>