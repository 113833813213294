<template>
  <v-container class="pt-10 ml-0 pl-0">
    <v-card elevation="0">
      <v-form id="password-change" @submit.prevent="save">
        <v-row justify="start">
          <v-col cols="8" class="py-0 my-0">
            <v-card-title class="text-h5 font-weight-bold text-uppercase d-flex justify-start px-0"
              style="color: #62608b">Change Password</v-card-title>
          </v-col>
          <v-col v-if="errorMsg || updateFailed" cols="10" md="6">
            <span v-if="errorMsg" class="error--text">{{errorMsg}}</span>
            <v-card-text v-if="updateFailed" class="error--text">
              Wrong password.
            </v-card-text>
          </v-col>
        </v-row>
        <!-- <v-divider class="pb-10"></v-divider> -->
        <v-row justify="start" align="start">
          <v-col cols="8" class="py-0 my-0">
            <span v-if="!$v.changePassword.password.required && $v.changePassword.password.$dirty"
              class="red--text text-body-2">Password required.</span>
            <span v-if="!$v.changePassword.password.minLength" class="red--text text-body-2">Password should be greater
              than {{$v.changePassword.password.$params.minLength.min}} characters.</span>
            <v-text-field dense autofocus outlined class="rounded" label="Password *"
              :value="$v.changePassword.password.$model" @input="updateChangePasswordProperty('password', $event)"
              :type="showPassword ? 'text': 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"></v-text-field>

          </v-col>
        </v-row>
        <v-row justify="start" align="start">
          <v-col cols="8" class="py-0 my-0">
            <span v-if="!$v.changePassword.new_password.required && $v.changePassword.new_password.$dirty"
              class="red--text text-body-2">New password required.</span>
            <span v-if="!$v.changePassword.new_password.minLength" class="red--text text-body-2">New password should be
              greater than {{$v.changePassword.new_password.$params.minLength.min}} characters.</span>
            <v-text-field dense outlined class="rounded" label="New Password *"
              :value="$v.changePassword.new_password.$model"
              @input="updateChangePasswordProperty('new_password', $event)" :type="showNewPassword ? 'text': 'password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showNewPassword = !showNewPassword"></v-text-field>

          </v-col>
        </v-row>
        <v-row justify="start" align="start">
          <v-col cols="8" class="py-0 my-0">
            <span v-if="!$v.changePassword.confirm_password.required && $v.changePassword.confirm_password.$dirty"
              class="red--text text-body-2">Confirm password required.</span>
            <span v-if="!$v.changePassword.confirm_password.sameAsNewPassword" class="red--text text-body-2">Confirm
              password must be as new password.</span>
            <v-text-field dense outlined class="rounded" label="Confirm Password *"
              :value="$v.changePassword.confirm_password.$model"
              @input="updateChangePasswordProperty('confirm_password', $event)"
              :type="showConfirmPassword ? 'text': 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>

          </v-col>
        </v-row>
        <v-row justify="start" align="start">
          <v-col cols="8" class="d-flex justify-start">
            <v-btn type="submit" :disabled="disabledSave" for="password-change" color="#5da55b"
              class="text-uppercase white--text mr-2" min-width="100px" width="30%">Save</v-btn>
            <v-btn @click="resetChangePassword" :disabled="disabledSave" color="error"
              class="text-uppercase white--text ml-2" min-width="100px" width="30%">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-dialog v-model="sendOtp" persistent width="500">
      <SendOtp title="Verify OTP" :data="changePassword" otp_type="password" @cancel-otp="sendOtp=false"
        @verify-success="save" @verify-failed="errorMsg='OTP is not matched.'"
        @invalid-request="errorMsg='Somthing went wrong.'"></SendOtp>
    </v-dialog>
    <v-dialog v-model="successMessage" width="500" persistent>
      <v-card justify="center" align="center" class="" rounded style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Password has changed successfully</span>
          <br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
          @click="redirectTo">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex'
  import {
    required,
    sameAs,
    minLength
  } from 'vuelidate/lib/validators'
  import SendOtp from '@/components/SendOtp'
  export default {
    title: () => ('Change Password'),
    metaInfo: {
      title: 'Change Password for National Society'
    },
    components: {
      SendOtp
    },
    data: () => ({
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      updateFailed: false,
      sendOtp: false,
      errorMsg: '',
      disabledSave: false,
      successMessage:false
    }),
    computed: {
      ...mapGetters('user', {
        changePassword: 'getChangePassword'
      }),
      ...mapGetters('JWT', {
        isDefaultPassword: 'isDefaultPassword'
      }),
    },
    validations() {
      let data = {
        changePassword: {
          password: {
            required,
            minLength: minLength(8)
          },
          new_password: {
            required,
            minLength: minLength(8)
          },
          confirm_password: {
            required,
            sameAsNewPassword: sameAs('new_password')
          }
        }
      }
      return data
    },
    methods: {
      ...mapActions('user', [
        'updatePassword',
        'SendOTPUser'
      ]),
      ...mapActions('JWT', [
        'logout'
      ]),
      ...mapMutations('user', [
        'setChangePasswordProperty',
        'resetChangePassword'
      ]),
      updateChangePasswordProperty(property, value) {
        this.setChangePasswordProperty({
          property,
          value
        })
        this.$v.changePassword[property].$touch()
      },
      save() {
        this.$v.$touch()
        this.disabledSave = true

        if (!this.$v.$invalid) {
          this.updatePassword().then((response) => {
            if (response.data.otp || response.data.matched_failed) {
              this.sendOtp = true
            } else {
              this.successMessage=true
              //   this.logout().then(() => {
              //   location.reload()
              // })
            }
            this.disabledSave = false
          }).catch(() => {
            this.updateFailed = true
            this.disabledSave = false
          })
        } else {
          this.disabledSave = false
        }
      },
      redirectTo(){
          this.logout().then(() => {
              location.reload()
          })
      },
      updateData() {
        this.updatePassword().then(() => {
          this.logout().then(() => {
            location.reload()
          })
        })
      }
    }
  }
</script>
<style scoped>

</style>