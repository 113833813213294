<template>
  <v-app>
    <v-main class="wraper-class">
      <Navbar v-if="$route.path!='/payment-redirect'"></Navbar>
      <router-view class="content-class"></router-view>
      <Footer v-if="$route.matched[0].path != '/account' && $route.path!='/addevent' && $route.path!='/addnotice' && $route.path!='/add-security' && $route.name!='User' && $route.path!='/addiscussion' && $route.path!='/addcomplaint' && $route.path!='/payment-redirect'"></Footer>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { mapGetters } from 'vuex';

export default {
  title: 'National Society',
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'National Society'
  },
  components: {
    Navbar,
    Footer
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('JWT', {
      loggedIn: 'loggedIn'
    })
  }
};
</script>

<style scoped>
.wraper-class {
  padding: 0 !important;
  margin: 0 !important;
}
.content-class {
  overflow: hidden;
  min-height: 70vh !important;
}
</style>


