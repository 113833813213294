<template>
  <v-card height="360" class="rounded-lg">
    <v-row align="center" justify="center" class="pa-0 ma-0 ml-0">
      <v-col cols="9" class="">
        <!-- <v-card-title>Send Message to {{user.full_name}} ({{user.mobile_number}})</v-card-title> -->
         <v-card-title class="text-left font-weight-bold ml-0 pl-0" style="color: #62608b">
           Send Message to {{user.full_name}} ({{user.mobile_number}})
          </v-card-title>
      </v-col>
      <v-col cols="9" class="">
        <v-textarea
          class="rounded"
          outlined
          v-model="message"
          autofocus
          auto-grow
          background-color="grey lighten-4">
        </v-textarea>
      </v-col>
      <v-col cols="9" class="">
        <v-btn color="action_btn_color"  class="rounded white--text mr-4 btn-width">Send</v-btn>
        <v-btn color="error"  class="rounded white--text btn-width" @click="$emit('cancel-message')">Cancel</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
 props: [
   'user'
 ],
 data: () => ({
   message: ''
 })
}
</script>
<style scoped>

</style>
