<template>
  <v-container class="contact-setting">
    <v-card elevation="0">
      <v-container class="ml-0 pl-0">
        <v-row justify="start" class="d-flex">
          <v-col cols="12" sm="6" class="ml-0 pl-0">
            <v-checkbox v-model="enableEvents" label="Enable Events" color="project_primary"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-end">
            <v-checkbox v-model="enableNews" label="Enable Notices" color="project_primary"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" class="ml-0 pl-0">
            <v-checkbox v-model="showComplaints" label="Make Complaints & Grievances visible to Public"
              color="project_primary"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-end">
            <v-checkbox v-model="enablePosts" label="Enable Discussions" color="project_primary"></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container class="ml-0 pl-0">
        <FeesSettings :fees="fees" :feesId="feesId"></FeesSettings>
      </v-container>
      <v-divider></v-divider>
      <v-container class="ml-0 pl-0">
        <PaymentSetUp :settings="settings"></PaymentSetUp>
      </v-container>
      <v-divider></v-divider>
      <v-container class="ml-0 pl-0">
        <v-row justify="center" class="d-flex">
          <v-col cols="12" class="ml-0 pl-0">
            <v-card-title class="text-h5 font-weight-bold text-uppercase d-flex justify-start px-0"
              style="color: #62608b">Cover Image and Logo</v-card-title>
          </v-col>
          <v-col cols="6" class="ml-0 pl-0">
            <!-- <v-img v-if="settings.cover_image" :src="settings.cover_image" height="250"></v-img> -->
            <span v-if="limitExceed" class="red--text">File size limit exceed. File size must not greater than 5
              MB.</span>
            <span v-if="typeCheck" class="red--text">File must be the following format(jpg, jpeg, png, bmp, gif).</span>
            <span v-if="dimensionCheck" class="red--text">For better visualization please provide image minimum 1161 pixel width and
              527 pixel Height.</span>
            <UploadImages :max="3" maxError @changed="handleCoverImage" ref="myFile"
              uploadMsg="(Optional) Click to upload or drop your cover image here" fileError clearAll
              style="height: 250px !important" class="rounded" />
          </v-col>
          <v-col cols="6" class="ml-0 pl-0">
            <!-- <v-img v-if="settings.logo" :logo="settings.logo" height="250"></v-img> -->
            <span v-if="limitExceed1" class="red--text">File size limit exceed. File size must not greater than 5
              MB.</span>
            <span v-if="typeCheck1" class="red--text">File must be the following format(jpg, jpeg, png, bmp, gif).</span>
            <UploadImages :max="1" maxError @changed="handleLogoImage"
              uploadMsg="(Optional) Click to upload or drop your logo here" fileError clearAll
              style="height: 250px !important" class="rounded" />
          </v-col>
          <v-col cols="12" class="ml-0 pl-0">
            <v-card-actions class="d-flex justify-start px-0">
              <v-btn class="white--text rounded btn-width" color="#5da55b" :disabled="setting_btn"
                @click="saveContacts">
                Save</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="showDialog" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>

          <span class="text-h4 font-weight-bold">Settings saved successfully.</span><br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="updateCompelete">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  // import { required, numeric, email } from 'vuelidate/lib/validators'
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex'
  import FeesSettings from '@/components/FeesSettings.vue'
  import UploadImages from "vue-upload-drop-images"
  import PaymentSetUp from "@/components/PaymentSetUp.vue"
  export default {
    title: 'Contact Settings',
    metaInfo: {
      title: 'Contact Settings for National Society'
    },
    components: {
      FeesSettings,
      UploadImages,
      PaymentSetUp
    },
    data: () => ({
      image: {
        size: '',
        height: '',
        width: ''
      },
      imageLoaded: false,
      previewImage: null,
      showDialog: false,
      overlay: false,
      setting_btn: false,
      limitExceed: false,
      typeCheck: false,
      limitExceed1: false,
      typeCheck1: false,
      dimensionCheck: false,
      imgWidth: '',
      imgHeight: '',
    }),
    validations() {
      // const phoneNumber =  val => (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val) || /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val))
      let data = {
        settings: {
          // email: {
          //   required,
          //   email
          // },
          // contact_number: {
          //   required,
          //   phoneNumber
          // },
          // pincode: {
          //   numeric
          // }
        }
      }
      return data
    },
    computed: {
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      ...mapGetters('fees', {
        fees: 'getFees',
        feesId: 'getFeesId'
      }),
      ...mapGetters('user', {
        user: 'getUser'
      }),
      enableEvents: {
        get() { 
          return this.settings.enable_events
        },
        set(value) {
          this.setSettingsProperty({
            property: 'enable_events',
            value
          })
        }
      },
      enableNews: {
        get() {
          return this.settings.enable_news
        },
        set(value) {
          this.setSettingsProperty({
            property: 'enable_news',
            value
          })
        }
      },
      enablePosts: {
        get() {
          return this.settings.enable_posts
        },
        set(value) {
          this.setSettingsProperty({
            property: 'enable_posts',
            value
          })
        }
      },
      showComplaints: {
        get() {
          return this.settings.show_complaints
        },
        set(value) {
          this.setSettingsProperty({
            property: 'show_complaints',
            value
          })
        }
      },
    },
    methods: {
      ...mapActions('settings', [
        'fetchSettings',
        'createSettings',
        'updateSettings'
      ]),
      ...mapActions('fees', [
        'fetchFees',
        'createFees',
        'updateFees'
      ]),
      ...mapActions('user', [
        'fetchUser'
      ]),
      ...mapMutations('settings', [
        'setSettingsProperty'
      ]),
      updateContactsProperty(property, value) {
        this.setSettingsProperty({
          property,
          value
        })
        // this.$v.changePassword[property].$touch()
      },
      saveContacts() {
        this.updateContactsProperty('society', this.user.society)
        this.$v.$touch()
        if (!this.$v.$invalid) {
          if (!this.settings.id) {
            this.createSettings().then(() => {
              this.showDialog = true
            })
          } else {
            this.updateSettings().then(() => {
              this.showDialog = true
            })
          }
          this.saveFeesItems()
        }
      },
      saveFeesItems() {
        this.overlays = true
        if (this.feesId) {
          this.updateFees().then(() => {
            this.fetchFeeData()
            this.updatedFees = true
            this.statusPopup = true
          })
        } else {
          this.createFees().then(() => {
            this.fetchFeeData()
            this.createdFees = true
            this.statusPopup = true
          })
        }
      },
      fetchFeeData() {
        this.overlay = true
        this.fetchFees().then(() => {
          this.overlays = false
        })
      },
      handleCoverImage(file) {
        console.log("FIle",file)
        const fileType = ["image/gif", "image/jpg", "image/jpeg", "image/png", "image/bmp"]
        if (file.length>0) {
          let file_size = file[0].size / (1024 * 1024);
          if (file_size > 5) {
            this.setting_btn = true
            this.limitExceed = true
            this.typeCheck = false
          } else if (!fileType.includes(file[0].type)) {
            this.setting_btn = true
            this.typeCheck = true
            this.limitExceed = false
          } else {
            this.setting_btn = false
            this.typeCheck = false
            this.limitExceed = false
          }
          // Width Height
          var obj = this
          var reader = new FileReader();
          reader.readAsDataURL(file[0]);
          reader.onload = function (e) {
            let image = new Image();
            image.src = e.target.result;
            image.onload = function () {
            obj.dimension(this.width, this.height)
            }
          }
          // Width Height
          this.updateContactsProperty('cover_image', file[0])
        }
        else {
            this.limitExceed = false
            this.typeCheck = false
            this.dimensionCheck=false
            this.setting_btn = false
        } 
      },
      dimension(width,height) {
        console.log(width)
        if ((width < 1161) || (height < 527)) {
          this.dimensionCheck = true

        } else {
          this.dimensionCheck = false
        }
      },
      handleLogoImage(file) {

        const fileType = ["image/gif", "image/jpg", "image/jpeg", "image/png", "image/bmp"]
        if (file.length>0) {
          let file_size = file[0].size / (1024 * 1024);
          if (file_size > 5) {
            this.setting_btn = true
            this.limitExceed1 = true
            this.typeCheck1 = false
          } else if (!fileType.includes(file[0].type)) {
            this.setting_btn = true
            this.typeCheck1 = true
            this.limitExceed1 = false
          } else {
            this.setting_btn = false
            this.typeCheck1 = false
            this.limitExceed1 = false
          }
           this.updateContactsProperty('logo', file[0])
        }
        else{
            this.setting_btn = false
            this.typeCheck1 = false
            this.limitExceed1 = false
        }

      },
      updateCompelete() {
        this.showDialog = false
        location.reload()
      },
    },
    mounted() {
      this.overlay = true
      this.fetchSettings().then(() => {
        console.log('settings', this.settings)
        this.overlay = false
      })
      this.fetchFeeData()
      this.fetchUser()
    }
  }
</script>
<style scoped>
  .validation-text {
    position: relative;
    bottom: 20px;
  }

  .error-span {
    margin-bottom: -18px !important;
  }

  /* .imageHolder{
    background-size:contain !important;
  } */
</style>