<template>
    <v-container class="d-flex align-center">
        <!-- <v-row class="flex-column ma-auto">
            <div class="text-center text-h4 font-weight-bold">Pricing</div>
            <div class="text-center text-h6 pt-8">We accept only a single fee of INR 499.00</div>
            <div class="text-center">
                <span class="price">
                    <v-icon class="rupee">mdi-currency-inr</v-icon>
                    499.
                </span>
                <span class="price1">
                    00
                </span>
                <span class="gst">
                    + GST
                </span>
            </div>
            <div class="text-center text-lg-h6">National Society Registration Fees</div>
            <div class="text-center pt-12">
                <v-btn class="white--text rounded" large color="#67ac5b">
                    Sign up now
                </v-btn>
            </div>
        </v-row> -->
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <div>
                     <div class="text-left text-h4 font-weight-bold pb-4" style="color:#62608b">Pricing</div>
                <v-card class="" width="450" rounded>
                    <v-card-title class="pt-2 pb-2"  style="background-color:#f65a05;color:white">Registration Fees</v-card-title>
                     <v-card-text class="text--primary mt-3" >
                         <ul class="list" style="color:black !important">
                             <li>One time registration and setup fees for society.</li>
                             <li>No Cancellation fees.</li>
                         </ul>
                     </v-card-text>
                    <v-card-text class="text--primary" >
                        <div class="">
                            <span class="price">
                                <v-icon class="rupee">mdi-currency-inr</v-icon>
                                499.
                            </span>
                            <span class="price1">
                                00
                            </span>
                            <span class="gst">
                                + GST
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
            <div>
                <v-card class="" width="450" rounded>
                    <v-card-title class="pt-2 pb-2" style="background-color:#f3b731;color:black !important">Membership Fees</v-card-title>
                     <v-card-text class="text--primary mt-3" >
                         <ul class="list" style="color:black !important">
                             <li>Per member, per month, billed monthly/quarterly/yearly.</li>
                             <li>Access to all features.</li>
                             <li>No cancellation fees.</li>
                         </ul>
                     </v-card-text>
                    <v-card-text class="text--primary" >
                        <div class="">
                            <span class="price">
                                <v-icon class="rupee">mdi-currency-inr</v-icon>
                                10.
                            </span>
                            <span class="price1">
                                00
                            </span>
                            <span class="gst">
                                + GST
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
                <div>
                    <span>
                        Please read our
                    </span>
                    <v-btn class="pl-0 ml-0 text-decoration-underline text-capitalize text-subtitle-1 btn-underline"
                        color="primary" plain @click="$router.push('terms-and-conditions')" :ripple="false">
                        Terms & Conditions
                    </v-btn>
                </div>
                   <div class="text-center pt-6 pb-3">
                <v-btn class="white--text rounded" large color="#67ac5b" @click="$router.push('registration')">
                    Sign up now
                </v-btn>
            </div>
            </div>
            </v-col>  
        </v-row>
    </v-container>
</template>
<script>
    export default {
        title: 'Pricing for The National Society',
        metaInfo: {
            title: 'Pricing for The National Society'
        },
    }
</script>
<style scoped>
    .rupee {
        font-size: 30px;
        margin-top: -20px !important;
        margin-right: -20px !important;
        /* color: #1976d3; */
        color:black !important;
    }

    .price {
        /* margin-left:20px !important; */
        font-size: 50px !important;
        /* color: #1976d3; */
        color:black !important;
        font-weight: bold;
    }

    .price1 {
        font-size: 20px;
        margin-left: -10px !important;
        /* color: #1976d3; */
        color:black !important;
        font-weight: bold;
    }

    .gst {
        font-size: 18px;
        /* color: #1976d3; */
        color:black !important;
        font-weight: bold;
    }
</style>