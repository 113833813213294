import Vue from 'vue'
import ApiService from '../../services/api.service'


const state = {
  payment: {
    amount: '0',
    tax: '0',
    order_id: '',
    cust_name: '',
    cust_email: '',
    cust_contact: ''
  }
}

const mutations = {
  setPayment(state, payment) {
    Vue.set(state, 'payment', {...payment})
  },
  resetPayment(state) {
    Vue.set(state.payment, 'amount', '0')
    Vue.set(state.payment, 'tax', '0')
    Vue.set(state.payment, 'order_id', '')
    Vue.set(state.payment, 'cust_name', '')
    Vue.set(state.payment, 'cust_email', '')
    Vue.set(state.payment, 'cust_contact', '')
  }
}

const actions = {
  paymentInitiate(_, data) {
    return ApiService.post('/payment-api/v-1/pay/', data)
  },
  paymentHandle(_, data) {
    return ApiService.post('/payment-api/v-1/payment/success/', data)
  },
  monthlyPaymentInitiate(_, data) {
    return ApiService.post('/payment-api/v-1/monthly-payment-order/', data)
  },
  monthlyPaymentHandle(_, data) {
    return ApiService.post('/payment-api/v-1/monthly-payment/success/', data)
  },
}

const getters = {
  getPayment: state => state.payment
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
