<template>
    
        <v-row >
            <v-col xs="12" sm="12" md="3" lg="3" xl="3" class="d-flex justify-lg-start" >
                <p class="font-weight-bold text-caption pt-2" style="font-size:18px !important;">
                    {{label}}({{value}})
                </p>
            </v-col>
            <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="searchDiv" >
                <v-text-field v-model="searchQuery" @input="searching($event)" rounded dense color="#FFA500" label="Search" outlined
                    append-icon="mdi-magnify" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        props: [
            'label',
            'value'
        ],
        data: () => ({
            searchQuery: ''
        }),
        methods: {
            searching(value) {
                this.$emit('searching', value)
            }
        }
    }
</script>