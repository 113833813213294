import Vue from 'vue'
import ApiService from '../../services/api.service'
// import { TokenService } from '@/services/token.service'
// import _ from 'lodash'

const state = {
  setting: {
    society: '',
    cover_image: null,
    logo: null,
    enable_events: true,
    enable_news: true,
    enable_posts: true,
    show_complaints: true,
    enable_netbank: false,
    enable_upi: false,
    enable_googlepay: false,
    account_type: '',
    beneficiary_name: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    upi_id: '',
    mobile_number: '',
    qr_code: undefined
  },
  societyFees: {
    registration_fees: '0',
    member_fees: '0',
    enable_events: true,
    enable_news: true,
    enable_posts: true,
    show_complaints: true,
    enable_netbank: false,
    enable_upi: false,
    enable_googlepay: false,
    account_type: '',
    beneficiary_name: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    upi_id: '',
    mobile_number: '',
    qr_code: undefined
  }
}

const mutations = {
  setSettings(state, setting) {
    Vue.set(state, 'setting', ...setting)
  },
  setSettingsProperty(state, { property, value }) {
    Vue.set(state.setting, property, value)
  },
  setSocietyFees(state, fees) {
    Vue.set(state, 'societyFees', {...fees})
  },
  setSocietyFeesProperty(state, { property, value }) {
    Vue.set(state.societyFees, property, value)
  }
}

const actions = {
  createSettings({ getters }) {
    let formData = new FormData()
    for (let key in getters.getSettings) {
      if (getters.getSettings[key] != null) {
        formData.append(key, getters.getSettings[key])
      }
    }
    return ApiService.post('/config-api/v-1/contacts/', formData)
  },
  updateSettings({ getters }) {
    let formData = new FormData()

    for (let key in getters.getSettings) {
      if (getters.getSettings[key] != null && key != 'logo' && key != 'cover_image') {
        formData.append(key, getters.getSettings[key])
      }
      if(key == 'logo' && typeof getters.getSettings['logo'] != 'string') {
        formData.append(key, getters.getSettings[key])
      }
      if(key=='cover_image' && typeof getters.getSettings['cover_image'] != 'string') {
        formData.append(key, getters.getSettings[key])
      }
    }
    return ApiService.post(`/config-api/v-1/update-contacts/`, formData)
  },
  fetchSettings({ commit }) {
    return ApiService.get('/config-api/v-1/contacts/').then((response) => {
      if (response.data.length > 0) {
        commit('setSettings', response.data)
      }
    })
  },
  sendEmail(_, data) {
    return ApiService.post('/config-api/v-1/send-contact-details/', data)
  },
  createSocietyFees({ dispatch, getters }) {
    let formData = new FormData()
    for (let key in getters.getSocietyFees) {
      if (getters.getSocietyFees[key] != null) {
        formData.append(key, getters.getSocietyFees[key])
      }
    }
    return ApiService.post('/config-api/v-1/society-fees/', formData).then(() => {
      dispatch('fetchSocietyFees')
    })
  },
  fetchSocietyFees({ commit }) {
    return ApiService.get('/config-api/v-1/society-fees/').then((response) => {
      commit('setSocietyFees', response.data)
    })
  }
}

const getters = {
  getSettings: state => state.setting,
  getSocietyFees: state => state.societyFees
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
