<template>
    <div>
        <v-row>
            <v-col cols="12">
                <!-- <v-carousel v-show="check" light cycle interval="6000" :show-arrows="false" hide-delimiter-background hide-delimiters
          delimiter-icon="mdi-minus" class="carousel" height="650">
          <v-carousel-item  v-for="(item, i) in items" :key="i" :src="item.src" transition="scroll-x-transition" eager>
          </v-carousel-item>
        </v-carousel> -->
                <v-card flat tile>
                    <v-window v-model="onboarding" continuous v-show="check">
                        <v-window-item v-for="(item, i) in items" :key="i" align="center" justify="center" transition="scroll-x-transition">
                            <!-- <v-card color="" height="">
                                <v-row class="fill-height" align="center" justify="center"> -->
                                 <v-img :src="item.src" position="top center" contain />
                                <!-- </v-row>
                            </v-card> -->
                        </v-window-item>
                    </v-window>

                    <!-- <v-card-actions class="justify-space-between">
                        <v-btn text @click="prev">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-item-group v-model="onboarding" class="text-center" mandatory>
                            <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
                                <v-btn :input-value="active" icon @click="toggle">
                                    <v-icon>mdi-record</v-icon>
                                </v-btn>
                            </v-item>
                        </v-item-group>
                        <v-btn text @click="next">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-actions> -->
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Image1 from '@/assets/society.jpg'
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    // import Image2 from '@/assets/hero02__1615360295_203.109.44.209.jpg'
    // import Image3 from '@/assets/hero03__1615360359_203.109.44.209.jpg'
    // import Image4 from '@/assets/hero04__1615360459_203.109.44.209.jpg'
    export default {
        data: () => ({
            items: [{
                    src: Image1,
                },
                // {
                //   src: Image2,
                // },
                // {
                //   src: Image3,
                // },
                // {
                //   src: Image4,
                // },
            ],
            check: false,
            img_height: 0,
            return: {
                size: 'contain',
                position: 'left top',
            },
            length: 3,
            onboarding: 0,

        }),
        computed: {
            ...mapGetters('settings', {
                settings: 'getSettings'
            }),
        },
        methods: {
            ...mapActions('settings', [
                'fetchSettings'
            ]),
            getImageHeight(url) {

                var img = new Image;
                img.src = url
                img.onload = function () {
                    // console.log("height",this.height)
                    this.img_height = this.height
                    console.log("height", this.img_height)
                    if (this.img_height < 650) {
                        this.position = 'left top'
                        this.size = 'contain'
                    }
                }
            },
            
        },
        created() {
            this.fetchSettings().then(() => {
                if (this.settings.cover_image) {
                    this.items.pop()
                    this.items.push({
                        src: this.settings.cover_image
                    })

                }
                this.check = true
                this.getImageHeight(this.settings.cover_image)
            })
        }
    }
</script>

<style scoped>
    .carousel {
        position: relative;
    }

    .carousel>>>.v-carousel__controls {
        justify-content: flex-end;
        bottom: 45%;
    }

    .carousel>>>.v-item-group {
        transform: rotate(90deg);
        background-color: rgba(128, 128, 128, 0.1);
        backdrop-filter: blur(10px);
    }

    .carousel>>>.v-item--active {
        color: #434D3D !important;
    }

    .theme--light>>>.v-btn--active:hover::before,
    .theme--light>>>.v-btn--active::before {
        opacity: 0 !important;
    }

    .carousel>>>.v-carousel__controls__item {
        color: white;
        opacity: 1;
        z-index: 2;
    }

    .carousel>>>.v-btn--icon.v-size--small {
        width: inherit;
        height: inherit;
    }

    .carousel>>>.v-carousel__controls__item .v-icon {
        font-size: 60px !important;
        font-weight: bold !important;
        opacity: 1 !important;
    }

    .carousel-center-text-card {
        background-color: #88394A !important;
        backdrop-filter: blur(24px) !important;
    }

    .carousel-message {
        position: absolute;
        top: 450px;
        left: 10%;
        right: 43%;
    }

    .carousel-message .message {
        word-break: break-word !important;
    }

    .carousel-society-registration {
        position: absolute;
        top: 150px;
        left: 45%;
        right: 0%;
    }

    .rounded-border-registration {
        border-bottom-left-radius: 25% 100%;
        border-top-left-radius: 25% 100%;

    }

    .registration-card-color {
        background: linear-gradient(to right, rgba(66, 61, 61), rgba(183, 158, 138, 0.8));
        /* background: linear-gradient(to right, #1A237E, #CFD8DC); */
        /* background: linear-gradient(to right, #607D8B, #CFD8DC); */
    }

    .v-window-item>>>.v-image .v-image__image--cover {
        /* background-size: contain !important; */
        /* background-position: left top !important;  */
        background-position: v-bind('position');
        background-size: v-bind('size');
    }

    @media (max-width: 840px) {
        .small-off {
            display: none;
        }

        .carousel {
            height: 500px !important;
        }
    }

    @media (max-width: 500px) {
        .carousel {
            height: 300px !important;
        }
    }
</style>
