<template>
  <div class="profile pt-6 mb-16">
    <v-card elevation="0">
      <v-row class="mt-2" justify="start" align="start">
        <v-col cols="9">
          <v-card elevation="0">
            <v-card-text
              class="
                text-subtitle-1 text-justify
                d-flex
                justify-start
                px-2
                py-2
              "
              style="background: #fff0e1"
            >
              You can transfer the money with the following modes of payment
              below.<br />
              Once you transfer the money, please click the button "Payment
              Done".<br />
              This will notify the Admin that you have made the payment.<br />
              It is advised that you should retain the receipt or proof if
              payemnt in case of any disputes.
            </v-card-text>
            <v-card-text
              class="text-subtitle-1 font-weight-bold pl-0 ml-0"
              style="color: #ca4627"
            >
              Please note that your payment will reflect here only after
              approval by Admin
            </v-card-text>
            <v-card-text class="text-h5 font-weight-bold pl-0 ml-0 pt-0">
              Amount you are paying: INR {{ payment.amount }}
            </v-card-text>
            <v-card-text class="pl-0 ml-0" v-if="settings.enable_netbank">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                Netbanking
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 text-subtitle-2 pt-0" v-if="settings.enable_netbank">
              <div>
                Benificiary Name:
                <span class="font-weight-black">{{
                  settings.beneficiary_name
                }}</span>
              </div>
              <div>
                Bank Name:
                <span class="font-weight-black">{{ settings.bank_name }}</span>
              </div>
              <div>
                Account Number:
                <span class="font-weight-black">{{
                  settings.account_number
                }}</span>
              </div>
              <div>
                Account Type:
                <span class="font-weight-black">{{
                  settings.account_type
                }}</span>
              </div>
              <div>
                IFSC Code:
                <span class="font-weight-black">{{ settings.ifsc_code }}</span>
              </div>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <v-row>
                <v-col cols="6">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pl-0 ml-0" v-if="settings.enable_upi">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                UPI
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 text-subtitle-2 pt-0" v-if="settings.enable_upi">
              <div v-if="settings.upi_id">
                UPI ID:
                <span class="font-weight-black">{{ settings.upi_id }}</span>
              </div>
              <v-row v-if="settings.upi_id && settings.mobile_number">
                <v-col cols="4">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
              <div v-if="settings.mobile_number">
                Mobile Number:
                <span class="font-weight-black">{{ settings.mobile_number }}</span>
              </div>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <v-row>
                <v-col cols="6">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pl-0 ml-0" v-if="settings.enable_googlepay">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                Google Pay
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 pt-0">
              <v-img
                v-if="settings.qr_code"
                :src="settings.qr_code"
                width="14%"
              ></v-img>
            </v-card-text>

            <v-card-text class="pl-0 ml-0">
              <v-btn
                :disabled="payment.status !== 'unpaid'"
                class="white--text rounded mr-2"
                color="#67AC5B"
                @click="requestPayment"
              >
                Payment Done
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="showStatusOfPayment" width="500" persistent>
      <v-card
        justify="center"
        align="center"
        class="rounded-lg"
        style="padding-top: 40px; padding-bottom: 40px"
      >
        <v-card-text
          class="d-flex justify-center align-center px-0 py-0"
          style="
            border-radius: 50%;
            border: 4px solid #f1f7ec;
            width: 120px;
            height: 120px;
            margin-bottom: 25px;
          "
        >
          <v-icon color="error" style="font-size: 80px"> mdi-check </v-icon>
        </v-card-text>
        <v-card-text>
          <span dense class="text-h4 font-weight-bold" text type="success">
            Payment request is made succesfully.
          </span>
          <br />
          <v-btn
            class="white--text text-uppercase mx-2 btn-width"
            color="#423D3D"
            @click="closePopup"
            >Cancel</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay v-model="overlays">
      <v-progress-circular
        indeterminate
        color="white"
        width="5"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import qrcode from "@/assets/qr-code.webp";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  title: () => "Fees Payment",
  metaInfo: {
    title: "Fees Payment",
  },
  data: () => ({
    qrcode,
    overlays: false,
    errorMsg: "",
    showStatusOfPayment: false,
  }),
  computed: {
    ...mapGetters("settings", {
      settings: "getSettings",
    }),
    ...mapGetters("fees", {
      payment: "getPayment",
    }),
    payment_id() {
      return this.$route.params.payment_id;
    },
  },
  methods: {
    ...mapActions("settings", ["fetchSettings"]),
    ...mapActions("fees", [
      "getPaymentByID",
      "paymentRequest",
      "getPaymentMulti",
    ]),
    ...mapMutations("fees", ["resetPayment"]),
    requestPayment() {
      this.overlays = true;
      this.paymentRequest({ payment_id: this.payment_id })
        .then(() => {
          console.log("payment requested");
          this.showStatusOfPayment = true;
        })
        .finally(() => {
          this.overlays = false;
        });
    },
    closePopup() {
      this.showStatusOfPayment = false;
      this.$router.push("/account/fees");
    },
  },
  mounted() {
    this.overlays = true;
    this.fetchSettings().then(() => {
      console.log('settings', this.settings)
    })
    if (!isNaN(this.payment_id)) {
      this.getPaymentByID(this.payment_id).finally(() => {
        this.overlays = false;
      });
    } else {
      this.getPaymentMulti({ payment_id: this.payment_id }).finally(() => {
        this.overlays = false;
      });
    }
  },
  beforeDestroy() {
    Object.assign(this.$data, this.$options.data.apply(this));
    this.resetPayment();
  },
};
</script>
<style scoped>
.btn-underline:hover {
  text-decoration: none !important;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: black;
}

/* To show the lines on right 
and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border: 0.5px solid black;
  background: black;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}
</style>