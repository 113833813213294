<template>
  <v-container class="pt-10 ml-0 pl-0">
    <v-row>
      <v-col cols="12" class="ml-0 pl-0">
        <v-row>
          <v-col cols="12">
            <searchBar label="My Complaints" :value="complaints.count" @searching="searchQuery=$event">
            </searchBar>
          </v-col>
          <v-col cols="6" class="pb-0 mb-0 pl-0 ml-0">
            <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline ml-0 pl-3"
              color="primary" plain to="/complaints" :ripple="false">
              View all complaints
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-0 mr-0 pb-0 mb-0">
            <v-btn class="mr-3 white--text rounded" color="#67ac5b" @click="$router.push('/addcomplaint')">
              <v-icon class="mx-1">mdi-plus</v-icon>Add Complaints
            </v-btn>
          </v-col>
        </v-row>

        <v-card class="mb-3 my-6" elevation="0" v-for="(info, index) in filteredComplaints" :key="index">

          <v-row class="ml-0 pl-0" align="start" justify="start" style="">
            <v-col class="ml-0 pl-0" v-if="info.title || info.description">

              <v-row class="ml-0 pl-0">
                <v-card-title v-text="info.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                  style="font-size:18px !important;"></v-card-title>
                <v-card-text class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                  <span class="text-decoration-underline text-capitalize font-italic">{{info.full_name}},</span>
                  <span class="pl-1 font-italic">{{getDate(info.added)}}</span>
                </v-card-text>

                <v-card-text class="text-subtitle-1 font-weight-regular px-0 py-2">
                  <span v-if="info.show_full_description">{{info.description}}...</span>
                  <span v-else>{{info.description.slice(0, 100)}}...</span>
                  <!-- <a @click="moreLess(index)">{{linkText(info)}}</a> -->
                  <a @click="complaintSelected(info)">{{linkText(info)}}</a>
                </v-card-text>
              </v-row>
            </v-col>

          </v-row>

          <v-row class="d-flex justify-end" style="margin-top:10px !important">

            <v-chip outlined label :color="info.status=='rejected'? 'red': info.status=='new'?'warning':'green'"
              v-if="info.solution_status=='unsolved'" class="rounded my-2 text-uppercase subtitle-1">
              {{info.status == 'new'? 'pending': info.status}}</v-chip>
            <v-chip outlined label color="green" v-if="info.solution_status=='solved'"
              class="rounded my-2 text-uppercase subtitle-1">closed</v-chip>


            <v-btn color="#7b7a81" @click="editComplaint(info)" small class="mx-2 my-2  white--text ">Edit</v-btn>
            <v-btn color="#d63928" @click="deletePost(info)" small class="mx-2 my-2 ml-0 white--text ">Delete</v-btn>

          </v-row>


          <v-divider class="mt-3"></v-divider>

        </v-card>

        <v-row justify="center" align="center" class="mt-1 mb-3">
          <v-pagination v-model="page" :length="Math.ceil(complaints.count/10)" :total-visible="7" color="primary"
            @input="fetchUserComplaintsAndGrievancesWithPage($event)"
            @previous="fetchUserComplaintsAndGrievancesWithUrl(complaints.previous)"
            @next="fetchUserComplaintsAndGrievancesWithUrl(complaints.next)"></v-pagination>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showEdit" width="700" persistent>
      <EditComplaint :complaints_id="editedComplaintId" :title="editedTitle" :description="editedDescription"
        @cancel-edit="showEdit=false" @title-changed="editedTitle=$event"
        @description-changed="editedDescription=$event">
      </EditComplaint>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600" persistent>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>Do you want to delete this complaint?</v-card-title>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="green--text" @click="confirmDelete">Yes</v-btn>
                <v-btn class="red--text" @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import EditComplaint from '@/components/EditComplaint.vue'
  import searchBar from '@/components/searchBar';
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'
  export default {
    title: () => ('My Complaints'),
    metaInfo: {
      title: 'Residents Complaints'
    },
    components: {
      EditComplaint,
      searchBar
    },
    data: () => ({
      showMore: false,
      //  linkText: 'Read full complaint',
      disabledButtons: false,
      showEdit: false,
      editedComplaintId: new Number(),
      editedTitle: '',
      editedDescription: '',
      deleteDialog: false,
      deleteComplaintId: new Number(),
      overlay: false,
      page: 1,
      complaintPerPage: 5,
      searchQuery: '',
      complaintItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ]
    }),
    computed: {
      ...mapGetters('user', {
        complaints: 'getComplaints'
      }),
      currentPageComplaints() {
        return this.complaintPerPage != null ? this.complaints.data.slice((this.page - 1) * this.complaintPerPage, (this
          .page - 1) * this.complaintPerPage + this.complaintPerPage) : this.complaints.data
      },
      filteredComplaints() {
        if (this.searchQuery) {
          return this.complaints.data.filter((complaint) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return complaint.title.toLowerCase().includes(search) || complaint.description.toLowerCase()
                .includes(search)
            })
          })
        } else {
          return this.complaints.data
        }
      }
    },
    methods: {
      ...mapActions('user', [
        'fetchUserComplaintsAndGrievances',
        'deleteComplaintsAndGrievances',
        'fetchUserComplaintsAndGrievancesWithPage',
        'fetchUserComplaintsAndGrievancesWithUrl'
      ]),
      ...mapMutations('user', [
        'setComplaintsProperty',
        'setComplaint'
      ]),
      moreLess(index) {
        this.showMore = !this.showMore
        this.setComplaintsProperty({
          index: index,
          property: 'show_full_description',
          value: this.showMore
        })
      },
      linkText(complaint) {
        if (complaint.show_full_description) {
          return 'less'
        } else {
          return 'Read full complaint'
        }
      },
      editComplaint(post) {
        this.editedComplaintId = post.id
        this.editedTitle = post.title
        this.editedDescription = post.description
        this.showEdit = true
      },
      deletePost(post) {
        this.deleteComplaintId = post.id
        this.deleteDialog = true
      },
      cancelDelete() {
        this.deleteComplaintId = new Number()
        this.deleteDialog = false
      },
      confirmDelete() {
        this.deleteComplaintsAndGrievances(this.deleteComplaintId).then(() => {
          this.fetchUserComplaintsAndGrievances()
          this.deleteDialog = false
        })
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      },
      complaintSelected(complaint) {
        this.setComplaint(complaint)
        this.$router.push(`/complaint/${complaint.title.replace(/\s/g, '-')}-${complaint.id}/`)
      },
    },
    mounted() {
      this.overlay = true
      this.fetchUserComplaintsAndGrievances().then(() => {
        this.overlay = false
         for (let complaint in this.complaints.data) {
          this.setComplaintsProperty({
            index: complaint,
            property: 'show_full_description',
            value: false
          })
        }
      })

    }
  }
</script>
<style scoped>
  .text-card {
    overflow-y: scroll;
    padding: 0;
  }
</style>