<template>
  <div class="profile pt-6 mb-16">
    <v-card elevation="0">
      <v-row class="mt-2" justify="start" align="start">
        <v-col cols="8">
          <span class="text-h5 font-weight-bold text-uppercase d-flex justify-start px-0"
            style="color: #62608b">Profile</span>
        </v-col>
        <v-col cols="8" class="">
          <!-- <v-img :src="profileImage" max-width="30%"></v-img> -->
          <v-img v-if="user.profile_photo && !profilepic" class="pt-2 pb-2" :src="profilePhotoUrl" max-width="30%"
            @click="selectImage('profile-pic')" ></v-img>
          <div v-else>
            <v-img v-if="profilepic" class="pt-2 pb-2" :src="profilepic" max-width="30%"
              @click="selectImage('profile-pic')" ></v-img>
            <v-img v-else class="pt-2 pb-2" :src="profileImage" max-width="30%" @click="selectImage('profile-pic')"></v-img>
            <v-file-input id="profile-pic" @change="onAddPhoto" truncate-length="15"
                                      style="display:none"></v-file-input>
          </div>
          <div>
            
          </div>
          <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline ml-0 pl-0"
            color="#5da55b" plain :ripple="false"  @click="selectImage('profile-pic')">
            Upload
          </v-btn>
          <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline ml-11" color="#d51e43"
            plain :ripple="false" @click="deleteProfilePhoto">
            Delete
          </v-btn>
        </v-col>
        <v-col cols="8" class="py-0 my-0">
          <span v-if="!$v.user.mobile_number.required && $v.user.mobile_number.$dirty"
            class="validation-text red--text text-body-2">Mobile number required.</span>
          <span v-if="!$v.user.mobile_number.phoneNumber && $v.user.mobile_number.$dirty"
            class="validation-text red--text text-body-2">Mobile Number Must be valid.</span>
          <span v-if="!$v.user.mobile_number.isNumberExist && $v.user.mobile_number.$dirty"
            class="validation-text red--text text-body-2">Mobile Number already exist.</span>
          <v-text-field class="rounded" label="Mobile Number*" outlined dense :value="$v.user.mobile_number.$model"
            :rules="[$v.user.mobile_number.required, rules.phoneNumber]"
            @input="updateUserProperty('mobile_number', $event)"></v-text-field>
        </v-col>

        <v-col cols="8" class="py-0 my-0">
          <span v-if="!$v.user.full_name.required" class="red--text text-body-2">Full Name required.</span>
          <v-text-field class="rounded" label="Full Name*" outlined dense :value="$v.user.full_name.$model"
            @input="updateUserProperty('full_name', $event)"></v-text-field>
        </v-col>
        <v-col cols="8" class="py-0 my-0">
          <span v-if="!$v.user.address.required" class="red--text text-body-2">Address required.</span>
          <v-text-field class="rounded" label="Address*" outlined dense :value="$v.user.address.$model"
            @input="updateUserProperty('address', $event)"></v-text-field>
        </v-col>

        <v-col cols="8" class="py-0 my-0">
          <span v-if="!$v.user.email.email" class="red--text text-body-2">Email invalid.</span>
          <v-text-field class="rounded" label="Email*" outlined dense :value="$v.user.email.$model"
            @input="updateUserProperty('email', $event)"></v-text-field>
        </v-col>
        <v-col cols="8" class="py-0 my-0">
          <v-text-field class="rounded" label="Company*" outlined dense :value="$v.user.company.$model"
            @input="updateUserProperty('company', $event)"></v-text-field>
        </v-col>
        <v-col cols="8" class="py-0 my-0">
          <v-text-field class="rounded" label="Designation*" outlined dense :value="$v.user.designation.$model"
            @input="updateUserProperty('designation', $event)"></v-text-field>
        </v-col>
        <v-col cols="8" class="d-flex">
          <span class="text-h5 font-weight-bold text-uppercase d-flex justify-start px-0" style="color: #62608b">Family Details</span>
          <v-spacer></v-spacer>
          <v-btn color="#5da55b" class="text-uppercase white--text" @click="addFamilyMember(user.id)">+ Add Member</v-btn>
        </v-col>
        <v-col cols="8" class="py-0 my-0" v-for="(v, index) in $v.familyMembers.$each.$iter" :key="index">
          <v-row>
            <v-col cols="5">
              <span v-show="!v.relation.required && v.relation.$dirty" class="validation-text red--text text-body-2">Relation required.</span>
              <v-select
                :value="v.relation.$model"
                label="Relation"
                outlined
                dense
                :items="relationItems"
                item-text="name"
                item-value="value"
                @input="updateFamilyProperty(index, 'relation', $event)"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <span v-show="!v.member_name.required && v.member_name.$dirty" class="validation-text red--text text-body-2">Family member name required.</span>
              <v-text-field 
                :value="v.member_name.$model"
                outlined
                dense
                label="Member Name"
                @input="updateFamilyProperty(index, 'member_name', $event)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn small fab text class="project_red--text" @click="deleteMember(v.id.$model, index)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8" class="" justify="start" align="start">
          <v-btn @click="save" color="#5da55b" class="text-uppercase white--text mr-2 btn-width"
            :disabled="disabledSave">Save</v-btn>
          <v-btn @click="resetUser" color="error" class="text-uppercase white--text ml-2 btn-width"
            :disabled="disabledSave">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="sendOtp" persistent width="500">
      <SendOtp title="Verify OTP" :data="user" otp_type="number_change" :mobile_number="user.mobile_number"
        @cancel-otp="sendOtp=false" @verify-success="save" @verify-failed="errorMsg='Invalid OTP.'"
        @invalid-request="errorMsg='Somthing went wrong.'"></SendOtp>
    </v-dialog>
  </div>
</template>
<script>
  import profileImage from '@/assets/profile.jpg'
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'
  import {
    required,
    numeric,
    maxValue,
    email
  } from 'vuelidate/lib/validators'
  import {
    helpers
  } from 'vuelidate/lib/validators'
  import SendOtp from '@/components/SendOtp'
  const phoneNumber = helpers.regex('phoneNumber', (
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))
  export default {
    title: () => ('Resident Profile'),
    metaInfo: {
      title: 'Resident Profile'
    },
    components: {
      SendOtp
    },
    data: () => ({
      typeCheck: false,
      profilepic: '',
      profileImage,
      rules: {
        phoneNumber: val => (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val) ||
          /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val)) || 'Phone Number Must be valid',
      },
      relationItems: [{
          name: 'Husband',
          value: 'husband'
        },
        {
          name: 'Wife',
          value: 'wife'
        },
        {
          name: 'Father',
          value: 'father'
        },
        {
          name: 'Mother',
          value: 'mother'
        },
        {
          name: 'Son',
          value: 'son'
        },
        {
          name: 'Daughter',
          value: 'daughter'
        },
        {
          name: 'Other',
          value: 'other'
        },
      ],
      disabledSave: false,
      overlay: false,
      sendOtp: false,
      errorMsg: '',
      familyData: [],
    }),
    computed: {
      ...mapGetters('user', {
        user: 'getUser',
        familyMembers: 'getFamily'
      }),
      familyMembersNumber() {
        return this.user.family_members
      },
      profilePhotoUrl() {
        return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}` + this.user.profile_photo
      }
    },
    methods: {
      selectImage(elementId) {
        document.getElementById(elementId).click()
      },
      onAddPhoto(file) {
        const fileType = ["image/jpg", "image/jpeg", "image/png", "image/webp"]
        if (!fileType.includes(file.type)) {
          this.typeCheck = true
        } else {
          this.typeCheck = false
          const reader = new FileReader()
          reader.addEventListener('load', e => this.profilepic = e.target.result)
          reader.addEventListener('error', () => this.profilepic = this.errorPhoto)
          reader.readAsDataURL(file)
          this.updateUserProperty('profile_photo', file)
        }
      },
      deleteProfilePhoto() {
        this.setUserProperty({
          property: 'profile_photo',
          value: null
        })
      },
      ...mapActions('user', [
        'fetchUser',
        'fetchFamily',
        'updateUser',
        'checkUserExist',
        'updateFamilyMembers',
        'deleteFamilyMember'
      ]),
      ...mapMutations('user', [
        'setUserProperty',
        'setFamilyProperty',
        'resetUser',
        'setFamily',
        'addFamilyMember',
        'removeFamilyMember'
      ]),
      updateUserProperty(property, value) {
        if (property === "family_members") {
          if (value > this.familyMembers.length) {
            let addedValue = value - this.familyMembers.length
            for (let i = 0; i < addedValue; i++) {
              this.familyData.push({
                'relation': '',
                'member_name': ''
              })
            }
            this.setFamily(this.familyData)
          }
        }
        this.setUserProperty({
          property,
          value
        })
        this.$v.user[property].$touch()
      },
      updateFamilyProperty(index, property, value) {
        this.setFamilyProperty({
          index,
          property,
          value
        })
        this.$v.familyMembers.$touch()
      },
      save() {
        this.$v.$touch()
        this.disabledSave = true
        this.overlay = true

        if (!this.$v.$invalid) {
          this.setUserProperty({
            property: 'family_members',
            value: this.familyMembers.length
          })
          this.updateUser().then((response) => {
            if (response.data.otp || response.data.matched_failed) {
              this.sendOtp = true
            } else {
              this.updateFamilyMembers(this.familyMembers).then(()=> {
                location.reload()
              })
            }
            this.overlay = false
          }).catch(() => {
            this.disabledSave = false
            this.overlay = false
          }).finally(() => {
            this.disabledSave = false
            this.overlay = false
          })
        } else {
          this.disabledSave = false
        }
      },
      deleteMember(id, index) {
        this.disabledSave = true
        this.overlay = true
        this.removeFamilyMember(index)
        this.deleteFamilyMember({'id': id}).finally(() => {
          this.disabledSave = false
          this.overlay = false
        })
      }
    },
    mounted() {
      this.overlay = true
      this.fetchUser().then(() => {
        this.fetchFamily().then(() => {
          this.overlay = false
          console.log('user', this.user)
        })
      })
    },
    validations() {
      let data = {
        user: {
          mobile_number: {
            required,
            phoneNumber,
            async isNumberExist(value) {
              if (value === '') return true
              const response = await this.checkUserExist({
                userData: {
                  mobile_number: value
                }
              })
              if (response.data.status) {
                return !response.data.isUser
              } else {
                return true
              }
            }
          },
          full_name: {
            required
          },
          address: {
            required
          },
          email: {
            email
          },
          company: {

          },
          designation: {

          },
          family_members: {
            numeric,
            maxValue: maxValue(10)
          }
        },
        familyMembers: {
          $each: {
            relation: {
              required
            },
            member_name: {
              required
            },
            id: {}
          }
        },
        familyMembersNumber: {
          required,
          numeric,
          maxValue: maxValue(10)
        }
      }
      return data
    },
  }
</script>
<style scoped>
  .btn-underline:hover {
    text-decoration: none !important;
  }
</style>