<template>
  <div class="mt-6">
    <!-- <v-row class="related-events-title" align="center" justify="start">
      <v-col cols="4">
        <span class="heading-title text-capatilize">Related Events</span>
      </v-col>
      <v-divider class="title-line"></v-divider>
    </v-row>
    <v-row class="related-events-content" justify="start">
      <v-col cols="4" class="mb-3" v-for="(item, index) in events" :key="index">
        <EventCard
          @event-clicked="eventSelected($event)"
          :item="item"
          :imageHeight="200"
        ></EventCard>
      </v-col>
    </v-row>
    <v-row align="center" justify="end" v-if="!loadComplete" class="read-more">
      <v-btn
        text
        class="read-more-btn"
        @click="loadAllEvents"
        :disabled="loading"
        :loading="loading"
      >
        <span class="text-capitalize">read more</span>
        <v-icon size="20">mdi-arrow-right</v-icon>
        <template v-slot:loader>
          <span class="text-capitalize">loading...</span>
        </template>
      </v-btn>
    </v-row> -->

    <v-row align="center">
      <v-col cols="6" class="d-flex justify-start  mb-2 pb-0">
        <span class="font-weight-bold" style="font-size:34px !important;color:black !important">Upcoming Events</span>
      </v-col>

      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0" color="primary"
          plain to="/events">
          view all events
        </v-btn>
      </v-col>

      <v-col v-if="events.length==0" class="mt-0 pt-0">
          <v-card elevation="4" class="text-card" color="#eef1f6" height="">
            
            <v-card-text class="text-h5 pa-0 text-capitalize font-weight-medium text-center font-italic pa-16"
            style="font-size:18px !important;">
              There are no events to display
            </v-card-text>
        
        </v-card>
      </v-col>  

      <v-col cols="4" v-else v-for="(item, index) in events" :key="index" class="mt-0 pt-0">

        <v-card elevation="4" class="text-card" color="#eef1f6" >
         
          <v-img height="200" v-if="item.thumbnail" :src="item.media_file"></v-img>
          <v-img height="200" contain v-else :src="defaultDiscussionImage" style="background-color:#e9e9e9"></v-img>
          <div class="pa-4">
              <v-card-title v-if="item.title.length>33" v-text="item.title.slice(0,33) + '...'"  class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                style="font-size:18px !important;">
              </v-card-title>
              <v-card-title v-else v-text="item.title"  class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                style="font-size:18px !important;">
              </v-card-title>
              <div style="height:90px">
                  <v-card-text  class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                    <span>{{item.description.slice(0, 100)}}...</span>
                    <a @click="eventSelected(item)">{{linkText}}</a>
                  </v-card-text>
              </div>
                          
          </div>

        </v-card>

      </v-col>
    </v-row>


  </div>
</template>

<script>
  import {
    mapMutations
  } from 'vuex'
  //import EventCard from '@/components/EventCard'
  import defaultDiscussionImage from '@/assets/default-discussion-image.png'
  export default {
    props: [
      'events',
      'loadComplete'
    ],
    components: {
      //EventCard
    },
    data: () => ({
      defaultDiscussionImage,
      loading: false,
      linkText: 'View more',
      showMore: false,
    }),
    watch: {
      loadComplete() {
        if (this.loadComplete) {
          this.loading = false
        } else {
          this.loading = true
        }
      }
    },
    methods: {
      ...mapMutations('event', [
        'setEvent'
      ]),
      eventSelected(event) {
        this.setEvent(event)
        this.$router.push(`/event/${event.title.replace(/\s/g, '-')}-${event.id}/`)
      },
      loadAllEvents() {
        this.$emit('load-all-events')
        this.loading = true
      },
      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'View more'
        }
      },

    }
  }
</script>

<style scoped>
  .related-events {
    background-color: #F8F8F8 !important;
    padding-bottom: 55px !important;
  }

  .related-events .related-events-title {
    padding-left: 18% !important;
    padding-top: 26px !important;
  }

  .related-events .related-events-content {
    padding: 8px 18% !important;
  }

  .heading-title {
    font-size: 3rem !important;
  }

  /* .related-events .image {
  border-radius: 10px !important;
} */

  .event-date {
    font-size: 33px !important;
  }

  .event-month {
    font-size: 14px !important;
  }

  .title-left-border {
    border-left: 5px solid #423D3D !important;
    max-width: 0px !important;
  }

  .title {
    font-size: 28px !important;
    word-break: break-word;
  }

  .title-line {
    border-color: #423D3D !important;
  }

  .description {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .read-more {
    padding: 0 80px;
  }

  .read-more-btn {
    font-size: 20px !important;
    color: #423D3D !important;
  }

  .btn-underline:hover {
    text-decoration: none !important;
  }

  /* .events-card {
  border-bottom: 1px solid #423D3D !important;
} */
</style>