<template>
  <v-container class="py-0 my-0">
    <v-row justify="center" v-show="check">
      <v-col cols="3" xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
         <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
          <v-divider class="mr-2"></v-divider>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled"
                :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index"
                :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled"
                :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="" cols="9">
        <v-card elevation="0">
          <v-card-title class="text-center text-h4 font-weight-bold pb-5" style="color: #62608b">Create Notice
          </v-card-title>
          <v-row class="px-4 pb-8" justify="start">
            <!-- <v-col cols="6">
              <v-file-input
                id="select-image"
                accept="image/*"
                label="Images"
                v-model="informationData.media_file"
                v-show="false"
                @change="onAddImages"
              ></v-file-input>
              <v-img max-height="150" v-if="informationData.media_file" max-width="200" :src="imageUrl" @click="selectImage"></v-img>
              <v-card class="select-image-card" elevation="0" v-else width="595" height="370">
                <v-row class="select-image" align="center" no-gutters>
                  <v-col cols="3" class="justify-center mx-auto">
                    <v-btn text large @click="selectImage">
                      <v-icon color="#D4C6BA" size="80">mdi-cloud-upload</v-icon>
                    </v-btn>
                    <v-card-text class="my-0">Upload here</v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <v-form if="create-notice" @submit.prevent="createInformation">
              <div v-if="!$v.informationData.title.required && $v.informationData.title.$dirty"
                class="validation-text red--text text-body-2 error-span">Title required.</div>
              <div v-if="!$v.informationData.title.maxLength" class="validation-text red--text text-body-2 error-span">
                Title should be {{$v.informationData.title.$params.maxLength.max}} characters long.</div>
              <v-text-field class="rounded" label="Title*" autofocus v-model="$v.informationData.title.$model" outlined
                dense></v-text-field>

              <div v-if="!$v.informationData.description.required && $v.informationData.description.$dirty"
                class="validation-text red--text text-body-2 error-span">Description required.</div>
              <div v-if="!$v.informationData.description.maxLength"
                class="validation-text red--text text-body-2 error-span">Description should be
                {{$v.informationData.description.$params.maxLength.max}} characters long.</div>
              <v-textarea class="rounded" v-model="$v.informationData.description.$model" label="Description*" auto-grow
                outlined></v-textarea>
              <v-card-actions class="mx-0 px-0 py-0">
                <v-btn  min-width="100px" class="white--text text-uppercase btn-width" color="#477fca"
                  :disabled="overlay" type="submit" for="create-notice">Create</v-btn>
              </v-card-actions>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="postComplete" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg"  style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Notice created successfully</span>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="finishedPost">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import {
    required,
    maxLength
  } from 'vuelidate/lib/validators'
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  // import UploadImages from "vue-upload-drop-images"
  export default {
    title: 'Add News',
    metaInfo: {
      title: 'Add Notice'
    },
    components: {
      // UploadImages
    },
    data: () => ({
      informationData: {
        title: '',
        media_file: undefined,
        description: ''
      },
      imageUrl: '',
      errorImage: '',
      postComplete: false,
      overlay: false,
      selectedListItem: 0,
      settingsListItem: 0,
      bottomListItem: 0,
      check:false,
      profileList: [{
          text: 'Profile',
          icon: 'mdi-account',
          disabled: false,
          path: 'profile'
        },
        {
          text: 'My Discussions',
          icon: 'mdi-post',
          disabled: false,
          path: 'mydiscussion'
        },
        {
          text: 'My Complaints',
          icon: 'mdi-notebook-edit-outline',
          disabled: false,
          path: 'mycomplains'
        },
        {
          text: 'Fees',
          icon: 'mdi-currency-inr',
          disabled: false,
          path: 'fees'
        },
      ],
      settingsList: [{
        text: 'Change Password',
        icon: 'mdi-lock',
        disabled: false,
        path: 'password'
      }, ],
      bottomList: [{
        text: 'Home',
        icon: 'mdi-home',
        disabled: false,
        path: ''
      }, ],
    }),
    validations() {
      return {
        informationData: {
          title: {
            required,
            maxLength: maxLength(100)
          },
          description: {
            required,
            maxLength: maxLength(20000)
          }
        }
      }
    },
    computed: {
      ...mapGetters('user', {
        user: 'getUser'
      }),
      ...mapGetters('society', {
        society: 'getSociety'
      }),
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        isDefaultPassword: 'isDefaultPassword'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      })
    },
    watch: {
      postComplete() {
        if (this.postComplete) {
          setTimeout(() => {
            this.postComplete = false
            this.$router.push({
              path: '/'
            })
          }, 3000)
        }
      },
    },
    methods: {
      ...mapActions('event', [
        'addNotice'
      ]),
      ...mapActions('society', [
        'fetchSociety'
      ]),
      ...mapActions('user', [
        'fetchUser'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      selectImage() {
        document.getElementById("select-image").click()
      },
      onAddImages(file) {
        const reader = new FileReader()
        reader.addEventListener('load', e => this.imageUrl = e.target.result)
        reader.addEventListener('error', () => this.imageUrl = this.errorImage)
        reader.readAsDataURL(file)
      },
      createInformation() {
        this.overlay = true
        this.$v.$touch()
        if (!this.$v.$invalid) {
          let formData = new FormData()
          if (this.informationData.media_file && this.informationData.media_file.size && this.informationData.media_file
            .size > 0) {
            formData.append('media_file', this.informationData.media_file)
          }
          formData.append('society', this.user.society)
          formData.append('title', this.informationData.title)
          formData.append('description', this.informationData.description)

          this.addNotice(formData).then(() => {
            this.postComplete = true
            this.overlay = false
          }).catch(() => {
            this.overlay = false
          })
        } else {
          this.overlay = false
        }
      },
      finishedPost() {
        this.postComplete = false
        this.$router.push('/account/notice')
      },
      handleImages(file) {
        this.informationData.media_file = file[0]
      }
    },
    mounted() {
      this.fetchUser()
    },
    created() {
      this.fetchSociety()
      this.fetchSettings().then(() => {
        if (this.isAdmin) {
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.settingsList.unshift({
            'text': 'Settings',
            'icon': 'mdi-cog-outline',
            'disabled': this.isDefaultPassword,
            'path': 'settings'
          })
          this.profileList.unshift({
            'text': 'Fees',
            'icon': 'mdi-currency-inr',
            'disabled': this.isDefaultPassword,
            'path': 'fees-settings'
          })
          this.profileList.unshift({
            'text': 'Securities',
            'icon': 'mdi-security',
            'disabled': this.isDefaultPassword,
            'path': 'security-setting'
          })
          if (this.settings.enable_events) {
            this.profileList.unshift({
              'text': 'Events',
              'icon': 'mdi-calendar-today',
              'disabled': this.isDefaultPassword || !this.settings.enable_events,
              'path': 'events'
            })
          }
          this.profileList.unshift({
            'text': 'Complaints',
            'icon': 'mdi-notebook-edit-outline',
            'disabled': this.isDefaultPassword,
            'path': 'complains'
          })
          if (this.settings.enable_posts) {
            this.profileList.unshift({
              'text': 'Discussions',
              'icon': 'mdi-post',
              'disabled': this.isDefaultPassword || !this.settings.enable_posts,
              'path': 'discussion'
            })
          }

          if (this.settings.enable_news) {
            this.profileList.unshift({
              'text': 'Notice Board',
              'icon': 'mdi-information-outline',
              'disabled': this.isDefaultPassword || !this.settings.enable_news,
              'path': 'notice'
            })
          }
          this.profileList.unshift({
            'text': 'Residents',
            'icon': 'mdi-account-check',
            'disabled': this.isDefaultPassword,
            'path': 'residents'
          })
        } else {
          if (!this.settings.enable_posts) {
            this.profileList = this.profileList.filter((value) => {
              return value.path !== 'myposts'
            })
          }
        }
        this.check=true
      })
    }
  }
</script>
<style scoped>
  .add-notice {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .select-image-card {
    background: #F7F7F7;
    border: 1px dashed #423D3D;
  }

  .select-image {
    height: inherit;
  }

  .input-text-section {
    height: 370px;
    display: grid;
    align-self: center;
  }

  .validation-text {
    position: relative;
    bottom: 20px;
  }


  .error-span {
    margin-bottom: -18px !important;
  }

  .left-container-card {
    position: fixed;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .title-size {
    font-size: 13px;
    text-transform: uppercase;
  }
</style>