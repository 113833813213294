<template>
  <v-container class="add-security py-0">
    <v-row v-show="check">
      <v-col cols="3" xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
         <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
          <v-divider class="mr-2"></v-divider>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled"
                :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index"
                :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled"
                :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10" class="px-0 py-0 ">
        <v-form id="add-security" @submit.prevent="addSecurity">
          <v-card class="mx-auto" elevation="0">
            <v-card-title class="text-center text-h4 font-weight-bold pb-5" style="color: #62608b">Add Security
            </v-card-title>
            <v-row justify="start" class="px-0 mx-0">
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.security.full_name.required && $v.security.full_name.$dirty"
                  class="validation-text red--text text-body-2 error-span">Full Name required.</div>
                <v-text-field class="rounded" label="Full Name*" autofocus v-model="$v.security.full_name.$model"
                  outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.security.father_name.required && $v.security.father_name.$dirty"
                  class="validation-text red--text text-body-2 error-span">Father Name required.</div>
                <v-text-field class="rounded" label="Father Name*" v-model="$v.security.father_name.$model" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.security.mobile_number.required && $v.security.mobile_number.$dirty"
                  class="validation-text red--text text-body-2 error-span">Mobile Number required.</div>
                <div v-if="!$v.security.mobile_number.phoneNumber && $v.security.mobile_number.$dirty"
                  class="validation-text red--text text-body-2 error-span">Mobile Number Must be valid.</div>
                <v-text-field class="rounded" label="Mobile Number*" v-model="$v.security.mobile_number.$model" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.security.gender.required && $v.security.gender.$dirty"
                  class="validation-text red--text text-body-2 error-span">Gender required.</div>
                <v-select v-model.trim="$v.security.gender.$model" class="rounded" label="Gender*" :items="genderArray"
                  item-text="text" item-value="value" outlined dense></v-select>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.dateOfJoining.required && $v.dateOfJoining.$dirty"
                  class="validation-text red--text text-body-2 error-span">This field is required.</div>
                <DatePicker :rounded="'rounded'" :label="'Date of joining*'"
                  @get-date="security.date_of_joining=$event">
                </DatePicker>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 my-0">
                <div v-if="!$v.dateOfBirth.required && $v.dateOfBirth.$dirty"
                  class="validation-text red--text text-body-2 error-span">This field is required.</div>
                <div v-if="!$v.dateOfBirth.maxValue" class="validation-text red--text text-body-2 error-span">This field
                  must be a past date.</div>
                <DatePicker :rounded="'rounded'" :label="'Date of birth*'" @get-date="security.date_of_birth=$event">
                </DatePicker>
              </v-col>
              <v-col cols="12" sm="12" class="py-0 my-0">
                <v-text-field v-model="$v.security.reference.$model" class="rounded" label="Reference"
                  placeholder="Optional" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="py-0 my-0">
                <div v-if="!$v.security.permanent_address.required && $v.security.permanent_address.$dirty"
                  class="validation-text red--text text-body-2 error-span">Permanent address is required.</div>
                <v-textarea v-model="$v.security.permanent_address.$model" class="rounded" label="Permanent Address*"
                  auto-grow outlined dense></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" class="py-0 my-0">
                <span v-if="!$v.security.photo.required && $v.security.photo.$dirty" class="red--text text-body-2">Photo
                  is required.</span>
                <span v-if="limitExceed" class="red--text">File size limit exceed. File size must not greater than 5
                  MB</span>
                <span v-if="typeCheck" class="red--text">File must be image</span>
                <UploadImages :max="1" maxError @changed="handleSecurityPhoto" uploadMsg="Upload Photo Here" fileError
                  clearAll style="height: inherit !important" class="rounded" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0 my-0">
                <span v-if="!$v.security.adhar_card.required && $v.security.adhar_card.$dirty"
                  class="red--text text-body-2">Aadhaar card is required.</span>
                <span v-if="limitExceed1" class="red--text">File size limit exceed. File size must not greater than 5
                  MB.</span>
                <span v-if="typeCheck1" class="red--text">File must be image.</span>
                <UploadImages :max="1" maxError @changed="handleSecurityAdhar" uploadMsg="Upload Aadhaar Card Here"
                  fileError clearAll style="height: inherit !important" class="rounded" />
              </v-col>
              <v-col cols="12" class="pb-8 mb-4">
                <v-card-actions class="d-flex justify-start px-0 mx-0">
                  <v-btn class="white--text text-uppercase btn-width" min-width="150px" color="#477fca" type="submit"
                    for="add-security" :disabled="loading">Create</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="addStatus" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>

          <span v-if="addSuccess" class="text-h4 font-weight-bold">Security details added successfully.</span>
          <span v-if="addError" class="text-h4 font-weight-bold">Security details addition unsuccessful.</span>
          <br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="cancelStatus">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>


  </v-container>
</template>
<script>
  import {
    required,
    maxValue
  } from 'vuelidate/lib/validators'
  import {
    helpers
  } from 'vuelidate/lib/validators'
  import DatePicker from '@/components/DatePicker.vue'
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import UploadImages from "vue-upload-drop-images"

  const phoneNumber = helpers.regex('phoneNumber', (
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))
  export default {
    metaInfo: {
      title: 'Add Security'
    },
    components: {
      DatePicker,
      UploadImages
    },
    data: () => ({
      setting_btn: false,
      limitExceed: false,
      typeCheck: false,
      limitExceed1: false,
      typeCheck1: false,
      security: {
        full_name: '',
        father_name: '',
        gender: '',
        date_of_joining: '',
        permanent_address: '',
        date_of_birth: '',
        mobile_number: '',
        reference: '',
        photo: undefined,
        adhar_card: undefined,
      },
      genderArray: [{
          text: 'Male',
          value: 'male'
        },
        {
          text: 'Female',
          value: 'female'
        }
      ],
      photoUrl: '',
      adharUrl: '',
      errorPhoto: '',
      loading: false,
      addStatus: false,
      addSuccess: false,
      addError: false,
      selectedListItem: 0,
      settingsListItem: 0,
      bottomListItem: 0,
      check: false,
      profileList: [{
          text: 'Profile',
          icon: 'mdi-account',
          disabled: false,
          path: 'profile'
        },
        {
          text: 'My Discussions',
          icon: 'mdi-post',
          disabled: false,
          path: 'mydiscussion'
        },
        {
          text: 'My Complaints',
          icon: 'mdi-notebook-edit-outline',
          disabled: false,
          path: 'mycomplains'
        },
        {
          text: 'Fees',
          icon: 'mdi-currency-inr',
          disabled: false,
          path: 'fees'
        },
        // {
        //   text: 'Change Password',
        //   icon: 'mdi-lock',
        //   disabled: false,
        //   path: 'password'
        // },
      ],
      settingsList: [{
        text: 'Change Password',
        icon: 'mdi-lock',
        disabled: false,
        path: 'password'
      }, ],
      bottomList: [{
        text: 'Home',
        icon: 'mdi-home',
        disabled: false,
        path: ''
      }, ],
      showAlert: true,
      overlays: false
    }),
    validations() {
      let currentDate = new Date()
      return {
        security: {
          full_name: {
            required
          },
          father_name: {
            required
          },
          gender: {
            required
          },
          date_of_joining: {
            required
          },
          date_of_birth: {
            required
          },
          permanent_address: {
            required
          },
          mobile_number: {
            required,
            phoneNumber
          },
          reference: {

          },
          photo: {
            required
          },
          adhar_card: {
            required
          }
        },
        dateOfJoining: {
          required,
          // minValue: minValue(currentDate.setDate(currentDate.getDate() - 1))
        },
        dateOfBirth: {
          required,
          maxValue: maxValue(currentDate.setDate(currentDate.getDate()))
        },
      }
    },
    computed: {
      dateOfJoining() {
        return this.security.date_of_joining ? new Date(this.security.date_of_joining) : null
      },
      dateOfBirth() {
        return this.security.date_of_birth ? new Date(this.security.date_of_birth) : null
      },
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        isDefaultPassword: 'isDefaultPassword'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      ...mapGetters('society', {
        society: 'getSociety'
      })
    },
    methods: {
      ...mapActions('society', [
        'fetchSociety'
      ]),
      ...mapActions('security', [
        'postSecurityData'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      onAddPhoto(file) {
        const reader = new FileReader()
        reader.addEventListener('load', e => this.photoUrl = e.target.result)
        reader.addEventListener('error', () => this.photoUrl = this.errorPhoto)
        reader.readAsDataURL(file)
      },
      onAddAdhar(file) {
        const reader = new FileReader()
        reader.addEventListener('load', e => this.adharUrl = e.target.result)
        reader.addEventListener('error', () => this.adharUrl = this.errorPhoto)
        reader.readAsDataURL(file)
      },
      selectImage(elementId) {
        document.getElementById(elementId).click()
      },
      cancelStatus() {
        this.$router.push('/account/security-setting')
        this.security = {
          full_name: '',
          father_name: '',
          gender: '',
          date_of_joining: '',
          permanent_address: '',
          date_of_birth: '',
          mobile_number: '',
          reference: '',
          photo: undefined,
          adhar_card: undefined,
        }
        this.$v.$reset()
        this.addStatus = false
      },
      addSecurity() {
        this.loading = true
        this.$v.$touch()
        if (!this.$v.$invalid) {
          let formData = new FormData()
          for (let key in this.security) {
            formData.append(key, this.security[key])
          }
          this.postSecurityData(formData).then(() => {
            this.loading = false
            this.addSuccess = true
            this.addError = false
            this.addStatus = true
          }).catch(() => {
            this.loading = false
            this.addSuccess = false
            this.addError = true
            this.addStatus = true
          })
        } else {
          this.loading = false
        }
      },
      handleSecurityPhoto(file) {
        
        const fileType = ["image/gif", "image/jpg", "image/jpeg", "image/png", "image/bmp"]
        console.log(file)
        if (file) {
          let file_size = file[0].size / (1024 * 1024);
          console.log(file_size)
          if (file_size > 5) {
            this.loading = true
            this.limitExceed = true
            this.typeCheck = false
          } else if (!fileType.includes(file[0].type)) {
            this.loading = true
            this.typeCheck = true
            this.limitExceed = false
          } else {
            this.loading = false
            this.typeCheck = false
            this.limitExceed = false
          }
        }

        this.$v.security.photo.$model = file[0]
      },
      handleSecurityAdhar(file) {
        const fileType = ["image/gif", "image/jpg", "image/jpeg", "image/png", "image/bmp"]
        console.log(file)
        if (file) {
          let file_size = file[0].size / (1024 * 1024);
          console.log(file_size)
          if (file_size > 5) {
            this.loading = true
            this.limitExceed1 = true
            this.typeCheck1 = false
          } else if (!fileType.includes(file[0].type)) {
            this.loading = true
            this.typeCheck1 = true
            this.limitExceed1 = false
          } else {
            this.loading = false
            this.typeCheck1 = false
            this.limitExceed1 = false
          }
        }

        this.$v.security.adhar_card.$model = file[0]
      }
    },
    created() {
      this.overlays = true
      this.fetchSociety()
      this.fetchSettings().then(() => {
        if (this.isAdmin) {
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.settingsList.unshift({
            'text': 'Settings',
            'icon': 'mdi-cog-outline',
            'disabled': this.isDefaultPassword,
            'path': 'settings'
          })
          this.profileList.unshift({
            'text': 'Fees',
            'icon': 'mdi-currency-inr',
            'disabled': this.isDefaultPassword,
            'path': 'fees-settings'
          })
          this.profileList.unshift({
            'text': 'Securities',
            'icon': 'mdi-security',
            'disabled': this.isDefaultPassword,
            'path': 'security-setting'
          })
          if (this.settings.enable_events) {
            this.profileList.unshift({
              'text': 'Events',
              'icon': 'mdi-calendar-today',
              'disabled': this.isDefaultPassword || !this.settings.enable_events,
              'path': 'events'
            })
          }
          this.profileList.unshift({
            'text': 'Complaints',
            'icon': 'mdi-notebook-edit-outline',
            'disabled': this.isDefaultPassword,
            'path': 'complains'
          })
          if (this.settings.enable_posts) {
            this.profileList.unshift({
              'text': 'Discussions',
              'icon': 'mdi-post',
              'disabled': this.isDefaultPassword || !this.settings.enable_posts,
              'path': 'discussion'
            })
          }

          if (this.settings.enable_news) {
            this.profileList.unshift({
              'text': 'Notice Board',
              'icon': 'mdi-information-outline',
              'disabled': this.isDefaultPassword || !this.settings.enable_news,
              'path': 'notice'
            })
          }
          this.profileList.unshift({
            'text': 'Residents',
            'icon': 'mdi-account-check',
            'disabled': this.isDefaultPassword,
            'path': 'residents'
          })
        } else {
          if (!this.settings.enable_posts) {
            this.profileList = this.profileList.filter((value) => {
              return value.path !== 'myposts'
            })
          }
        }
        this.overlays = false
        this.check = true
      })
    }
  }
</script>
<style scoped>
  .validation-text {
    position: relative;
    bottom: 15px;
  }

  .select-image-card {
    background: #F7F7F7;
    border: 1px dashed #423D3D;
  }

  .select-image {
    height: inherit;
  }

  .error-span {
    margin-bottom: -14px !important;
  }

  .left-container-card {
    position: fixed;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .title-size {
    font-size: 13px;
    text-transform: uppercase;
  }
</style>