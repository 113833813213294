<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <span
          class="
            text-h5
            font-weight-bold
            text-uppercase
            d-flex
            justify-start
            px-0
          "
          style="color: #62608b"
          >Payment Settings
        </span>
      </v-col>
      <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
        <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
          color="primary" plain @click="$router.back()" :ripple="false">
          Back
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field outlined dense label="Registration Fees" :value="fees.registration_fees" @input="updateSocietyFeesData('registration_fees', $event)"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense label="Member Fees" :value="fees.member_fees" @input="updateSocietyFeesData('member_fees', $event)"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <AccountDetailsSetUp :settings="fees"></AccountDetailsSetUp>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn color="#5da55b" class="text-uppercase white--text" @click="createSocietyFees">Save</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="table text-center">
          <template>
            <thead>
              <tr>
                <th class="text-center">Society Name</th>
                <th class="text-center">Society ID</th>
                <th class="text-center">Joining Date</th>
                <th class="text-center">Contact Number</th>
                <th class="text-center">Payment Period</th>
                <th class="text-center">Amount</th>
                <th class="text-center">Total Users</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(society, index) in monthlyFees.data" :key="index">
                <td>{{society.society.society_name}}</td>
                <td>{{society.society.registration_number}}</td>
                <td>{{getDate(society.society.date_of_join)}}</td>
                <td>{{society.society.mobile_number}}</td>
                <td>{{getMonthString(society.payment_month)}}{{society.payment_year}}</td>
                <td>{{society.amount}}</td>
                <td>{{society.users}}</td>
                <td>
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="5" class="d-flex justify-center align-center">
                      <v-select 
                        class="pa-0 ma-0 rounded"
                        :items="paymentStatusItems" item-text="text"
                        item-value="value" outlined dense
                        :value="society.status"
                        :background-color="society.status=='paid'?'green':society.status=='unpaid'?'project_red':'warning'"
                        @change="updateStatus(society.id, $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="2" v-if="society.society.disable">
                      <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                        color="primary" plain :ripple="false" @click="updateSocietyDisableStatus(society.society.id, false)">
                        Enabled
                      </v-btn>
                    </v-col>
                    <v-col cols="2" v-else>
                      <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                        color="primary" plain :ripple="false" @click="updateSocietyDisableStatus(society.society.id, true)">
                        Disabled
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters v-if="monthlyFees.data.length">
      <v-pagination
        v-model="page"
        :length="pageNumber"
        :total-visible="7"
        color="primary"
        @input="fetchMonthlyFeesWithPage($event)"
        @previous="fetchMonthlyFeesWithUrl(monthlyFees.previous)"
        @next="fetchMonthlyFeesWithUrl(monthlyFees.next)"
      ></v-pagination>
    </v-row>
    <v-overlay v-model="overlays">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
      <v-dialog v-model="showDialog" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>

          <span class="text-h4 font-weight-bold">Settings saved successfully.</span><br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="updateCompelete">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container> 
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AccountDetailsSetUp from '@/components/AccountDetailsSetUp.vue'
export default {
  components: {
    AccountDetailsSetUp
  },
  data: () => ({
    page: 1,
    paymentStatusItems: [
      {
        text: 'Paid',
        value: "paid"
      },
      {
        text: 'Unpaid',
        value: "unpaid"
      },
      {
        text: 'Processing',
        value: "processing"
      }
    ],
    overlays: false,
    showDialog:false,
  }),
  computed: {
    ...mapGetters('settings', {
      fees: 'getSocietyFees'
    }),
    ...mapGetters('fees', {
      monthlyFees: 'getMonthlyFees'
    }),
    pageNumber() {
      return Math.ceil(this.monthlyFees.count/10)
    }
  },
  methods: {
    ...mapActions('settings', [
      'fetchSocietyFees',
      'createSocietyFees'
    ]),
    ...mapMutations('settings', [
      'setSocietyFeesProperty'
    ]),
    ...mapActions('fees', [
      'fetchMonthlyFees',
      'fetchMonthlyFeesWithPage',
      'fetchMonthlyFeesWithUrl',
      'updateSocietyMonthlyFeesStatus'
    ]),
    ...mapActions('society', [
      'updateSocietyDisable'
    ]),
    updateSocietyFeesData(property, value) {
      this.setSocietyFeesProperty({
        property,
        value
      })
    },
    getDate(date) {
      return `${String(new Date(date).getDate()).padStart(2,'0')}/${String(new Date(date).getMonth()+1).padStart(2,'0')}/${new Date(date).getFullYear()}`
    },
    getMonthString(month) {
      const today = new Date(2020, month - 1, 1)
      //return today.toLocaleString('default', { month: 'long' })
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
      ];
      return ` ${ monthNames[today.getMonth()]} `;
    },
    updateStatus(id, status) {
      this.overlays = true
      this.updateSocietyMonthlyFeesStatus({'fees_id': id, 'status': status}).finally(() => {
        this.overlays = false
      })
    },
    updateSocietyDisableStatus(society_id, disable) {
      this.overlays = true
      this.updateSocietyDisable({'society_id': society_id, 'disable': disable}).then(() => {
        this.fetchMonthlyFees()
      }).finally(() => {
        this.overlays = false
      })
    },
    updateCompelete() {
        this.showDialog = false
        location.reload()
    },
  },
  mounted() {
    this.overlays = true
    this.fetchSocietyFees().then(() => {
      console.log('****', this.fees)
    })
    this.fetchMonthlyFees().finally(() => {
      this.overlays = false
    })
  }
}
</script>
<style scoped>
.v-select>>>.v-text-field__details {
  display: none !important;
}
.v-select>>>.v-input__slot {
  margin: 0;
}
</style>
