export default {
  data: () => ({
    script: `https://checkout.razorpay.com/v1/checkout.js`
  }),
  methods: {
    async loadRazorPay() {
      return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = this.script
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
        document.body.appendChild(script)
      })
    }
  }
}