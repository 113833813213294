<template>
    <v-container class="">
        <v-row class="py-5" justify="center" align="center"
            style="margin-bottom:0 !important;padding-bottom:0px !important">

            <v-col xs="12" sm="12" md="3" lg="3" xl="3" class="d-flex justify-lg-start " style="padding-top:15px;">
                <p class="font-weight-bold text-caption pt-2" style="font-size:18px !important;">
                    Discussions({{ posts.count }})
                </p>
            </v-col>

            <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="searchDiv " style="padding-top:15px">
                <v-text-field rounded color="#FFA500" v-model="searchQuery" dense label="Search" outlined
                    append-icon="mdi-magnify" single-line hide-details></v-text-field>
            </v-col>


            <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
                <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.back()" :ripple="false">
                    Back
                </v-btn>
            </v-col>

        </v-row>

        <v-row align="center" v-for="(info, index) in filteredPosts" :key="index">
            <v-col class="pt-0 mt-0" cols="12">
                <div class="">
                    <v-card-title v-text="info.title"
                        class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                        style="font-size:18px !important;">
                    </v-card-title>
                    <div>
                        <v-card-text class="text-caption text-capitalize pl-0 ml-0"
                            style="font-size:15px !important;">
                            <span>{{info.description.slice(0, 100)}}...</span>
                            <a @click="postSelected(info)">{{linkText}}</a>
                        </v-card-text>
                    </div>
                </div>
            </v-col>


            <v-col cols="6" class="d-flex justify-start align-center">
                <span class="text-capitalize font-italic font-weight-bold" style="font-size:15px !important;color:#707070">
                    Comments({{info.post_comment.length}}),
                </span>
                <span class="mx-2 font-weight-bold" style="color:#707070">
                    <v-btn icon class="ma-0 pa-0" @click="likePost(info)">
                        <v-icon color="#1a77d2" class="">mdi-thumb-up</v-icon> 
                    </v-btn>
                    {{get_like_count(info.post_response)}}
                </span>
                <span class="font-weight-bold" style="color:#707070">
                    <v-btn icon class="ma-0 pa-0" @click="dislikePost(info)">
                        <v-icon color="#1a77d2" class="">mdi-thumb-down</v-icon>
                    </v-btn>
                    {{get_dislike_count(info.post_response)}}
                </span>
            </v-col>

            <v-col cols="6" class="d-flex justify-end ">
                <span class="text-capitalize font-italic" style="font-size:15px !important;">
                    Started by: {{info.full_name}} on {{getDate(info.added)}}
                </span>
            </v-col>


            <v-col >
                <v-divider class="pt-2"></v-divider>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="posts.data.length>0">
            <v-pagination
                v-model="page"
                :length="Math.ceil(posts.count/10)"
                :total-visible="7"
                color="primary"
                @next="fetchPostsWithUrl(posts.next)"
                @previous="fetchPostsWithUrl(posts.previous)"
                @input="fetchPostsWithPage($event)"
            ></v-pagination>
        </v-row>
        <!-- <CommunityInfo
            :CommunityData="CommunityData"
            :posts="posts" 
            @liked="fetchPosts"
        ></CommunityInfo> -->
    </v-container>
</template>
<script>
    import {
        mapActions,
        mapGetters,
        mapMutations
    } from 'vuex'
    // import CommunityInfo from '@/components/CommunityInfo.vue'
    export default {
        metaInfo: {
            title: 'Discussions'
        },
        // components: {
        //     CommunityInfo
        // },
        data: () => ({
            showMore: false,
            linkText: 'View Full Discussion',
            disabledButtons: false,
            deleteDialog: false,
            deletedPostId: new Number(),
            overlay: false,
            searchQuery: '',
            page: 1,
            postPerPage: 10,
            postItemsPerPage: [{
                    text: '5',
                    value: 5
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '15',
                    value: 15
                },
                {
                    text: '20',
                    value: 20
                },
                {
                    text: '100',
                    value: 100
                },
                {
                    text: 'All',
                    value: null
                },
            ]
        }),
        computed: {
            ...mapGetters('user', {
                posts: 'getPosts'
            }),
            ...mapGetters('posts', {
                comments: 'getComments'
            }),
            filteredPosts() {
                if (this.searchQuery) {
                    return this.posts.data.filter((post) => {
                        return this.searchQuery.toLowerCase().split(' ').every((search) => {
                            return post.title.toLowerCase().includes(search) || post.description
                                .toLowerCase().includes(
                                    search) || post.full_name.toLowerCase().includes(search)
                        })
                    })
                } else {
                    return this.posts.data
                }
            },
            currentPagePosts() {
                return this.postPerPage != null ? this.filteredPosts.slice((this.page - 1) * this.postPerPage, (this
                        .page - 1) *
                    this.postPerPage + this.postPerPage) : this.filteredPosts
            }
        },
        methods: {
            ...mapActions('user', [
                'fetchPosts',
                'updatePostStatus',
                'deleteStory',
                'fetchPostsWithPage',
                'fetchPostsWithUrl'
            ]),
            ...mapMutations('user', [
                'setPostStatusProperty',
                'setPost'
            ]),
            ...mapActions('posts', [
                'fetchComments',
                'likeDislike'
            ]),
            ...mapMutations('posts', [
                'setLikeProperty'
            ]),
            moreLess() {
                this.showMore = !this.showMore
                if (this.showMore) {
                    this.linkText = 'less'
                } else {
                    this.linkText = 'View Full Discussion'
                }
            },
            updatePostStatusProperty(status, id) {
                this.disabledButtons = true
                this.setPostStatusProperty({
                    status,
                    id
                })
                this.updatePostStatus().then(() => {
                    this.fetchPosts()
                    this.disabledButtons = false
                })
            },
            deletePost(post) {
                this.deletedPostId = post.id
                this.deleteDialog = true
                this.disabledButtons = true
            },
            cancelDelete() {
                this.deletedPostId = new Number()
                this.deleteDialog = false
            },
            confirmDelete() {
                this.deleteStory(this.deletedPostId).then(() => {
                    this.fetchPosts()
                    this.deleteDialog = false
                    this.disabledButtons = false
                })
            },
            postSelected(post) {
                this.setPost(post)
                this.$router.push(`/discussion/${post.title.replace(/\s/g, '-')}-${post.id}/`)
            },
            get_like_count(arr) {
                let likeCount = arr.filter((value) => {
                    if (value.like) {
                        return value
                    }
                })
                return likeCount.length
            },
            get_dislike_count(arr) {
                let dislikeCount = arr.filter((value) => {
                    if (value.dislike) {
                        return value
                    }
                })
                return dislikeCount.length
            },
            getDate(date) {
                return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
            },
            likePost(discussion) {
                this.setLikeProperty({
                    property: 'post',
                    value: discussion.id
                })
                this.setLikeProperty({
                    property: 'like',
                    value: true
                })
                this.setLikeProperty({
                    property: 'dislike',
                    value: false
                })
                this.likeDislike().then(() => {
                    this.fetchPosts()
                })
            },
            dislikePost(discussion) {
                this.setLikeProperty({
                    property: 'post',
                    value: discussion.id
                })
                this.setLikeProperty({
                    property: 'like',
                    value: false
                })
                this.setLikeProperty({
                    property: 'dislike',
                    value: true
                })
                this.likeDislike().then(() => {
                    this.fetchPosts()
                })
            },
        },
        mounted() {
            this.overlay = true
            this.fetchPosts().then(() => {
                this.overlay = false
            })
        }
    }
</script>
<style scoped>
    .text-card {
        overflow-y: scroll;
        padding: 0;
    }

    .row+.row {
        margin-top: 0 !important;
    }

    .btn-underline:hover {
        text-decoration: none !important;
    }
</style>