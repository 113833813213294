var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1)],1)],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 btn-underline text-decoration-underline",attrs:{"plain":"","color":"black darken-2","ripple":false},on:{"click":_vm.setToday}},[_vm._v(" Go to Today ")])],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('span',{staticClass:"text-center d-flex justify-center text-h6"},[_vm._v(_vm._s(event.name))])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"450px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[(!_vm.editSecurity)?_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)},on:{"click":function($event){return _vm.fetchData(_vm.selectedEvent)}}}):_vm._e(),(_vm.editSecurity)?_c('v-select',{staticClass:"rounded pt-6 ma-0",attrs:{"label":"Select","items":_vm.securities.data,"item-text":"full_name","item-value":"id","outlined":"","dense":""},model:{value:(_vm.timings.security),callback:function ($$v) {_vm.$set(_vm.timings, "security", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"timings.security"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[(!_vm.editStartTime)?_c('span',{on:{"click":_vm.startTime}},[_vm._v(_vm._s(_vm.formatAMPM(new Date(_vm.selectedEvent.start))))]):_vm._e(),(_vm.editStartTime)?_c('v-menu',{ref:"startMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.$v.timings.start_time.$model,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$v.timings.start_time, "$model", $event)},"update:return-value":function($event){return _vm.$set(_vm.$v.timings.start_time, "$model", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded pt-6 edit-time-input",attrs:{"label":"Start Time","readonly":"","outlined":"","dense":""},model:{value:(_vm.$v.timings.start_time.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.start_time, "$model", $$v)},expression:"$v.timings.start_time.$model"}},'v-text-field',attrs,false),on))]}}],null,false,2927135072),model:{value:(_vm.$v.timings.startMenu.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.startMenu, "$model", $$v)},expression:"$v.timings.startMenu.$model"}},[(_vm.$v.timings.startMenu.$model)?_c('v-time-picker',{attrs:{"full-width":"","header-color":"project_primary"},on:{"click:minute":function($event){return _vm.$refs.startMenu.save(_vm.$v.timings.start_time.$model)}},model:{value:(_vm.$v.timings.start_time.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.start_time, "$model", $$v)},expression:"$v.timings.start_time.$model"}}):_vm._e()],1):_vm._e(),_c('v-toolbar-title',[_vm._v("-")]),(!_vm.editEndTime)?_c('span',{on:{"click":_vm.endTime}},[_vm._v(_vm._s(_vm.formatAMPM(new Date(_vm.selectedEvent.end))))]):_vm._e(),(_vm.editEndTime)?_c('v-menu',{ref:"endMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.$v.timings.end_time.$model,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$v.timings.end_time, "$model", $event)},"update:return-value":function($event){return _vm.$set(_vm.$v.timings.end_time, "$model", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded pt-6 edit-time-input",attrs:{"label":"End Time","readonly":"","outlined":"","dense":""},model:{value:(_vm.$v.timings.end_time.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.end_time, "$model", $$v)},expression:"$v.timings.end_time.$model"}},'v-text-field',attrs,false),on))]}}],null,false,3294523104),model:{value:(_vm.$v.timings.endMenu.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.endMenu, "$model", $$v)},expression:"$v.timings.endMenu.$model"}},[(_vm.$v.timings.endMenu.$model)?_c('v-time-picker',{attrs:{"header-color":"project_primary","full-width":""},on:{"click:minute":function($event){return _vm.$refs.endMenu.save(_vm.$v.timings.end_time.$model)}},model:{value:(_vm.$v.timings.end_time.$model),callback:function ($$v) {_vm.$set(_vm.$v.timings.end_time, "$model", $$v)},expression:"$v.timings.end_time.$model"}}):_vm._e()],1):_vm._e()],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteTime(_vm.selectedEvent)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.editSecurityTime}},[_vm._v(" Edit ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.cancelEdit}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }