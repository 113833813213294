<template>
  <v-container class="ml-0 pl-0">
    <v-card elevation="0" class="pl-0">
      <v-card-title class="font-weight-bold text-caption pl-0" style="font-size:18px !important;">Fees
        ({{payments.length}} Pending)</v-card-title>
      <v-row no-gutters justify="end" class="">
        <v-btn :loading="loading" class="ma-0 pa-0 text-decoration-underline text-capitalize text-subtitle-1 text-btn"
          color="primary" plain @click="getAllUnpaidDetails">
          View Unpaid
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3" class="pr-2">
          <v-autocomplete :disabled="overlays" :items="Object.values(residents)" :filter="customFilter"
            v-model="selectedResident" item-text="full_name" item-value="id" label="Residents" outlined filled
            class="rounded" dense cache-items rounded background-color="white"></v-autocomplete>
        </v-col>
        <v-col cols="3" class="pr-2">
          <DatePicker :rounded="'rounded'" :label="'From Date *'" :date_given="firstDateOfCurrentMonth"
            @get-date="selectedFromDate($event)"></DatePicker>
        </v-col>
        <v-col cols="3" class="pr-2">
          <DatePicker :rounded="'rounded'" :label="'To Date *'" @get-date="selectedToDate($event)"></DatePicker>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
          <v-btn @click="checkPaymentStatus" color="action_btn_color" class="white--text rounded mr-2 btn-width">Go
          </v-btn>
          <v-btn @click="resetPage" color="primary" class="white--text rounded btn-width">Reset</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-divider v-if="payments.length>0" class="mb-3"></v-divider>
    <v-card elevation="0" color="transparent" v-if="payments.length>0">
      <v-row justify="space-around">
        <v-col cols="2" class="d-flex justify-center">
          <span class="header-text text-capitalize font-weight-bold">Resident Name</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <span class="header-text text-capitalize font-weight-bold">Amount</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <span class="header-text text-capitalize font-weight-bold">Period</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <span class="header-text text-capitalize font-weight-bold">Payment Status</span>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <span class="header-text text-capitalize font-weight-bold">Invoice</span>
        </v-col>
      </v-row>
    </v-card>
    <v-card color="#F5F5F5" elevation="0" class="my-6" v-for="(user, index) in payments" :key="index">
      <v-row justify="space-around" align="center">
        <v-col cols="2" class="d-flex justify-center">

          <span v-if="user.full_name.length>10"
            class="header-text text-capitalize">{{user.full_name.slice(0,10)+'...'}}</span>
          <span v-else class="header-text text-capitalize">{{user.full_name}}</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <v-expansion-panels class="rounded pa-0 ma-0" popout flat focusable>
            <v-expansion-panel>
              <v-expansion-panel-header color="#F5F5F5" class="d-flex align-center py-0">{{user.amount}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in user.amount_break_up" :key="index">{{index}} {{item}}</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <span class="header-text text-capitalize">{{getMonthString(user.payment_month)}} {{user.payment_year}}</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-center">
          <v-select class="pa-0 ma-0 rounded" :class="user.status=='paid'?'green--text':user.status=='unpaid'?'red--text':'warning--text'"
            :background-color="user.status=='paid'?'green':user.status=='unpaid'?'project_red':'warning'" :items="paymentStatusItems" item-text="text"
            item-value="value" :value="user.status" outlined dense
            @change="savePaymentDetails('status', $event, index, user)"></v-select>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <span class="">
            <v-icon v-if="user.status=='paid'" style="color:#bf3422 !important;font-size:30px" @click="getInvoice(user.id)">mdi-file-pdf</v-icon>
            <span v-else>---</span>
          </span>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="confirmChangeDialog" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="error" style="font-size:80px">
            mdi-alert
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">You want to change payment status?</span>
          <br>
          <v-btn class="white--text text-uppercase mx-2" width="30%" color="#423D3D" @click="deletePaymentDetails">Yes
          </v-btn>
          <v-btn class="white--text text-uppercase mx-2" width="30%" color="#423D3D" @click="confirmChangeDialog=false">
            No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showStatusOfPayment" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon v-if="feesPaid" color="error" style="font-size:80px">
            mdi-alert
          </v-icon>
          <v-icon v-if="successPayment" color="error" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>

          <span dense class="text-h4 font-weight-bold" v-if="feesPaid" text type="error">Resident has paid the fees
            already.</span><br>
          <span dense class="text-h4 font-weight-bold" v-if="successPayment" text type="success">Payment of fees is
            succesfull.</span><br>
          <v-btn class="white--text text-uppercase mx-2 btn-width" color="#423D3D" @click="closePopup">Cancel</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="feesItemEmpty" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="error" style="font-size:80px">
            mdi-alert
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Fees items or Residensts are empty. Please fill the details
            first.</span>
          <br>
          <v-btn class="white--text text-uppercase mx-2" width="30%" color="#423D3D" @click="feesItemEmpty = false">
            Cancel</v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay v-model="overlays">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import DatePicker from '@/components/DatePicker.vue'
  export default {
    metaInfo: {
      title: 'Fees Settings'
    },
    components: {
      DatePicker
    },
    data: () => ({
      overlays: false,
      selectedResident: null,
      selectedMonth: '',
      selectedYear: '',
      selectedIndex: null,
      selectedUser: null,
      fromMonth: '',
      fromYear: '',
      toMonth: '',
      toYear: '',
      paymentEnable: false,
      showStatusOfPayment: false,
      feesPaid: false,
      successPayment: false,
      paymentStatus: false,
      paymentStatusItems: [{
          text: 'Paid',
          value: "paid"
        },
        {
          text: 'Unpaid',
          value: "unpaid"
        },
        {
          text: 'Processing',
          value: "processing"
        }
      ],
      confirmChangeDialog: false,
      loading: false,
      feesItemEmpty: false,
    }),
    computed: {
      ...mapGetters('fees', {
        fees: 'getFees',
        payments: 'getPaymentDetails'
      }),
      ...mapGetters('user', {
        residents: 'getApprovedResidents'
      }),
      totalFees() {
        let total = 0
        for (let value in this.fees) {
          total = total + Number(this.fees[value])
        }
        return total
      },
      monthArray() {
        let arr = []
        for (let i = 0; i < 12; i++) {
          const today = new Date(2020, i, 1)
          arr.push({
            text: today.toLocaleString('default', {
              month: 'long'
            }),
            value: today.getMonth() + 1
          })
        }
        return arr
      },
      yearArray() {
        let arr = []
        const today = new Date()
        arr.push({
          text: today.getFullYear(),
          value: today.getFullYear()
        })
        for (let i = 0; i < 4; i++) {
          arr.push({
            text: today.getFullYear() - i,
            value: today.getFullYear() - i
          })
        }
        return arr
      },
      paymentData() {
        let payment = {
          user: this.selectedResident,
          amount: this.totalFees,
          amount_break_up: this.fees,
          payment_month: this.selectedMonth,
          payment_year: this.selectedYear,
          from_month: this.fromMonth,
          from_year: this.fromYear,
          to_month: this.toMonth,
          to_year: this.toYear
        }
        return payment
      },
      firstDateOfCurrentMonth() {
        let currtentDate = new Date()
        if((currtentDate.getMonth() - 4) >= 0) {
          return String(new Date().getFullYear()) + '-' + String((currtentDate.getMonth() - 3) + 1).padStart(2, '0') + '-01'
        } else {
          return String(new Date().getFullYear() - 1) + '-' + String((currtentDate.getMonth() - 3) + 12).padStart(2, '0') + '-01'
        }
      }
    },
    methods: {
      ...mapActions('fees', [
        'fetchFees',
        'updatePayment',
        'getPaymentStatus',
        // 'deleteUserPayment',
        'fetchLimitedUnpaidPayment',
        'fetchUnpaidPayment'
      ]),
      ...mapActions('user', [
        'fetchApprovedResidents'
      ]),
      ...mapMutations('fees', [
        'removePaymentDetails',
        'setPaymentDetailsProperty'
      ]),
      updatePaymentDetailsProperty(property, value, index) {
        this.setPaymentDetailsProperty({
          property,
          value,
          index
        })
        // this.$v.changePassword[property].$touch()
      },
      getMonthString(month) {
        const today = new Date(2020, month - 1, 1)
        //return today.toLocaleString('default', { month: 'long' })
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        return ` ${ monthNames[today.getMonth()]} `;
      },
      savePaymentDetails(property, value, index, user) {
        this.overlays = true
        this.updatePaymentDetailsProperty(property, value, index)
        this.selectedIndex = index
        this.selectedUser = user
        this.confirmChangeDialog = !value

        this.updatePayment(user).then(() => {
          this.showStatusOfPayment = true
          this.successPayment = true
        }).finally(() => {
          this.overlays = false
        })
      },
      deletePaymentDetails() {
        // this.overlays = true
        // this.deleteUserPayment(this.selectedUser).then(() => {
        //   this.showStatusOfPayment = true
        //   this.successPayment = true
        //   this.confirmChangeDialog = false
        // }).finally(() => {
        //   this.overlays = false
        // })
      },
      getAllUnpaidDetails() {
        this.loading = true
        this.fetchUnpaidPayment().finally(() => {
          this.loading = false
        })
      },
      checkPaymentStatus() {
        if (this.selectedResident) {
          this.overlays = true
          this.getPaymentStatus(this.paymentData).finally(() => {
            this.overlays = false
          })
        }
      },
      closePopup() {
        this.showStatusOfPayment = false
        this.feesPaid = false
        this.successPayment = false
      },
      customFilter(item, queryText) {
        const textOne = item.full_name.toLowerCase()
        // const textTwo = item.abbr.toLowerCase()
        const searchText = queryText.toLowerCase()

        // return textOne.indexOf(searchText) > -1 ||
        //   textTwo.indexOf(searchText) > -1
        return textOne.indexOf(searchText) > -1
      },
      fetchData() {
        this.overlays = true
        this.fetchApprovedResidents().then(() => {
          this.fetchFees().then(() => {
            this.fetchLimitedUnpaidPayment().then(() => {
              this.overlays = false
            }).catch(() => {
              this.feesItemEmpty = true
              this.overlays = false
            })
          })
        })
      },
      resetPage() {
        this.removePaymentDetails()
        Object.assign(this.$data, this.$options.data.apply(this))
      },
      selectedToDate(date) {
        this.toMonth = new Date(date).getMonth() + 1
        this.toYear = new Date(date).getFullYear()
      },
      selectedFromDate(date) {
        this.fromMonth = new Date(date).getMonth() + 1
        this.fromYear = new Date(date).getFullYear()
      },
      getInvoice(payment_id) {
        window.open(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/fees-api/v-1/payment/invoice/pdf/?payment_id=${payment_id}`)
      },
    },
    validations() {

    },
    mounted() {
      this.fetchData()
    },
    beforeDestroy() {
      this.resetPage()
    }
  }
</script>
<style scoped>
  .btn-size {
    height: 30px !important;
    width: 30px !important;
  }

  .v-select>>>.v-text-field__details {
    display: none !important;
  }

  .text-btn:hover {
    text-decoration: none !important;
  }

  .v-input>>>.v-select__selection--comma {
    color: white !important;
  }
</style>