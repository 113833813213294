<template>
    <v-container :class="navClass">
        <v-row justify="space-between" align="center">
            <v-col cols="5">
                <router-link to="/" exact style="text-decoration: none;">
                    <v-img
                        v-if="!loggedIn || isSuper"
                        class="logo"
                        :lazy-src="national_society_logo"
                        max-height="80"
                        max-width="180"
                        :src="national_society_logo"
                    ></v-img>
                    <span v-else class="text-h4 font-weight-bold" style="color: #62608b">
                        <v-img
                            v-if="settings.logo"
                            class="logo"
                            :lazy-src="settings.logo"
                            max-height="80"
                            max-width="30%"
                            :src="settings.logo"
                            contain
                            position="left center"
                        ></v-img>
                        <span v-else>{{user.society_name}}</span>
                    </span>
                </router-link>
                <!-- <div v-else>
                </div> -->
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="6" sm="5" md="4">
                <v-btn class="mr-2" text rounded color="success" dark v-if="!loggedIn" @click="$route.path=='/signin'? $router.go('/'): $router.push('/signin')"> Sign In </v-btn>
                <v-btn class="signUpBtn" rounded color="success" dark v-if="!loggedIn" @click="$route.path=='/registration'? $router.go('/'): $router.push('/registration')"> Sign Up </v-btn>
                <v-btn class="mr-2" :disabled="!this.payment_verify" rounded color="primary" dark v-if="loggedIn && !navClass" :href="`${accountUrl}`"> Account </v-btn>
                <div v-if="loggedIn && navClass || isSuper" class="d-flex flex-column text-end mx-3" style="color: #62608b;">
                    <span class="font-weight-bold text-capitalize">Welcome {{user.full_name}}!</span>
                    <span class="text-caption">You last logged in on {{new Date(user.second_last_login).toDateString()}} {{new Date(user.second_last_login).getHours()}}:{{new Date(user.second_last_login).getMinutes()}}</span>
                </div>
                <v-btn class="signUpBtn" rounded color="#ed6237" dark v-if="loggedIn" @click="signout"> Logout </v-btn>
            </v-col>
            <v-col cols="12" style="margin-top:-10px !important">
                <v-divider class="divider"></v-divider>
            </v-col>
        </v-row>
        <!-- <v-dialog v-model="paymentSuccess" width="500">
            <v-card height="150" class="d-flex flex-column align-center justify-center">
                <div class="text-h4 green--text font-weight-bold">Payment Successful.</div>
            </v-card>
        </v-dialog> -->
        <v-dialog v-model="paymentSuccess" width="500">
            <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
                <v-card-text class="d-flex justify-center align-center px-0 py-0"
                    style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
                    <v-icon color="#abd193" style="font-size:80px">
                        mdi-check
                    </v-icon>
                </v-card-text>
                <v-card-text>
                    <div class="text-h4 green--text font-weight-bold">Payment Successful.</div>
                    <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
                        @click="successPayment">
                        OK
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import logo from '@/assets/KNDC.png'
import national_society_logo from '@/assets/national-society-logo.png'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PaymentMixins from '@/mixins/payment.js'
export default {
    mixins: [
        PaymentMixins
    ],
    data: () => ({
        logo,
        national_society_logo,
        verticalListItems: [
            {
                title: 'Sign In',
                route: 'signin'
            },
            {
                title: 'Sign Up',
                route: 'signup'
            }
        ],
        verticalListItemsActive: [
            {
                title: 'account',
                route: 'account'
            },
            // {
            //     title: 'Sign Out',
            //     route: 'signout'
            // },
        ],
        selectedVirticalList: null,
        navigationDrawer: false,
        listItems: [
            {
                text: 'ABOUT',
                route: 'about'
            },
            {
                text: 'RESIDENTS',
                route: 'residents'
            },
            {
                text: 'COMPLAINTS & GRIEVANCES',
                route: 'complaints'
            },
            {
                text: 'EVENTS',
                route: 'events'
            },
            {
                text: 'CONTACT US',
                route: 'contacts'
            },
        ],
        paymentSuccess: false
    }),
    computed: {
        ...mapGetters('JWT',[
            'loggedIn',
            'isAdmin',
            'isSuper'
        ]),
        ...mapGetters('user',{
            user: 'getUser'
        }),
        ...mapGetters('settings', {
            settings: 'getSettings'
        }),
        ...mapGetters('society', {
            payment_verify: 'getPaymentVerify'
        }),
        accountUrl() {
            return this.isAdmin?'/account/residents':'/account/profile'
            // return this.isAdmin?{name: 'Residents'}:{name: 'Profile'}
        },
        navClass() {
            if(this.$route.matched[0].path=='/account' || this.$route.path=='/addevent' || this.$route.path=='/addinformation' || this.$route.path=='/add-security' || this.$route.name=='User' || this.$route.path=='/addiscussion' || this.$route.path=='/addcomplaint') {
                return 'sticky-navbar'
            } else {
                return ''
            }
        }
    },
    methods: {
        ...mapActions('JWT', [
            'logout'
        ]),
        ...mapActions('user', [
            'fetchUser'
        ]),
        ...mapActions('settings', [
            'fetchSettings',
        ]),
        ...mapActions('society', [
            'checkPaymentVerification'
        ]),
        ...mapActions('payment', [
            'paymentInitiate',
            'paymentHandle'
        ]),
        ...mapMutations('payment', [
            'setPayment'
        ]),
        signout() {
            this.logout()
            location.reload()
        },
        async makePaymentCall(amount, order_id, cust_name, cust_email, cust_contact) {
            const result = await this.loadRazorPay()
            if(!result){
                alert('Failed to load razorpay script')
            return
            }
            let optionsThis = this
            const options = {
                key: process.env.VUE_APP_RAZORPAY_ID,
                amount: amount,
                currency: `INR`,
                name: 'National Society',
                description: `Society Registration Fees`,
                order_id: order_id,
                // image: `Your business logo`,
                handler: function(response){
                    // It is function executed on success
                    optionsThis.paymentHandle({'response': response}).then(() => {
                        optionsThis.paymentSuccess = true
                        optionsThis.checkPaymentVerification()
                        window.open(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/payment-api/v-1/payment/invoice/pdf/?order_id=${order_id}`)
                    })
                },
                prefill: {
                    name: cust_name,
                    email: cust_email,
                    contact: cust_contact
                },
                modal: {
                    confirm_close: true,
                    animation: true,
                    handleback: true,
                    escape: true,
                    backdropclose: false
                }
            }; 
            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', (response) => {
                console.log('failed response', response)
            })
            paymentObject.open();
        },
        successPayment(){
            this.paymentSuccess = false
            this.checkPaymentVerification()
        }
    },
    mounted() {
        if(this.loggedIn) {
            this.fetchUser()
            if(!this.isSuper) {
                this.fetchSettings()
                this.checkPaymentVerification().then(() => {
                    if(!this.payment_verify && this.isAdmin) {
                        this.paymentInitiate({'society_id': this.user.society}).then((response) => {
                            this.setPayment({
                                amount: response.data.order.amount,
                                tax: response.data.order.tax,
                                order_id: response.data.payment.id,
                                cust_name: this.user.society_name,
                                cust_email: this.user.email,
                                cust_contact: this.user.mobile_number
                            })
                            this.$router.push('/payment-redirect')
                            // this.makePaymentCall(response.data.payment.amount, response.data.payment.id, this.user.society_name, this.user.email, this.user.mobile_number)
                        })
                    }
                })
            }
        } 
    }
}
</script>

<style scoped>
.navigation-list {
    position: absolute;
    height: none !important;
}

.logo-link {
    max-width: fit-content;
}

.logo-img {
    cursor: pointer;
}

.user-authentication-section {
    flex: 0 0 21% !important;
    min-width: 21% !important;
}
.user-authentication-section-active {
    flex: 0 0 4% !important;
    min-width: 4% !important;
}

.v-application .btn--active {
    /* border-bottom: 3px solid black !important; */
    position: relative;
}
.v-application .btn--active::after, .v-btn--plain:hover::after {
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: black;
}
.v-application .v-btn {
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.2)) !important;

}
.v-application .v-btn--outlined {
    box-sizing: border-box !important;
    border: 1px solid #434D3D !important;
}
.v-btn.rounded {
    border-radius: 3px !important;
}

@media (max-width: 1200px) {
    .navbar {
        width: 100% !important;
        left: 0px !important;
    }
    .navbar .v-image {
        height: 50px !important;
        width: 180px !important;
    }

    .small-off {
        display: none;
    }
}
@media (max-width: 840px) {
    .auth-btn-on {
        display: none;
    }
}
@media (min-width: 840px) {
    .auth-btn-off {
        display: none;
    }
}
@media (min-width: 1200px) {
    .navbar {
        left: 0px !important;
    }
    .md-off {
        display: none;
    }
}
.sticky-navbar {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}
</style>
