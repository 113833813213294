<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="text-left text-h4 font-weight-bold">
                    Privacy Policy
                </span>
                <p class="text-justify pt-4">
                    The National Society has established this privacy policy, which says, how we will respect the privacy of our
                    website users.It says what information we will gather, how we will use it and how we will keep it
                    secure, which may be updated by The National Society from time to time. The National Society will provide notice of
                    materially significant changes to this privacy policy by posting notice on the The National Society site.
                </p>
                <span class="text-left text-h4 font-weight-bold">
                    This Privacy Policy explains the following:
                </span>
                <p class="text-justify pt-4">
                    <ul class="list">
                        <li>What information does the National Society gather?</li>
                        <li>How does the National Society gather information?</li>
                        <li>How does the National Society use the gathered information?</li>
                        <li>How does the National Society protect your personal information?</li>
                        <li>Use of Cookies; email Pixels.</li>
                    </ul>
                </p>
                <span class="text-left text-h4 font-weight-bold">
                    What information does National Society gather?
                </span>
                <p class="text-justify pt-4">
                    The National Society collects personal information about you including, without limitation, personally
                    identifiable information (such as your name, address, phone number and email address). For some of
                    our Services we may also ask other information about you such as your age or other demographic
                    information.
                </p>
                <p class="text-justify">
                    The National Society collects information about your use of the Services including, without limitation, data
                    about your computer (such as operating system type, browser type, software installed on your
                    computer) and your web browsing information (such as referring URL's as well as your IP address).
                </p>

                <span class="text-left text-h4 font-weight-bold">
                    How does National Society use the gathered information?
                </span>
                <p class="text-justify pt-4">
                    The National Society uses your information to make your use of the Services more personalized and convenient.
                    The National Society uses your information to display or deliver materials, such as emails, surveys and
                    content. The National Society may use your information to target such materials so that they may be more
                    relevant to you than materials that would be provided without the use of your information. Pre-fill
                    online forms that you may submit to us or, for some of our Services, this information may be
                    pre-filled for third parties. The National Society may provide your information to other members of Alegra
                    Labs
                </p>
                <p class="text-justify">
                    <ul class="list">
                        <li>The National Society uses the information you have provided to update people who are willing to
                            contact you with respect to your classified ads, deals and events.</li>
                        <li>The National Society uses your information to conduct and provide market research and data analysis
                            services.</li>
                        <li>The National Society uses your information to provide, support and maintain the Services including,
                            for example, to monitor and diagnose the Services, to provide technical support and to
                            contact you with respect to the Services or other The National Society products or services.</li>
                    </ul>
                </p>
                <span class="text-left text-h4 font-weight-bold">
                    How does National Society protect your personal information?
                </span>

                <p class="text-justify pt-4">
                    The National Society is committed to ensuring that your information is secure. In order to prevent
                    unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial
                    procedures to safeguard and secure the information we collect online. While The National Society believes
                    that it has implemented reasonable security, The National Society cannot guarantee that our security measures
                    will prevent unauthorized third parties, such as hackers, from obtaining or accessing your
                    information.
                </p>

                <span class="text-left text-h4 font-weight-bold">
                    Use of Cookies; Email Pixels.
                </span>

                <p class="text-justify pt-4">
                    The National Society uses a feature of your Web browser called "cookies" to automate certain aspects of our
                    Web site. Cookies are very small files that are stored on your computer and that enable us to
                    recognize your computer each time you return to our site. By using cookies, The National Society can keep
                    track of your preferences, what you have already seen on our site and what you haven't, and any
                    personalization you've done, without requiring you to enter a password every time you come back.
                    The National Society uses any information gathered using our cookies in compliance with this Privacy Policy.
                </p>

                <p class="text-justify">
                    Third parties, including advertisers with whom The National Society may have a relationship, may also set
                    cookies. Such third parties are responsible for placing and using such cookies and may collect
                    information about you through such cookies; The National Society does not have access to such cookies,
                    control the use of such cookies nor control over the privacy policy of such parties.
                </p>
                <p class="text-justify">
                    We may use an emaildelivery and marketing company to send you emails. Pixel tags and cookies are
                    used in those emailmessages and at our website to help us measure the effectiveness of our
                    advertising and how visitors use our site.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        title: 'Privacy Policy for The National Society',
        metaInfo: {
            title: 'Privacy Policy for The National Society'
        },
    }
</script>
<style scoped>
    .about {
        padding-top: 64px !important;
        min-height: 51vh;
    }

    .list {
        list-style-type: disc;
        margin-left: 15px;
    }

    .list li {
        padding-bottom: 6px;
    }
</style>