<template>
    <v-container>
        <v-row>
            <v-col cols="12">

                <div class="text-left text-lg-h6 font-weight-bold pb-8">
                    Last updated on 15 April 2022.
                </div>
                <div class="text-left text-h4 font-weight-bold">
                    Terms & Conditions
                </div>
                <p class="text-justify pt-4">
                    The terms of use ("Terms and Conditions") mandate the terms on which the users ("You" or "Your" or
                    "User") access and register on the website www.nationalsociety.in and/ or mobile application
                    <span class="font-weight-bold">"National Society"</span> owned and operated by <span
                        class="font-weight-bold">Alegra Labs</span> whose registered office is Old UCO Bank Bldg.,
                    Adabari Tiniali, Guwahati 781012.
                </p>
                <p class="text-justify">
                    If you continue to browse and use this website you are agreeing to comply with and be bound by the
                    following terms and conditions of use, which together with our privacy policy govern <span
                        class="font-weight-bold">National Society</span> relationship with you in relation to this
                    website.
                </p>
                <span class="text-left text-h4 font-weight-bold">
                    The use of this website is subject to the following terms of use:
                </span>
                <div class="pl-4 pt-4 text-left text-h5 font-weight-bold">
                    Acceptance of Terms of Use Agreement.
                </div>
                <p class="text-justify pt-4">
                    <ul class="list">
                        <li>The content of the pages of this website is for your general information and use only. It is
                            subject to change without notice.</li>
                        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
                            timeliness, performance, completeness or suitability of the information and materials found
                            or offered on this website for any particular purpose. You acknowledge that such information
                            and materials may contain inaccuracies or errors and we expressly exclude liability for any
                            such inaccuracies or errors to the fullest extent permitted by law.</li>
                        <li>Your use of any information or materials on this website is entirely at your own risk, for
                            which we shall not be liable. It shall be your own responsibility to ensure that any
                            products, services or information available through this website meet your specific
                            requirements.</li>
                        <li>This website contains material which is owned by or licensed to us. This material
                            includes, but is not limited to, the design, layout, look, appearance and graphics.
                            Reproduction is prohibited other than in accordance with the copyright notice, which forms
                            part of these terms and conditions.</li>
                        <li>All trademarks reproduced in this website which are not the property of, or licensed to,
                            the operator are acknowledged on the website.</li>
                        <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal
                            offence.</li>
                        <li>From time to time this website may also include links to other websites. These links are
                            provided for your convenience to provide further information. They do not signify that we
                            endorse the website(s). We have no responsibility for the content of the linked website(s).
                        </li>
                        <li>You may not create a link to this website from another website or document without
                            National Society prior written consent.</li>
                        <li>Your use of this website and any dispute arising out of such use of the website is subject
                            to the laws of India or other regulatory authority.</li>
                    </ul>
                </p>
                <div class="pl-4 pt-4 text-left text-h5 font-weight-bold">
                    Account Security.
                </div>
                <p class="text-justify pt-4">
                    <ul class="list">
                        <li>
                            You are responsible for maintaining the confidentiality of your login credentials you
                            designate
                            during the registration process, and you are solely responsible for all activities that
                            occur under
                            your account. You agree to immediately notify us of any disclosure or unauthorized use of
                            your
                            account or any other breach of security, and ensure that you log out from your account at
                            the end of
                            each session.
                        </li>
                        <li>
                            We as a merchant shall be under no liability whatsoever in respect of any loss or damage
                            arising
                            directly or indirectly out of the decline of authorization for any Transaction, on Account
                            of the
                            Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank
                            from time
                            to time
                        </li>
                    </ul>
                </p>
                <div class="pl-4 pt-4 text-left text-h5 font-weight-bold">
                    Term and Termination.
                </div>
                <p class="text-justify pt-4">
                    <ul class="list">
                        <li>This Agreement will be effective, valid and subsisting as long as you use our Site.</li>
                        <li>You may terminate your membership at any time, for any reason by writing to Nationalsociety.in. In
                            the event you terminate your membership, you will not be entitled to a refund of any
                            unutilized subscription fees, if any, paid by you, except where otherwise guaranteed in
                            writing.</li>
                        <li>Nationalsociety.in may terminate your access to the Site and/or your membership for any
                            reason including but not limited to breach of the terms of use, using the service for
                            commercial purpose, engaging in prohibited or inappropriate communication or activity, by
                            sending notice to you at the email address as provided by you for membership. If
                            Nationalsociety.in terminates your membership for breach of terms of this Agreement, you
                            will not be entitled to any refund of any Subscription fees, if any, paid by you.</li>

                    </ul>
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        title: 'Terms & Conditions for The National Society',
        metaInfo: {
            title: 'Terms & Conditions for The National Society'
        },
    }
</script>
<style scoped>
    .about {
        padding-top: 64px !important;
        min-height: 51vh;
    }

    .list {
        list-style-type: disc;
        margin-left: 15px;
    }

    .list li {
        padding-bottom: 6px;
    }
</style>