import Vue from 'vue'
import ApiService from '../../services/api.service'
// import { TokenService } from '@/services/token.service'
// import _ from 'lodash'

const state = {
  fees: {},
  feesId: null,
  payments: [],
  paymentDetails: [],
  unpaid: [],
  payment: {},
  monthlyFees: {},
  societyMonthlyFeesData: {}
}

const mutations = {
  setFees(state, fees) {
    Vue.set(state, 'fees', {...fees})
  },
  setFeesId(state, feesId) {
    Vue.set(state, 'feesId', feesId)
  },
  setFeesProperty(state, { property, value }) {
    Vue.set(state.fees, property, value)
  },
  removeFeesProperty(state, property) {
    Vue.delete(state.fees, property)
  },
  setPayments(state, payments) {
    Vue.set(state, 'payments', [...payments])
  },
  setPaymentDetails(state, payments) {
    Vue.set(state, 'paymentDetails', [...payments])
  },
  setPaymentDetailsProperty(state, {property, value, index}) {
    Vue.set(state.paymentDetails[index], property, value)
  },
  removePaymentDetails(state) {
    Vue.set(state, 'paymentDetails', [])
  },
  removePayments(state) {
    Vue.set(state, 'payments', [])
  },
  setUnpaid(state, unpaid) {
    Vue.set(state, 'unpaid', [...unpaid])
  },
  setPayment(state, payment) {
    Vue.set(state, 'payment', {...payment})
  },
  resetPayment(state) {
    Vue.set(state, 'payment', {})
  },
  setMonthlyFees(status, fees) {
    Vue.set(state, 'monthlyFees', {...fees})
  },
  setSocietyMonthlyFeesData(status, fees) {
    Vue.set(state, 'societyMonthlyFeesData', {...fees})
  }
}

const actions = {
  createFees({ getters }) {
    return ApiService.post('/fees-api/v-1/fees/', {fields: getters.getFees})
  },
  updateFees({ getters }) {
    return ApiService.post(`/fees-api/v-1/update-fees/`, {fields: getters.getFees, id: getters.getFeesId})
  },
  fetchFees({commit}) {
    return ApiService.get('/fees-api/v-1/fees/').then((response) => {
      if (response.data.length > 0) {
        commit('setFees', response.data[0].fields)
        commit('setFeesId', response.data[0].id)
      }
    })
  },
  updatePayment({ dispatch }, data) {
    return ApiService.post('/fees-api/v-1/payment-fees/', data).then(() => {
      data.from_month = ''
      data.from_year = ''
      data.to_month = ''
      data.to_year = ''
      dispatch('getPaymentStatus', data)
    })
  },
  paymentRequest(_, data) {
    return ApiService.post('/fees-api/v-1/payment-request/', data)
  },
  getPaymentByID({ commit }, id) {
    return ApiService.get(`/fees-api/v-1/payment-fees/${id}`).then((response) => {
      commit('setPayment', response.data)
    })
  },
  getPaymentMulti({ commit }, id) {
    return ApiService.post(`/fees-api/v-1/fees-detials-multiple/`, id).then((response) => {
      commit('setPayment', response.data)
    })
  },
  getPaymentStatus({commit}, data) {
    return ApiService.get(`/fees-api/v-1/payment-status/?user=${data.user}&payment_month=${data.payment_month}&payment_year=${data.payment_year}&from_month=${data.from_month}&from_year=${data.from_year}&to_month=${data.to_month}&to_year=${data.to_year}`)
    .then((response) => {
      if(response.data.data) {
        commit('setPaymentDetails', response.data.data)
      }
    })
  },
  getUserPaymentStatus({commit}, data) {
    return ApiService.get(`/fees-api/v-1/user-payment-status/?from_month=${data.from_month}&from_year=${data.from_year}&to_month=${data.to_month}&to_year=${data.to_year}`)
    .then((response) => {
      commit('setPayments', response.data.data)
      commit('setUnpaid', response.data.due_months)
    })
  },
  fetchUserPayment({commit}) {
    return ApiService.get(`/fees-api/v-1/user-payment/`).then((response)=> {
      commit('setPayments', response.data.data)
    })
  },
  fetchLimitedUnpaidPayment({commit}) {
    return ApiService.get(`/fees-api/v-1/pending-user-payment/`).then((response)=> {
      commit('setPaymentDetails', response.data.data)
    })
  },
  fetchUnpaidPayment({commit}) {
    return ApiService.get(`/fees-api/v-1/pending-user-payment/`).then((response)=> {
      commit('setPaymentDetails', response.data.limited_data)
    })
  },
  fetchMonthlyFees({commit}) {
    return ApiService.get('/fees-api/v-1/society-fees/').then((response) => {
      commit('setMonthlyFees', response.data)
    })
  },
  fetchMonthlyFeesWithPage({commit}, page) {
    return ApiService.get(`/fees-api/v-1/society-fees/?page=${page}`).then((response) => {
      commit('setMonthlyFees', response.data)
    })
  },
  fetchMonthlyFeesWithUrl({commit}, url) {
    return ApiService.get(url).then((response) => {
      commit('setMonthlyFees', response.data)
    })
  },
  updateSocietyMonthlyFeesStatus({ dispatch }, data) {
    return ApiService.post(`/fees-api/v-1/update-society-fees-status/`, data).then(() => {
      dispatch('fetchMonthlyFees')
    })
  },
  fetchSocietyMonthlyFeesData({commit}) {
    return ApiService.get('/fees-api/v-1/society-fees-data/').then((response) => {
      commit('setSocietyMonthlyFeesData', response.data)
    })
  },
  societyPaymentRequest() {
    return ApiService.post('/fees-api/v-1/society-fees-request/')
  },
}

const getters = {
  getFees: state => state.fees,
  getFeesId: state => state.feesId,
  getPayments: state => state.payments,
  getPaymentDetails: state => state.paymentDetails,
  getUnpaid: state => state.unpaid,
  getPayment: state => state.payment,
  getMonthlyFees: state => state.monthlyFees,
  getSocietyMonthlyFeesData: state => state.societyMonthlyFeesData
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
