<template>
  <v-container class="add-event py-0 my-0">
    <v-row justify="center" v-show="check"> 
      <v-col cols="3" xl="2" class="pr-1 py-0">
        <v-card class="mx-0 left-container-card" height="100%" elevation="0">
         <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
          <v-divider class="mr-2"></v-divider>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="selectedListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index" :disabled="item.disabled"
                :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="settingsListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index"
                :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>
            <v-list-item-group v-model="bottomListItem" color="primary">
              <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index" :disabled="item.disabled"
                :to="{path: '/'+item.path}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="" cols="9">
        <v-card elevation="0">
          <v-card-title class="text-center text-h4 font-weight-bold pb-5" style="color: #62608b">Add Event
          </v-card-title>
          <v-row class="px-4" justify="start" align="center">
            <!-- <v-col cols="4">
              <v-file-input
                id="select-image"
                accept="image/*"
                label="Images"
                v-model="eventData.media_file"
                v-show="false"
                @change="onAddImages"
              ></v-file-input>
              <v-img max-height="199.43" v-if="eventData.media_file" max-width="337" :src="imageUrl" @click="selectImage"></v-img>
              <v-card class="select-image-card" elevation="0" v-else width="337" height="199.43">
                <v-row class="select-image" align="center" no-gutters>
                  <v-col cols="4" class="justify-center mx-auto">
                    <v-btn text large @click="selectImage">
                      <v-icon color="#D4C6BA" size="60">mdi-cloud-upload</v-icon>
                    </v-btn>
                    <v-card-text class="pa-0 text-center">Upload here</v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->
            <v-col cols="12" class="pb-10">
              <v-form id="create-event" @submit.prevent="createEvent">
              <div v-if="!$v.eventData.title.required && $v.eventData.title.$dirty"
                class="validation-text red--text text-body-2 error-span">Title required.</div>
              <div v-if="!$v.eventData.title.maxLength" class="validation-text red--text text-body-2">Title should be
                {{$v.eventData.title.$params.maxLength.max}} characters long.</div>
              <v-text-field class="rounded" label="Title*" autofocus v-model="$v.eventData.title.$model" outlined dense>
              </v-text-field>
              <v-row class="py-2">
                <v-col cols="5">
                  <div v-if="!$v.dateObject.required && $v.dateObject.$dirty"
                    class="validation-text red--text text-body-2 error-span">Event date required.</div>
                  <div v-if="!$v.dateObject.minValue" class="validation-text red--text text-body-2">Event date should be
                    an upcoming date.</div>
                  <DatePicker :rounded="'rounded'" :label="'Event Date*'" @get-date="eventData.event_date=$event">
                  </DatePicker>
                </v-col>
                <v-col cols="4">
                  <div v-if="!$v.eventData.event_time.required && $v.eventData.event_time.dirty"
                    class="validation-text red--text text-body-2 error-span">Description required.</div>
                  <v-select v-model.trim="eventData.event_time" class="rounded" label="Event Time" :items="timeArray"
                    item-text="text" item-value="value" outlined dense></v-select>

                </v-col>
                <v-col cols="3">
                  <div v-if="!$v.eventData.time_convention.required && $v.eventData.time_convention.$dirty"
                    class="validation-text red--text text-body-2 error-span">Description required.</div>
                  <v-select v-model="eventData.time_convention" class="rounded" label="Time Convention"
                    :items="timeConventionArray" item-text="text" item-value="value" outlined dense></v-select>

                </v-col>
              </v-row>
              <div v-if="!$v.eventData.description.required && $v.eventData.description.$dirty"
                class="validation-text red--text text-body-2 error-span">Description required.</div>
              <div v-if="!$v.eventData.description.maxLength" class="validation-text red--text text-body-2 error-span">
                Description should be {{$v.eventData.description.$params.maxLength.max}} characters long.</div>
              <v-textarea v-model="$v.eventData.description.$model" class="rounded" label="Description*" auto-grow
                outlined></v-textarea>
              <div v-if="limitExceed" class="red--text">File size limit exceed. File size must not greater than 5 MB</div>
              <div v-if="typeCheck" class="red--text">File must be image</div>
              <UploadImages :max="1" maxError @changed="handleImages" uploadMsg fileError clearAll class="rounded" />
              <v-checkbox v-model="$v.eventData.send_message.$model"
                label="Send message to all members about this event" color="project_primary" class="pt-4 my-0">
              </v-checkbox>
              <v-btn class="white--text text-uppercase btn-width" color="#477fca" :disabled="overlay" type="submit" for="create-event" >Create</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="postComplete" width="500" persistent>
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Event created successfully</span>

          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="finishedPost">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    required,
    requiredIf,
    maxLength,
    minValue
  } from 'vuelidate/lib/validators'
  import DatePicker from '@/components/DatePicker.vue'
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import UploadImages from "vue-upload-drop-images"
  export default {
    title: 'Add Events',
    metaInfo: {
      title: 'Add Events'
    },
    components: {
      DatePicker,
      UploadImages
    },
    data: () => ({
      setting_btn:false,
      limitExceed:false,
      typeCheck:false,
      eventData: {
        title: '',
        event_date: '',
        event_time: null,
        time_convention: null,
        media_file: undefined,
        description: '',
        send_message: false,
        check:false,
      },
      timeArray: [{
          text: 'select',
          value: null
        },
        {
          text: '1',
          value: 1
        },
        {
          text: '2',
          value: 2
        },
        {
          text: '3',
          value: 3
        },
        {
          text: '4',
          value: 4
        },
        {
          text: '5',
          value: 5
        },
        {
          text: '6',
          value: 6
        },
        {
          text: '7',
          value: 7
        },
        {
          text: '8',
          value: 8
        },
        {
          text: '9',
          value: 9
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '11',
          value: 11
        },
        {
          text: '12',
          value: 12
        },
      ],
      timeConventionArray: [{
          text: 'select',
          value: null
        },
        {
          text: 'AM',
          value: 'am'
        },
        {
          text: 'PM',
          value: 'pm'
        },
      ],
      imageUrl: '',
      errorImage: '',
      postComplete: false,
      overlay: false,
      selectedListItem: 0,
      settingsListItem: 0,
      bottomListItem: 0,
      profileList: [{
          text: 'Profile',
          icon: 'mdi-account',
          disabled: false,
          path: 'profile'
        },
        {
          text: 'My Discussions',
          icon: 'mdi-post',
          disabled: false,
          path: 'mydiscussion'
        },
        {
          text: 'My Complaints',
          icon: 'mdi-notebook-edit-outline',
          disabled: false,
          path: 'mycomplains'
        },
        {
          text: 'Fees',
          icon: 'mdi-currency-inr',
          disabled: false,
          path: 'fees'
        },
        // {
        //   text: 'Change Password',
        //   icon: 'mdi-lock',
        //   disabled: false,
        //   path: 'password'
        // },
      ],
      settingsList: [{
        text: 'Change Password',
        icon: 'mdi-lock',
        disabled: false,
        path: 'password'
      }, ],
      bottomList: [{
        text: 'Home',
        icon: 'mdi-home',
        disabled: false,
        path: ''
      }, ],
    }),
    computed: {
      ...mapGetters('society', {
        society: 'getSociety'
      }),
      ...mapGetters('user', {
        user: 'getUser'
      }),
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        isDefaultPassword: 'isDefaultPassword'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      dateObject() {
        return this.eventData.event_date ? new Date(this.eventData.event_date) : null
      }
    },
    validations() {
      let currentDate = new Date()
      return {
        eventData: {
          title: {
            required,
            maxLength: maxLength(100)
          },
          description: {
            required,
            maxLength: maxLength(20000)
          },
          event_time: {
            required: requiredIf(() => {
              return this.eventData.time_convention !== null
            })
          },
          time_convention: {
            required: requiredIf(() => {
              return this.eventData.event_time !== null
            })
          },
          send_message: {

          }
        },
        dateObject: {
          required,
          minValue: minValue(currentDate.setDate(currentDate.getDate() - 1))
        }
      }
    },
    watch: {
      postComplete() {
        if (this.postComplete) {
          setTimeout(() => {
            this.postComplete = false
            this.$router.push({
              path: '/'
            })
          }, 3000)
        }
      },
    },
    methods: {
      ...mapActions('society', [
        'fetchSociety'
      ]),
      ...mapActions('event', [
        'postEvents'
      ]),
      ...mapActions('user', [
        'fetchUser'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      selectImage() {
        document.getElementById("select-image").click()
      },
      onAddImages(file) {
        const reader = new FileReader()
        reader.addEventListener('load', e => this.imageUrl = e.target.result)
        reader.addEventListener('error', () => this.imageUrl = this.errorImage)
        reader.readAsDataURL(file)
      },
      createEvent() {
        this.overlay = true
        this.fetchSociety()
        this.$v.$touch()
        if (!this.$v.$invalid) {
          let formData = new FormData()
          if (this.eventData.media_file && this.eventData.media_file.size && this.eventData.media_file.size > 0) {
            formData.append('media_file', this.eventData.media_file)
          }
          formData.append('society', this.user.society)
          formData.append('title', this.eventData.title)
          formData.append('description', this.eventData.description)
          formData.append('event_date', this.eventData.event_date)
          formData.append('send_message', this.eventData.send_message)
          if (this.eventData.event_time) {
            formData.append('event_time', this.eventData.event_time)
          }
          if (this.eventData.time_convention) {
            formData.append('time_convention', this.eventData.time_convention)
          }

          this.postEvents(formData).then(() => {
            this.postComplete = true
            this.overlay = false
          }).catch(() => {
            this.overlay = false
          })
        } else {
          this.overlay = false
        }
      },
      finishedPost() {
        this.postComplete = false
        this.$router.push('/account/events')
      },
      handleImages(file) {
        const fileType = ["image/gif","image/jpg","image/jpeg","image/png","image/bmp"]
        console.log(file)
        if(file){
            let file_size = file[0].size/(1024*1024);
            console.log(file_size)
            if(file_size>5){
              this.overlay = true
              this.limitExceed=true
              this.typeCheck=false
            }
            else if( !fileType.includes(file[0].type) ){
              this.overlay = true
              this.typeCheck=true
              this.limitExceed=false
            }
            else{
              this.overlay = false
              this.typeCheck = false
              this.limitExceed=false
            }
        }
        this.eventData.media_file = file[0]
        console.log('file', file[0])

      }
    },
    mounted() {
      this.fetchUser().then(() => {
        console.log('user', this.user)
      })
    },
    created() {
      this.fetchSettings().then(() => {
        if (this.isAdmin) {
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.profileList.shift()
          this.settingsList.unshift({
            'text': 'Settings',
            'icon': 'mdi-cog-outline',
            'disabled': this.isDefaultPassword,
            'path': 'settings'
          })
          this.profileList.unshift({
            'text': 'Fees',
            'icon': 'mdi-currency-inr',
            'disabled': this.isDefaultPassword,
            'path': 'fees-settings'
          })
          this.profileList.unshift({
            'text': 'Securities',
            'icon': 'mdi-security',
            'disabled': this.isDefaultPassword,
            'path': 'security-setting'
          })
          if (this.settings.enable_events) {
            this.profileList.unshift({
              'text': 'Events',
              'icon': 'mdi-calendar-today',
              'disabled': this.isDefaultPassword || !this.settings.enable_events,
              'path': 'events'
            })
          }
          this.profileList.unshift({
            'text': 'Complaints',
            'icon': 'mdi-notebook-edit-outline',
            'disabled': this.isDefaultPassword,
            'path': 'complains'
          })
          if (this.settings.enable_posts) {
            this.profileList.unshift({
              'text': 'Discussions',
              'icon': 'mdi-post',
              'disabled': this.isDefaultPassword || !this.settings.enable_posts,
              'path': 'discussion'
            })
          }

          if (this.settings.enable_news) {
            this.profileList.unshift({
              'text': 'Notice Board',
              'icon': 'mdi-information-outline',
              'disabled': this.isDefaultPassword || !this.settings.enable_news,
              'path': 'notice'
            })
          }
          this.profileList.unshift({
            'text': 'Residents',
            'icon': 'mdi-account-check',
            'disabled': this.isDefaultPassword,
            'path': 'residents'
          })
        } else {
          if (!this.settings.enable_posts) {
            this.profileList = this.profileList.filter((value) => {
              return value.path !== 'myposts'
            })
          }
        }
        this.check=true
      })
    }
  }
</script>
<style scoped>
  .add-event {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .select-image-card {
    background: #F7F7F7;
    border: 1px dashed #423D3D;
  }

  .select-image {
    height: inherit;
  }

  .input-text-section {
    height: 370px;
    display: grid;
    align-self: center;
  }

  .validation-text {
    position: relative;
    bottom: 20px;
  }


  .error-span {
    margin-bottom: -18px !important;
  }

  .left-container-card {
    position: fixed;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .title-size {
    font-size: 13px;
    text-transform: uppercase;
  }
</style>