<template>
  <v-container>
    <!-- <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step>1"
          step="1"
        >
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper> -->
    <v-row>
      <v-col class="" xl="8" offset-xl="2" lg="8" offset-lg="2" md="8" offset-md="2" sm="12" cols="12">
        <v-card color="rounded" elevation="0" outlined class="mx-auto">
          <v-card-text v-if="showError" class="red--text px-7">{{errorMsg}}</v-card-text>
          <v-form v-model="formValid" id="forgot-password-form" @submit.prevent="checkUser">
            <v-row justify="start" align="center" class="px-3 py-10">
              <v-col cols="7" class="px-10 pt-6">
                <span class="text-lg-h4 text-h5 font-weight-bold" style="color: rgb(98, 96, 139);">Forgot
                  Password</span>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="10" class="px-10 mb-0 pb-0">
                <span v-if="!$v.userData.mobile_number.required && $v.userData.mobile_number.$dirty"
                  class="red--text text-body-2">Mobile number required.</span>
                <v-text-field v-model.trim="$v.userData.mobile_number.$model" class="rounded" outlined autofocus dense
                  label="Mobile Number *" :rules="[$v.userData.mobile_number.required, rules.phoneNumber]">
                </v-text-field>
              </v-col>
              <v-col cols="7" class="mt-0 pt-0">
                <v-card-actions class="px-7">
                  <v-btn class="text-uppercase white--text" color="rgb(71, 127, 202)" width="30%" type="submit"
                    for="forgot-password-form">Continue</v-btn>
                  <v-btn class="text-uppercase white--text" color="error" width="30%" @click="reset">Cancel</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="passwordDialog" width="700" persistent>
      <v-card class="rounded-lg">
        <v-form id="change-password-tab" @submit.prevent="forgotUserPassword">
          <v-row justify="start" no-gutters class="py-10">
            <v-col cols="12" class="px-7">
              <v-card-title class="text-uppercase font-weight-bold text-h5 text-lg-h4" style="color: rgb(98, 96, 139);">
                Change Password</v-card-title>
            </v-col>
            <v-col cols="12" class="pb-4">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" class="px-10">
              <span v-if="!$v.userData.password.required && $v.userData.password.$dirty"
                class="red--text text-body-2">Password required.</span>
              <span v-if="!$v.userData.password.minLength" class="red--text text-body-2">Password must be atleast 8
                charecter long.</span>
              <v-text-field v-model="$v.userData.password.$model" class="rounded" outlined dense autofocus
                label="Password *" :type="showPassword ? 'text': 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                style="background-color: white">
              </v-text-field>
            </v-col>
            <v-col cols="10" class="px-10">
              <span v-if="!$v.userData.confirm_password.required && $v.userData.confirm_password.$dirty"
                class="red--text text-body-2">Confirm Password required.</span>
              <span v-if="!$v.userData.confirm_password.sameAsPassword" class="red--text text-body-2">Confirm Password
                must be as Password.</span>
              <v-text-field v-model="$v.userData.confirm_password.$model" class="rounded" outlined dense
                label="Confirm Password *" :type="showConfirmPassword ? 'text': 'password'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
            </v-col>
            <v-col cols="7" class="px-8">
              <v-card-actions>
                <v-btn color="rgb(71, 127, 202)" class="white--text text-uppercase" width="30%" type="submit"
                  for="change-password-tab">
                  Continue
                </v-btn>
                <v-btn color="error" class="white--text text-uppercase" width="30%" @click="reset">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendOtp" persistent width="30%">
      <SendOtp title="Verify OTP" :data="userData" otp_type="forgot_password" :mobile_number="userData.mobile_number"
        @cancel-otp="sendOtp=false" @verify-success="forgotUserPassword"
        @verify-failed="errorMsg='OTP is not matched.'"
        @invalid-request="errorMsg='Somthing went wrong.'"></SendOtp>
    </v-dialog>
    <v-dialog v-model="successMessage" width="500" persistent>
      <v-card justify="center" align="center" class="" rounded style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <span class="text-h4 font-weight-bold">Password has changed successfully</span>
          <br>
          <v-form id="redirectTo" @submit.prevent="redirectTo">
            <v-btn large depressed class="white--text rounded" color="#8bd4f5" type="submit" for="redirectTo"
              style="margin-top:25px">
              OK
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    required,
    minLength,
    sameAs
  } from 'vuelidate/lib/validators'
  import {
    mapActions
  } from 'vuex'
  import SendOtp from '@/components/SendOtp'
  export default {
    title: 'Forgot Password',
    metaInfo: {
      title: 'Forgot Password for National Society'
    },
    components: {
      SendOtp
    },
    data: () => ({
      userData: {
        mobile_number: '',
        password: '',
        confirm_password: ''
      },
      formValid: false,
      showError: false,
      successMessage: false,
      rules: {
        phoneNumber: val => (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val) ||
          /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val)) || 'Phone Number Must be valid',
      },
      showPassword: false,
      showConfirmPassword: false,
      passwordDialog: false,
      errorMsg: '',
      sendOtp: false,
      step: 1
    }),
    validations() {
      if (this.passwordDialog) {
        return {
          userData: {
            mobile_number: {
              required
            },
            password: {
              required,
              minLength: minLength(8)
            },
            confirm_password: {
              required,
              sameAsPassword: sameAs('password')
            }
          }
        }
      } else {
        return {
          userData: {
            mobile_number: {
              required
            },
            password: {
              minLength: minLength(8)
            },
            confirm_password: {
              sameAsPassword: sameAs('password')
            }
          }
        }
      }
    },
    methods: {
      ...mapActions('user', [
        'checkUserExist',
        'forgotPassword'
      ]),
      checkUser() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.checkUserExist({
            userData: this.userData
          }).then((response) => {
            if (response.data.isUser) {
              this.passwordDialog = true
              this.showError = false
            } else {
              this.showError = true
              this.reset()
              this.errorMsg = 'User not exist.'
            }
          })
        }
      },
      reset() {
        this.userData.mobile_number = ''
        this.userData.password = ''
        this.userData.confirm_password = ''
        this.passwordDialog = false
        this.sendOtp = false
      },
      forgotUserPassword() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.forgotPassword({
            userData: this.userData
          }).then((response) => {
            if (response.data.otp || response.data.matched_failed) {
              this.sendOtp = true
            } else {
              //this.reset()
              this.passwordDialog = false
              this.sendOtp = false
              this.successMessage = true
            }
          }).catch(() => {
            this.reset()
          })
        }
      },
      redirectTo() {
        this.successMessage = false
        this.$router.push('/')
      }
    }
  }
</script>
<style scoped>
  .forgot-password {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
</style>