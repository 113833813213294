<template>
  <v-container>
    <!-- <v-row justify="center">
      <v-card width="68%" class="my-15" elevation="0">
        <v-row justify="center" align="center">
          <v-col cols="5">
            <v-card-title class="px-0 text-h5 font-weight-bold text-uppercase">Complaints and Grievances</v-card-title>
          </v-col>
          <v-col cols="5" class="justify-end d-flex">
            <v-btn color="#434D3D" class="text-upperclass white--text rounded" v-if="!isAdmin" @click="addComplaintDialog=true">
              <v-icon class="mx-1">mdi-plus</v-icon>Add Complaints
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-card height="211" class="mb-6 rounded-lg text-card" v-for="(complaint, index) in complaints.data" :key="index">
              <v-container>
                <v-chip color="green" v-if="complaint.solution_status=='solved'" outlined label class="mx-4 rounded text-uppercase subtitle-1">closed</v-chip>
                <v-row class="ma-0 pa-4">
                  <v-card-title v-text="complaint.title" class="text-h5 pa-0 text-capitalize font-weight-bold text-justify"></v-card-title>
                  <v-card-text v-text="complaint.description" v-if="complaint.description.length<200 || showMore" class="text-subtitle-1 px-0 py-2 text-justify"></v-card-text>
                  <v-card-text v-text="complaint.description.slice(0, 200)" v-else class="text-subtitle-1 px-0 py-2 text-justify"></v-card-text>
                  <a v-if="complaint.description.length>200" @click="moreLess">{{linkText}}</a>
                </v-row>
              </v-container>
            </v-card>
            <v-card height="100" class=" mb-6 rounded-lg text-card" v-if="complaints.data.length==0">
              <v-card-title>No data available</v-card-title>
            </v-card>
            <v-pagination
              v-model="page"
              :length="Math.ceil(complaints.count/10)"
              :total-visible="7"
              color="project_primary"
              @input="isAdmin?fetchComplaintsAndGrievancesWithPage($event):fetchUserApprovedComplaintsAndGrievancesWithPage($event)"
              @previous="isAdmin?fetchComplaintsAndGrievancesWithUrl(complaints.previous):fetchUserApprovedComplaintsAndGrievancesWithUrl(complaints.previous)"
              @next="isAdmin?fetchComplaintsAndGrievancesWithUrl(complaints.next):fetchUserApprovedComplaintsAndGrievancesWithUrl(complaints.next)"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-row> -->
    <v-row class="py-5" justify="center" align="center"
      style="margin-bottom:0 !important;padding-bottom:0px !important">

      <v-col xs="12" sm="12" md="3" lg="3" xl="3" class="d-flex justify-lg-start " style="padding-top:15px;">
        <p class="font-weight-bold text-caption pt-2" style="font-size:18px !important;">
          Complaints({{ complaints.count }})
        </p>
      </v-col>

      <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="searchDiv " style="padding-top:15px">
        <v-text-field rounded color="#FFA500" v-model="searchQuery" dense label="Search" outlined
          append-icon="mdi-magnify" single-line hide-details></v-text-field>
      </v-col>


      <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
        <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0" color="primary"
          plain @click="$router.back()" :ripple="false">
          Back
        </v-btn>
      </v-col>

    </v-row>
    <v-row align="center" v-for="(complaint, index) in filteredComplaints" :key="index">
      <v-col class="pt-0 mt-0" cols="12">
        <div class="">
          <v-card-title v-text="complaint.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
            style="font-size:18px !important;">
          </v-card-title>
          <div>
            <v-card-text class="text-caption text-capitalize text-justify pl-0 ml-0" style="font-size:15px !important;">
              <span v-if="complaint.show_full_description">{{complaint.description}}...</span>
              <span v-else>{{complaint.description.slice(0, 100)}}...</span>
              <!-- <a @click="moreLess(index)">{{linkText(complaint)}}</a> -->
              <a @click="complaintSelected(complaint)">{{linkText(complaint)}}</a>
            </v-card-text>
          </div>
        </div>
      </v-col>

      <v-col cols="6" class="d-flex justify-start align-center">
        <span class="text-capitalize font-italic font-weight-bold" style="font-size:15px !important;color:#707070">
          Comments({{complaint.complaint_comment.length}})
        </span>
      </v-col>

      <v-col cols="6" class="d-flex justify-end ">
        <span class="text-capitalize font-italic" style="font-size:15px !important;">
          Started by: {{complaint.full_name}} on {{getDate(complaint.added)}}
        </span>
      </v-col>
      
      <v-col>
        <v-divider class="pt-2"></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="complaints.data.length>0">
      <v-pagination v-model="page" :length="Math.ceil(complaints.count/10)" :total-visible="7" color="primary"
        @input="isAdmin?fetchComplaintsAndGrievancesWithPage($event):fetchUserApprovedComplaintsAndGrievancesWithPage($event)"
        @previous="isAdmin?fetchComplaintsAndGrievancesWithUrl(complaints.previous):fetchUserApprovedComplaintsAndGrievancesWithUrl(complaints.previous)"
        @next="isAdmin?fetchComplaintsAndGrievancesWithUrl(complaints.next):fetchUserApprovedComplaintsAndGrievancesWithUrl(complaints.next)">
      </v-pagination>
    </v-row>
    <v-dialog transition="dialog-right-transition" max-width="700" persistent v-model="addComplaintDialog">
      <AddComplaints @cancel-add="addComplaintDialog=false"></AddComplaints>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="white" width="5"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import AddComplaints from '@/components/AddComplaints.vue'
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  export default {
    title: 'Complaints and Grievances',
    metaInfo: {
      title: 'Complaints and Grievances for National Society'
    },
    components: {
      AddComplaints
    },
    data: () => ({
      addComplaintDialog: false,
      showMore: false,
      //linkText: 'more',
      overlay: false,
      page: 1,
      complaintPerPage: 5,
      searchQuery: '',
    }),
    computed: {
      ...mapGetters('user', {
        complaints: 'getComplaints'
      }),
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin'
      }),
      ...mapGetters('settings', {
        settings: 'getSettings'
      }),
      filteredComplaints() {
        if (this.searchQuery) {
          return this.complaints.data.filter((complaints) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return complaints.title.toLowerCase().includes(search) || complaints.description
                .toLowerCase().includes(
                  search) || complaints.full_name.toLowerCase().includes(search)
            })
          })
        } else {
          return this.complaints.data
        }
      },
      currentPagePosts() {
        return this.postPerPage != null ? this.filteredComplaints.slice((this.page - 1) * this.postPerPage, (this
            .page - 1) *
          this.postPerPage + this.postPerPage) : this.filteredComplaints
      }
      // currentPageComplaints() {
      //   return this.complaints.slice((this.page-1)*this.complaintPerPage,(this.page-1)*this.complaintPerPage+this.complaintPerPage)
      // }
    },
    methods: {
      ...mapActions('user', [
        'fetchComplaintsAndGrievances',
        'fetchComplaintsAndGrievancesWithPage',
        'fetchComplaintsAndGrievancesWithUrl',
        'fetchUserApprovedComplaintsAndGrievances',
        'fetchUserApprovedComplaintsAndGrievancesWithPage',
        'fetchUserApprovedComplaintsAndGrievancesWithUrl'
      ]),
      ...mapActions('settings', [
        'fetchSettings',
      ]),
      ...mapMutations('user', [
        'setComplaintsProperty',
        'setComplaint'
      ]),
      moreLess(index) {
        this.showMore = !this.showMore
        this.setComplaintsProperty({
          index: index,
          property: 'show_full_description',
          value: this.showMore
        })
      },
      linkText(complaint) {
        if (complaint.show_full_description) {
          return 'less'
        } else {
          return 'Read full complaint'
        }
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      },
      complaintSelected(complaint) {
        this.setComplaint(complaint)
        this.$router.push(`/complaint/${complaint.title.replace(/\s/g, '-')}-${complaint.id}/`)
      },
    },
    mounted() {
      this.overlay = true
      this.fetchSettings().then(() => {
        if (this.settings.show_complaints || this.isAdmin) {
          this.fetchComplaintsAndGrievances().then(() => {
            this.overlay = false
          })
        } else {
          this.fetchUserApprovedComplaintsAndGrievances().then(() => {
            this.overlay = false
            for (let complaint in this.complaints.data) {
              this.setComplaintsProperty({
                index: complaint,
                property: 'show_full_description',
                value: false
              })
            }
          })
        }
      })
    }
  }
</script>
<style scoped>
  .complaints-and-grievances {
    padding-top: 64px !important;
  }

  .user-info {
    width: 100%;
  }

  .text-card {
    overflow-y: scroll;
    padding: 0;
  }

  .row+.row {
    margin-top: 0 !important;
  }
</style>