<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mb-0 pb-0" style="margin-bottom:-20px !important">
        <v-img :src="image"></v-img>
        <div class="hover-img">
          <span class="title text-h4 font-weight-bold">Contact Us</span><br>
          <span class="desc">Send us a message and we will be in <br>
            touch with you within 24 hours.
          </span>
        </div>

      </v-col>
      <v-col cols="5" class="pt-0 mt-0">

        <v-form id="contact_details" @submit.prevent="sendContactDetails">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <p class="font-weight-light text-caption">
                Fields marked* are mandatory
              </p>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <span v-if="!$v.contactDetails.full_name.required && $v.contactDetails.full_name.$dirty" class="validation-text red--text text-body-2">Full Name required.</span>
              <v-text-field autofocus outlined dense label="Full Name*" v-model="contactDetails.full_name"></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <span v-if="!$v.contactDetails.contact_number.required && $v.contactDetails.contact_number.$dirty" class="validation-text red--text text-body-2">Contact number required.</span>
              <span v-if="!$v.contactDetails.contact_number.phoneNumber && $v.contactDetails.contact_number.$dirty" class="validation-text red--text text-body-2">Contact number should be valid.</span>
              <v-text-field outlined dense label="Contact Number*" v-model="contactDetails.contact_number"></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <span v-if="!$v.contactDetails.contact_email.email && $v.contactDetails.contact_email.$dirty" class="validation-text red--text text-body-2">Email should be valid.</span>
              <v-text-field outlined dense label="Email" v-model="contactDetails.contact_email"></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <span v-if="!$v.contactDetails.contact_msg.required && $v.contactDetails.contact_msg.$dirty" class="validation-text red--text text-body-2">Message required.</span>
              <v-textarea label="Message *" outlined dense auto-grow v-model="$v.contactDetails.contact_msg.$model">
              </v-textarea>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" >
              <v-btn class="white--text" color="#5da55b" type="submit" for="contact_details" :loading="overlay">
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </v-col>
      <v-col cols="5" offset="2" class="mt-0 pt-0">
        <div class="mt-0 pt-0">
          <span class="font-weight-bold text-h6" style="color:#a93d23">Email</span><br>
          <span class="font-weight-bold grey--text">info@nationalsociety.in</span>
        </div>
        <div class="mt-12">
          <span class="font-weight-bold text-h6" style="color:#a93d23">Address</span><br>
          <span class="font-weight-bold grey--text">
            Old UCO Bank Building, <br>Adabari Tiniali, <br>Guwahati - 781012, Assam
          </span>
        </div>
        <div class="mt-12">
          <span class="font-weight-bold text-h6" style="color:#a93d23">Contact Number</span><br>
          <span class="font-weight-bold grey--text">+91-8822774191</span>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="sending" width="500">
      <v-card justify="center" align="center" class="rounded-lg" style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>
          <div class="text-h4 green--text font-weight-bold">Thank you for contacting us.</div>
          <div class="text-h6 font-weight-bold">We will soon get back to you.</div>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px" @click="messageSendSuccessfull">
              OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import image from '@/assets/nscontact.jpg'
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import { required, email } from 'vuelidate/lib/validators'
  import { helpers } from 'vuelidate/lib/validators'
  const phoneNumber = helpers.regex('phoneNumber', (/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))
  export default {
    title: 'Contact Informations for National Society',
    metaInfo: {
      title: 'Contact Informations for National Society'
    },
    data: () => ({
      showDialog: false,
      overlay: false,
      image,
      contactDetails: {
        full_name: '',
        contact_number: '',
        contact_email: '',
        contact_msg: ''
      },
      sending: false
    }),
    computed: {
      ...mapGetters('settings', {
        contacts: 'getSettings'
      })
    },
    validations() {
      return {
        contactDetails: {
          full_name: {
            required
          },
          contact_number: {
            required,
            phoneNumber
          },
          contact_email: {
            email
          },
          contact_msg: {
            required
          }
        }
      }
    },
    methods: {
      ...mapActions('settings', [
        'fetchSettings',
        'sendEmail'
      ]),
      sendContactDetails() {
        this.$v.$touch()
        if(!this.$v.$invalid) {
          this.overlay = true
          this.sendEmail(this.contactDetails).then(() => {
            this.sending = true
            this.overlay = false
            console.log('send successfully')
          })
        }
      },
      messageSendSuccessfull() {
        Object.assign(this.$data, this.$options.data.apply(this))
        this.sending = false
        this.$v.$reset()
      }
    },
    mounted() {
      this.overlay = true
      this.fetchSettings().then(() => {
        this.overlay = false
      }).finally(()=>{
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .contact {
    padding-top: 64px !important;
  }

  .subtitle {
    display: flex !important;
    justify-content: center !important;
  }

  .title {
    /* position: relative;
    top: -30%;
    left: 3%; */
    color: white;
  }

  .desc {
    /* position: relative;
    top: -30%;
    left: 3%;
    bottom:0%; */
    color: white;
    line-height: 1.2;
  }

  .hover-img {
    position: relative;
    top: -18vh;
    left: 3%;
    bottom: 0;
  }
</style>