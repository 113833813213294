<template> 
  <v-container class="signin">
    <v-row>
      <v-col class="" xl="4" offset-xl="4" lg="6" offset-lg="3" md="6" offset-md="3" sm="12" xs="12">
        <v-card class="mx-auto" outlined style="padding-left: 20px; padding-right: 20px">
          <v-chip
            color="#ed6237"
            v-if="showError"
            text-color="white"
            label
            style="width:100%; top: -11px;"
            @click:close="showError=false"
          >Wrong credentials.</v-chip>
          <v-list-item three-line>
            <v-list-item-content>
              
              <div class="text-left text-lg-h4 font-weight-bold pb-5 " style="color: #62608b">
                Sign In to NationalSociety.in
              </div>

              <v-form v-model="formValid" id="signin-form" @submit.prevent="signinFunction">
                <v-row>
                  <v-col class="" cols="12">
                    <div v-if="!$v.signinData.mobile_number.required && $v.signinData.mobile_number.$dirty" class="validation-text red--text text-body-2 error-span">Mobile number required.</div>
                    <div v-if="!$v.signinData.mobile_number.phoneNumber" class="validation-text red--text text-body-2 error-span">Mobile Number Must be valid.</div>
                    <v-text-field
                      :autofocus="!autofocus"
                      outlined
                      dense
                      v-model.trim="$v.signinData.mobile_number.$model"
                      label="Mobile Number*"
                    ></v-text-field>
                  
                  </v-col>

                  <v-col class="ml-1" cols="12" style="margin-top:-50px !important;margin-bottom:10px">
                    <v-radio-group v-model="typeOfAuth" row class="ma-0" @change="changeAuthType($event)">
                      <v-radio
                        label="Sign in with password"
                        value="password"
                      ></v-radio>
                      <v-radio
                        label="Sign in with OTP"
                        value="OTP"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col class="form-col" cols="12" v-if="typeOfAuth=='password'">
                    <div v-if="!$v.signinData.password.required && $v.signinData.password.$dirty" class="validation-text red--text text-body-2 error-span">Password required.</div>
                    <div v-if="!$v.signinData.password.minLength" class="validation-text red--text text-body-2 error-span">Password must be atleast 8 charecter long.</div>
                    <v-text-field 
                      v-model="signinData.password"
                      label="Password*"
                      outlined
                      dense
                      :type="showPassword ? 'text': 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                
                  </v-col>

                  <v-col class="form-col" cols="12" v-else>
                    <span v-if="!$v.signinData.otp.required && $v.signinData.otp.$dirty" class="validation-text red--text text-body-2">OTP required.</span>
                    <span v-if="!$v.signinData.otp.numeric" class="validation-text red--text text-body-2">OTP should be numarical.</span>
                    <span v-if="!$v.signinData.otp.minLength || !$v.signinData.otp.maxLength" class="validation-text red--text text-body-2">OTP must be 4 charecter long.</span>
                    <v-text-field 
                      v-model="signinData.otp"
                      label="OTP*"
                      outlined
                      dense
                      :autofocus="autofocus"
                      :type="showPassword ? 'text': 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>

                  <v-col class="" cols="12" style="margin-top:-40px !important" v-if="typeOfAuth=='password'">
                    <v-row>
                      <v-col xs="12" sm="6" md="6" lg="6"   class="d-flex justify-start" style="margin-top:-12px !important;">
                        <v-checkbox  v-model="rememberMe" label="Remember me" :ripple="false"></v-checkbox>
                      </v-col>
                      <v-col xs="12" sm="6" md="6" lg="6"  class="d-flex justify-lg-end justify-md-end justify-sm-end justify-xs-start forgot-div" >
                        <v-btn plain color="#003366" class="ma-0 pa-0 text-capitalize text-body-2 text-decoration-underline text-underline-btn" elevation="0" to="forgot-password" :ripple="false">Forgot password?</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="" cols="12" style="margin-top:-40px !important" v-else>
                    <v-row>
                      <v-col xs="12" sm="6" md="6" lg="6"   class="d-flex justify-start" style="margin-top:-12px !important;">
                         <v-btn plain color="#003366" width="30%" @click="sendOTP" class="text-uppercase d-flex justify-start px-0 rounded pt-5">Resend</v-btn>
                      </v-col>
                      <v-col xs="12" sm="6" md="6" lg="6"  class="d-flex justify-lg-end justify-md-end justify-sm-end justify-xs-start forgot-div" >
                        <v-btn plain color="#003366" class="ma-0 pa-0 text-capitalize text-body-2 text-decoration-underline text-underline-btn" elevation="0" to="forgot-password" :ripple="false">Forgot password?</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="" cols="12" style="margin-top:-10px !important">
                    <v-btn class="white--text btn-width" color="#477fca" type="submit" for="signin-form">
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row class="pt-10 mb-0 pb-0">
                <v-col class="logo-div mb-0 pb-0" cols="12">
                  <v-row class="mb-0 pb-0">
                    <v-col cols="12" class="mb-0 pb-0" style="position: relative;">
                      <v-divider class="line"></v-divider>
                      <img class="icon" :src="icon" alt="" />
                    </v-col>
                    <v-col style="position:absolute;">
                      <v-chip
                        color="#ed6237"
                        v-if="invalidID"
                        text-color="white"
                        label
                        style="width:100%;"
                        @click:close="invalidID=false"
                      >Unrecognised Society ID.</v-chip>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-0 pt-0">
                  <p class="text-lg-h6 font-weight-bold" style="line-height: 20px">
                    My Society is registered and i would like to become a member
                    of my society
                  </p>
                  <p class="
                      text-md-body-1
                      green--text
                      font-weight-bold font-italic
                    " style="margin-top: -12px;margin-bottom:6px">
                    I know my Society Id.
                  </p>
                  <v-form v-model="societyForm" id="society-id" @submit.prevent="isValidRegistration">
                    <v-text-field
                      label="Enter your Society Id"
                      placeholder=""
                      outlined
                      dense
                      v-model="$v.society_registration_number.$model"
                      :error-messages="!$v.society_registration_number.required && $v.society_registration_number.$dirty ? 'Registration required': ''"
                    ></v-text-field>
                    <v-btn class="white--text btn-width" color="#477fca" type="submit" for="society-id">
                      Continue
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="sendOTPDialog" persistent width="30%">
      <v-card class="pb-5 rounded-lg">
        <v-card-title class="text-left text-h5 font-weight-bold py-5" style="color: #62608b">Do you want to sign in with OTP?</v-card-title>
        <v-card-actions class="mx-2">
          <v-btn color="success" min-width="100px" class="white--text text-subtitle-1 text-capitalize" @click="sendOTP">Send OTP</v-btn>
          <v-btn color="#ed6237" min-width="100px" class="white--text text-subtitle-1 text-capitalize" @click="sendOTPDialog=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, requiredIf, minLength, numeric, maxLength } from 'vuelidate/lib/validators'
import { helpers } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import icon from '@/assets/icon.png'
const phoneNumber = helpers.regex('phoneNumber', (/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))

export default {
  title: 'Signin for National Society',
  metaInfo: {
    title: 'Signin for National Society'
  },
  data: () => ({
    icon,
    signinData: {
      mobile_number: '' || localStorage.getItem('mobile_number'),
      password: '' || localStorage.getItem('password'),
      otp: ''
    },
    formValid: false,
    showError: false,
    rules: {
      phoneNumber: val => (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val) || /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val)) || 'Phone Number Must be valid',
    },
    showPassword: false,
    isAdminSignin: false,
    rememberMe: false,
    typeOfAuth: "password",
    sendOTPDialog: false,
    society_registration_number: '',
    invalidID: false,
    societyForm: false,
    autofocus: false,
  }),
  validations() {
    return {
      signinData: {
        mobile_number: {
          required,
          phoneNumber
        },
        password: {
          required: requiredIf(() => {
            return this.typeOfAuth == 'password'
          }),
          minLength: minLength(8)
        },
        otp: {
          required: requiredIf(() => {
            return this.typeOfAuth == 'OTP'
          }),
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(4)
        }
      },
      society_registration_number: {
        required
      }
    }
  },
  watch: {
    rememberMe() {
      if(this.rememberMe) {
        localStorage.setItem('mobile_number', this.signinData.mobile_number)
        localStorage.setItem('password', this.signinData.password)
      } else {
        localStorage.removeItem('mobile_number')
        localStorage.removeItem('password')
      }
    }
  },
  computed: {
    mobileNumberError() {
      return this.$v.signinData.mobile_number.required
    },
  },
  methods: {
    ...mapActions('user', [
      'SendOTPUser'
    ]),
    ...mapActions('JWT', [
      'fetchJWT'
    ]),
    ...mapActions('society', [
      'checkRegistrationValidity'
    ]),
    signinFunction() {
      this.$v.signinData.$touch()
      if(!this.$v.signinData.$invalid) {
        if(this.rememberMe) {
          localStorage.setItem('mobile_number', this.signinData.mobile_number)
          localStorage.setItem('password', this.signinData.password)
        } else {
          localStorage.removeItem('mobile_number')
          localStorage.removeItem('password')
        }
        if(this.typeOfAuth=='password') {
          delete this.signinData.otp
        } else if(this.typeOfAuth == 'OTP') {
          delete this.signinData.password
        }
        this.fetchJWT({formData: this.signinData})
        .then(() => {
          this.formValid = true
          this.showError = false
          // this.$router.push({path: '/'})
          location.reload()
        })
        .catch(() => {
          this.formValid = false
          this.showError = true
        })
      }
    },
    reset() {
      if(this.typeOfAuth=="password") {
        this.signinData.password = ''
      } else {
        this.signinData.otp = ''
      }
      this.signinData.mobile_number = ''
      this.$v.$reset()
    },
    forgotPassword() {
      console.log('clicked')
    },
    changeAuthType(authType) {
      this.$v.signinData.$reset()
      if(authType=='password') {
        this.sendOTPDialog = false
      } else if(authType == 'OTP') {
        this.sendOTPDialog = true
      }
      // this.$v.$reset()
    },
    sendOTP() {
      if(this.signinData.password) {
        delete this.signinData.password
      }
      this.signinData.otp_type = 'signup'
      this.SendOTPUser(this.signinData).then(() => {
        this.sendOTPDialog = false
        this.autofocus = true
      })
    },
    isValidRegistration() {
      this.$v.society_registration_number.$touch()
      if(!this.$v.society_registration_number.$invalid) {
        this.checkRegistrationValidity({registration: {society_registration_number: this.society_registration_number}}).then((response) => {
          if(response.data.status) {
            if(response.data.isValid) {
              this.$router.push(`/signup/${this.society_registration_number}`)
            } else {
              this.invalidID = true
            }
          } else {
            this.invalidID = true
          }
          this.societyForm = true
        }).catch(() => {
          this.societyForm = false
        })
      }
    },
  },
  mounted() {
    if(localStorage.getItem('mobile_number') && localStorage.getItem('password')) {
      this.rememberMe = true
    } else {
      this.rememberMe = false
    }
  }
}
</script>

<style scoped>
.signin {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.admin-signin-btn {
  float: right;
}
.validation-text {
  position: relative;
  bottom: 20px;
}
.form-col {
  margin-top: -35px !important;
}
  
.line {
  border: 1px solid #62608b !important;
  background: #62608b;
}

.icon {
  width: 12%;
  display: block;

  margin: auto;
  position: relative;
  top:-50% !important;
}

.text-underline-btn:hover {
  text-decoration: none !important;
}

.error-span{
  margin-bottom:-18px !important;
}
</style>
