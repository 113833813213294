<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-simple-table class="table text-center">
          <template>
            <thead>
              <tr>
                <th class="text-center">Society Name</th>
                <th class="text-center">Society ID</th>
                <th class="text-center">Joining Date</th>
                <th class="text-center">Contact Number</th>
                <th class="text-center">Payment Period</th>
                <th class="text-center">Total Users</th>
                <th class="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(society, index) in societyMonthlyFeesData.data"
                :key="index"
              >
                <td>{{ society.society.society_name }}</td>
                <td>{{ society.society.registration_number }}</td>
                <td>{{ getDate(society.society.date_of_join) }}</td>
                <td>{{ society.society.mobile_number }}</td>
                <td>
                  {{ getMonthString(society.payment_month)
                  }}{{ society.payment_year }}
                </td>
                <td>{{ society.users }}</td>
                <td>{{ society.amount }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total Amount</td>
                <td>{{ societyMonthlyFeesData.total_amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-card elevation="0">
      <v-row class="mt-2" justify="start" align="start">
        <v-col cols="9">
          <v-card elevation="0">
            <v-card-text
              class="
                text-subtitle-1 text-justify
                d-flex
                justify-start
                px-2
                py-2
              "
              style="background: #fff0e1"
            >
              You can transfer the money with the following modes of payment
              below.<br />
              Once you transfer the money, please click the button "Payment
              Done".<br />
              This will notify the Admin that you have made the payment.<br />
              It is advised that you should retain the receipt or proof if
              payemnt in case of any disputes.
            </v-card-text>
            <v-card-text
              class="text-subtitle-1 font-weight-bold pl-0 ml-0"
              style="color: #ca4627"
            >
              Please note that your payment will reflect only after
              approval by Admin
            </v-card-text>
            <v-card-text class="text-h5 font-weight-bold pl-0 ml-0 pt-0">
              Amount you are paying: INR {{ societyMonthlyFeesData.total_amount }}
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                Card Payment
              </span><br>
              <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                color="primary" @click="createOrder" plain :ripple="false">
                Click Here
              </v-btn>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                Netbanking
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 text-subtitle-2 pt-0">
              <div>
                Benificiary Name:
                <span class="font-weight-black">{{
                  fees.beneficiary_name
                }}</span>
              </div>
              <div>
                Bank Name:
                <span class="font-weight-black">{{ fees.bank_name }}</span>
              </div>
              <div>
                Account Number:
                <span class="font-weight-black">{{
                  fees.account_number
                }}</span>
              </div>
              <div>
                Account Type:
                <span class="font-weight-black">{{
                  fees.account_type
                }}</span>
              </div>
              <div>
                IFSC Code:
                <span class="font-weight-black">{{ fees.ifsc_code }}</span>
              </div>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <v-row>
                <v-col cols="6">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                UPI
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 text-subtitle-2 pt-0">
              <div>
                UPI ID:
                <span class="font-weight-black">{{ fees.upi_id }}</span>
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
              <div>
                Mobile Number:
                <span class="font-weight-black">{{fees.mobile_number}}</span>
              </div>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <v-row>
                <v-col cols="6">
                  <div class="divider">OR</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pl-0 ml-0">
              <span
                class="text-uppercase font-weight-medium px-4 py-2"
                style="background: #1977d1; color: white"
              >
                Google Pay
              </span>
            </v-card-text>
            <v-card-text class="pl-0 ml-0 pt-0">
              <v-img
                v-if="fees.qr_code"
                :src="fees.qr_code"
                width="14%"
              ></v-img>
            </v-card-text>

            <v-card-text class="pl-0 ml-0">
              <v-btn
                :disabled="!societyMonthlyFeesData.status"
                class="white--text rounded mr-2"
                color="#67AC5B"
                @click="requestPayment"
              >
                Payment Done
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="showStatusOfPayment" width="500" persistent>
      <v-card
        justify="center"
        align="center"
        class="rounded-lg"
        style="padding-top: 40px; padding-bottom: 40px"
      >
        <v-card-text
          class="d-flex justify-center align-center px-0 py-0"
          style="
            border-radius: 50%;
            border: 4px solid #f1f7ec;
            width: 120px;
            height: 120px;
            margin-bottom: 25px;
          "
        >
          <v-icon color="error" style="font-size: 80px"> mdi-check </v-icon>
        </v-card-text>
        <v-card-text>
          <span dense class="text-h4 font-weight-bold" text type="success">
            Payment request is made succesfully.
          </span>
          <br />
          <v-btn
            class="white--text text-uppercase mx-2 btn-width"
            color="#423D3D"
            @click="closePopup"
            >Cancel</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay v-model="overlays">
      <v-progress-circular
        indeterminate
        color="white"
        width="5"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import PaymentMixins from '@/mixins/payment.js';
export default {
  mixins: [
    PaymentMixins
  ],
  data: () => ({
    overlays: false,
    showStatusOfPayment: false
  }),
  computed: {
    ...mapGetters("fees", {
      societyMonthlyFeesData: "getSocietyMonthlyFeesData",
    }),
    ...mapGetters("settings", {
      fees: "getSocietyFees",
    }),
  },
  methods: {
    ...mapActions("fees", [
      "fetchSocietyMonthlyFeesData",
      "societyPaymentRequest"
    ]),
    ...mapActions("settings", [
      "fetchSocietyFees"
    ]),
    ...mapActions("payment", [
      "monthlyPaymentInitiate",
      "monthlyPaymentHandle"
    ]),
    ...mapMutations("payment", [
      "setPayment"
    ]),
    getDate(date) {
      return `${String(new Date(date).getDate()).padStart(2, "0")}/${String(
        new Date(date).getMonth() + 1
      ).padStart(2, "0")}/${new Date(date).getFullYear()}`;
    },
    getMonthString(month) {
      const today = new Date(2020, month - 1, 1);
      //return today.toLocaleString('default', { month: 'long' })
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      return ` ${monthNames[today.getMonth()]} `;
    },
    requestPayment() {
      this.overlays = true;
      this.societyPaymentRequest().then(() => {
        console.log("payment requested");
        this.showStatusOfPayment = true;
      }).finally(() => {
        this.overlays = false;
      });
    },
    closePopup() {
      this.showStatusOfPayment = false;
      this.$router.push("/account/fees");
    },
    createOrder() {
      this.monthlyPaymentInitiate({'amount': this.societyMonthlyFeesData.total_amount}).then((response) => {
        this.setPayment({
          amount: response.data.order.amount,
          tax: response.data.order.tax,
          order_id: response.data.payment.id,
          cust_name: response.data.cust_name,
          cust_email: response.data.cust_email,
          cust_contact: response.data.cust_contact
        })
        this.makePaymentCall(response.data.payment.amount, response.data.payment.id, response.data.cust_name, response.data.cust_email, response.data.cust_contact)
      })
    },
    async makePaymentCall(amount, order_id, cust_name, cust_email, cust_contact) {
      const result = await this.loadRazorPay()
      if(!result){
        alert('Failed to load razorpay script')
      return
      }
      let optionsThis = this
      const options = {
        key: process.env.VUE_APP_RAZORPAY_ID,
        amount: amount,
        currency: `INR`,
        name: 'National Society',
        description: `Society Monthly Fees`,
        order_id: order_id,
        // image: `Your business logo`,
        handler: function(response){
          // It is function executed on success
          optionsThis.monthlyPaymentHandle({'response': response}).then(() => {
            optionsThis.$router.push('/account/residents')
          })
        },
        prefill: {
          name: cust_name,
          email: cust_email,
          contact: cust_contact
        },
        modal: {
          confirm_close: true,
          animation: true,
          handleback: true,
          escape: true,
          backdropclose: false
        }
      }; 
      const paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', (response) => {
        console.log('failed response', response)
      })
      paymentObject.open();
    },
  },
  mounted() {
    this.fetchSocietyMonthlyFeesData().then(() => {
      console.log('****', this.societyMonthlyFeesData)
    })
    this.fetchSocietyFees()
  },
};
</script>
<style scoped>
</style>