<template>
  <v-container class="security-settings ">
    <v-row class="py-5" justify="center" align="center">
      <searchBar label="Securtity" :value="securities.count" @searching="searchQuery=$event"></searchBar>
      <v-col cols="12" class="d-flex justify-start pl-0" style="">
        <v-btn-toggle v-model="toggle_exclusive" color="#1976D2" active-class="active-btn">
          <v-btn :value="1" class="mr-2" color="#d0d0d0">All Securities</v-btn>
          <v-btn :value="2" class="mr-2 " color="#d0d0d0" @click="$router.push('/add-security')">
            <v-icon size="inherit" class="mx-1">mdi-plus</v-icon>Add Security
          </v-btn>
          <v-btn :value="3" class="mr-2" color="#d0d0d0" @click="setTime = true">Add Timings
          </v-btn>
          <v-btn :value="4" class="mr-2" color="#d0d0d0" @click="viewTime=true">View Securities
            Timetable</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="py-5 pt-0 mt-0" justify="center">
      <v-col cols="12">
        <v-simple-table class="table text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center font-weight-bold">Full Name</th>
                <th class="text-center font-weight-bold">Father Name</th>
                <th class="text-center font-weight-bold">Joining Date</th>
                <th class="text-center font-weight-bold">Contact</th>
                <th class="text-center font-weight-bold">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(security, index) in filteredSecurity" :key="index">
                <td>
                  <span v-if="security.full_name.length>10">
                    {{security.full_name.slice(0,10)+'...'}}
                  </span>
                  <span v-else>
                    {{security.full_name}}
                  </span>

                </td>
                <td>
                  <span v-if="security.father_name.length>10">
                    {{security.father_name.slice(0,10)+'...'}}
                  </span>
                  <span v-else>
                    {{security.father_name}}
                  </span>
                </td>
                <td>{{new Date(security.date_of_joining).toDateString()}}</td>
                <td>{{security.mobile_number}}</td>
                <td>
                  <v-row justify="center" no-gutters>
                    <v-col lg="3" xl="2" class="d-flex justify-center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-caption text-subtitle-1 font-weight-medium" tile
                            color="black" @click="viewSecurityTimings(security)" v-bind="attrs" v-on="on">Timings
                          </v-btn>
                        </template>
                        <span>Timings</span>
                      </v-tooltip>
                    </v-col>
                    <v-col lg="3" xl="2" class="d-flex justify-center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-caption text-subtitle-1 font-weight-medium" tile
                            color="black" @click="editSecurityDetails(security)" v-bind="attrs" v-on="on">Edit</v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-col>
                    <v-col lg="4" xl="3" v-if="security.status==='deactive'" class="d-flex justify-center">
                      <v-tooltip top color="green">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-caption text-subtitle-1 font-weight-medium" tile
                            color="green" @click="deactivateStatus(security)" v-bind="attrs" v-on="on">Approve</v-btn>
                        </template>
                        <span>Approve</span>
                      </v-tooltip>
                    </v-col>
                    <v-col lg="4" xl="3" v-else class="d-flex justify-center">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-caption text-subtitle-1 font-weight-medium" tile
                            color="red" @click="activateStatus(security)" v-bind="attrs" v-on="on">Deactivate</v-btn>
                        </template>
                        <span>Deactivate</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="securities.data.length>0" class="mb-3">
      <v-pagination
        v-model="page"
        :length="Math.ceil(securities.count/10)"
        :total-visible="7"
        color="primary"
        @input="fetchSecuritiesWithPage($event)"
        @previous="fetchSecuritiesWithUrl(securities.previous)"
        @next="fetchSecuritiesWithUrl(securities.next)"
      ></v-pagination>
    </v-row>
    <v-dialog v-model="setTime" width="80%">
      <SetTime @time_added="setTimeSuccess" @cancel-set-time="cancelAddTimings"></SetTime>
    </v-dialog>
    <v-dialog v-model="viewTime" width="80%">
      <ViewTime :secutitiesTime="secutitiesTime" @time-updated="fetchSecuritiesTime" :allTime="viewTime"></ViewTime>
    </v-dialog>
    <v-dialog v-model="showSecurityTime" width="80%">
      <ViewTime :secutitiesTime="securityTime" :allTime="viewTime"></ViewTime>
    </v-dialog>
    <v-dialog v-model="editSecurity" width="70%">
      <EditSecurity @cancel-edit="closeEditDialog" @error-edit="errorEditDialog" :security="security"></EditSecurity>
    </v-dialog>

    <v-dialog v-model="statusPopup" width="500" persistent>
      <v-card justify="center" align="center" class="" rounded style="padding-top:40px;padding-bottom:40px">
        <v-card-text class="d-flex justify-center align-center px-0 py-0"
          style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
          <v-icon color="#abd193" style="font-size:80px">
            mdi-check
          </v-icon>
        </v-card-text>
        <v-card-text>


          <span v-if="editSuccess" class="text-h4 font-weight-bold">Security details updated successfully.</span>
          <span v-if="editError" class="text-h4 font-weight-bold">Security details updated unsuccessful.</span>
          <span v-if="timeAdded" class="text-h4 font-weight-bold">Security time added successfully.</span>
          <br>
          <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
            @click="statusPopup=false">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import SetTime from '@/components/SetTime.vue'
  import ViewTime from '@/components/ViewTime.vue'
  import EditSecurity from '@/components/EditSecurity.vue'
  import searchBar from '@/components/searchBar'
  export default {
    metaInfo: {
      title: 'Security Settings'
    },
    components: {
      SetTime,
      ViewTime,
      EditSecurity,
      searchBar
    },
    data: () => ({
      searchQuery: '',
      disable: false,
      setTime: false,
      viewTime: false,
      editSecurity: false,
      statusPopup: false,
      editSuccess: false,
      editError: false,
      showSecurityTime: false,
      timeAdded: false,
      page: 1,
      securityPerPage: 10,
      securityItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ],
      toggle_exclusive: 1,
    }),
    watch: {
      viewTime() {
        if(!this.viewTime) {
          this.toggle_exclusive = 1
        }
      },
      setTime() {
        if(!this.setTime) {
          this.cancelAddTimings()
        }
      }
    },
    computed: {
      ...mapGetters('security', {
        securities: 'getSecurites',
        secutitiesTime: 'getSecuritesTime',
        security: 'getSecurity',
        securityTime: 'getSecurityTime'
      }),
      filteredSecurity() {
        if (this.searchQuery) {
          return this.securities.data.filter((security) => {
            return security.full_name.toLowerCase().startsWith(this.searchQuery.toLowerCase()) || security
              .mobile_number.toLowerCase().startsWith(this.searchQuery.toLowerCase()) || security.father_name
              .toLowerCase().startsWith(this.searchQuery.toLowerCase())
          })
        } else {
          return this.securities.data
        }
      },
      // currentPageSecurities() {
      //   return this.securityPerPage != null ? this.filteredSecurity.slice((this.page - 1) * this.securityPerPage, (this
      //     .page - 1) * this.securityPerPage + this.securityPerPage) : this.filteredSecurity
      // }
    },
    methods: {
      ...mapActions('security', [
        'fetchSecurities',
        'fetchSecuritiesWithPage',
        'fetchSecuritiesWithUrl',
        'fetchSecurity',
        'updateStatus',
        'fetchSecuritiesTime',
        'fetchSecurityTime'
      ]),
      deactivateStatus(security) {
        this.disable = true
        this.updateStatus({
          status: 'deactive',
          security_id: security.id
        }).then(() => {
          this.disable = false
        })
      },
      activateStatus(security) {
        this.disable = true
        this.updateStatus({
          status: 'active',
          security_id: security.id
        }).then(() => {
          this.disable = false
        })
      },
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      editSecurityDetails(security) {
        this.fetchSecurity({
          id: security.id
        }).then(() => {
          this.editSecurity = true
        })
      },
      closeEditDialog() {
        this.editSecurity = false
        this.editError = false
        this.timeAdded = false
        this.editSuccess = true
        this.statusPopup = true
      },
      errorEditDialog() {
        this.editSecurity = false
        this.editSuccess = false
        this.timeAdded = false
        this.editError = true
        this.statusPopup = true
      },
      setTimeSuccess() {
        this.setTime = false
        this.editSecurity = false
        this.editSuccess = false
        this.editError = false
        this.timeAdded = true
        this.statusPopup = true
      },
      viewSecurityTimings(security) {
        this.fetchSecurityTime(security.id).then(() => {
          this.showSecurityTime = true
        })
      },
      cancelAddTimings() {
        this.setTime = false
        this.toggle_exclusive = 1
      }
    },
    mounted() {
      this.fetchSecurities()
      this.fetchSecuritiesTime()
    }
  }
</script>
<style scoped>
  .table th {
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .table td {
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .table-btn:hover {
    text-decoration: underline;
  }

  .table-btn {
    font-size: 13px !important;
  }

  .active-btn {
    background-color: #1976D2 !important;
    color: white !important;
  }
</style>