<template>
  <v-container class="">
    <v-row class="py-5" justify="center" align="center">
      <searchBar label="Active Notice" :value="notices.count" @searching="searchQuery=$event"></searchBar>
      <v-col cols="12" class="d-flex justify-end pr-0">
        <v-btn v-if="isAdmin" class="white--text rounded mr-0" color="#67ac5b" @click="$router.push('/addnotice')">
          <v-icon class="mx-1">mdi-plus</v-icon>Add Notice
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-3 pl-0 ml-0">
      <v-col cols="12" class="ma-0 pl-0">
        <v-card class="mb-3" elevation="0" v-for="(info, index) in filteredInformations" :key="index">
          <v-row class="pr-3 " align="start">
            <v-col class="pa-0 ma-0" v-if="info.title || info.description">
              <v-row class="ma-0 pa-0">
                <v-card-title v-text="info.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                  style="font-size:18px !important;">
                </v-card-title>

                <v-card-text class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                  <span class="text-decoration-underline text-capitalize font-italic"></span>
                  <span class="font-italic">{{getDate(info.added)}}</span>
                </v-card-text>

                <v-card-text class="text-subtitle-1 font-weight-regular px-0 py-2">
                  <span>{{info.description.slice(0, 100)}}...</span>
                  <a @click="newsClicked(info)">{{linkText}}</a>
                </v-card-text>

              </v-row>
            </v-col>

          </v-row>

          <v-row class="d-flex justify-end mt-4">
            <v-btn small class="mr-2 pl-6 pr-6 black--text " color="#d0d0d0" @click="editData(info)"
              :disabled="disabledButtons">
              Edit
            </v-btn>
            <v-btn small class="mr-3 white--text " color="#d63928" @click="deleteSelectedInformation(info)"
              :disabled="disabledButtons">
              Delete
            </v-btn>
          </v-row>

          <v-row>
            <v-divider class="mt-5 mb-3"></v-divider>
          </v-row>

        </v-card>

        <v-row justify="center" align="center" v-if="notices.data.length>0">

          <v-pagination
            v-model="page"
            :length="Math.ceil(notices.count/10)"
            :total-visible="7"
            color="primary"
            @input="fetchNoticesWithPage($event)"
            @previous="fetchNoticesWithUrl(notices.previous)"
            @next="fetchNoticesWithUrl(notices.next)"
          ></v-pagination>
        </v-row>
      </v-col>

    </v-row>
    <v-dialog v-model="editInformation" width="70%" persistent>
      <EditNews @cancel-edit="editInformation=false"></EditNews>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600" persistent>
      <v-card class="rounded-lg">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>Do you want to delete this notice?</v-card-title>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="green--text" @click="confirmDelete">Yes</v-btn>
                <v-btn class="red--text" @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import EditNews from '@/components/EditNews.vue'
  import searchBar from '@/components/searchBar';
  export default {
    title: () => ('News Settings'),
    metaInfo: {
      title: 'Notice Settings for National Society'
    },
    components: {
      EditNews,
      searchBar
    },
    data: () => ({
      showMore: false,
      linkText: 'View full notice',
      searchQuery: '',
      overlay: false,
      editInformation: false,
      deleteDialog: false,
      disabledButtons: false,
      page: 1,
      newsPerPage: 10,
      newsItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ]
    }),
    computed: {
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin'
      }),
      ...mapGetters('event', {
        notices: 'getNotices',
        info: 'getNotice'
      }),
      filteredInformations() {
        if (this.searchQuery) {
          return this.notices.data.filter((info) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return info.title.toLowerCase().includes(search) || info.description.toLowerCase().includes(
                search)
            })
          })
        } else {
          return this.notices.data
        }
      },
      // currentPageNews() {
      //   return this.newsPerPage != null ? this.filteredInformations.slice((this.page - 1) * this.newsPerPage, (this
      //     .page - 1) * this.newsPerPage + this.newsPerPage) : this.filteredInformations
      // }
    },
    methods: {
      ...mapActions('event', [
        'fetchNotices',
        'deleteNotice',
        'fetchNoticesWithPage',
        'fetchNoticesWithUrl'
      ]),
      ...mapMutations('event', [
        'setNotice',
        'setNoticeProperty'
      ]),
      newsClicked(item) {
        this.setNotice(item)
        this.$router.push(`/notice/${item.title.replace(/\s/g, '-')}-${item.id}/`)
      },
      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'View full notice'
        }
      },
      editData(info) {
        this.setNotice(info)
        this.editInformation = true
      },
      confirmDelete() {
        this.deleteNotice({
          event_id: this.selectedInformation.id
        }).then(() => {
          this.fetchNotices()
          this.deleteDialog = false
          this.disabledButtons = false
        })
      },
      cancelDelete() {
        this.deleteDialog = false
        this.disabledButtons = false
      },
      deleteSelectedInformation(event) {
        this.selectedInformation = event
        this.deleteDialog = true
        this.disabledButtons = true
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      }
    },
    mounted() {
      this.overlay = true
      this.fetchNotices().then(() => {
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .notice-buttons {
    position: relative;
    margin: auto;
  }

  .text-card {
    overflow-y: scroll;
    padding: 0;
  }

  .row+.row {
    margin-top: 0;
  }
</style>