<template>
    <v-container>
        <v-row>
            <v-col align="center" cols="12">
                <h2 class="text-decoration-underline">Society Member List</h2>
            </v-col>
            <v-col cols="12"  class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
                        <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.back()" :ripple="false">
                    Back
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-simple-table class="table text-center">
                    <template>
                        <thead>
                            <tr>
                                <th class="text-center">Memeber Name</th>
                                <th class="text-center">Joining Date</th>
                                <th class="text-center">Contact Number</th>
                                <th class="text-center">Memeber Email ID</th>
                                <th class="text-center">Memeber Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in users.data" :key="index">
                                <td>{{user.full_name}}</td>
                                <td>{{getDate(user.date_joined)}}</td>
                                <td>{{user.mobile_number}}</td>
                                <td>{{user.email}}</td>
                                <td :class="user.is_active?'green--text':'error--text'">{{user.is_active?'Active':'Deactive'}}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" no-gutters v-if="users.data.length>0">
          <v-pagination
            v-model="page"
            :length="Math.ceil(users.count/10)"
            :total-visible="7"
            color="primary"
            @input="inputFetch($event, society_id)"
            @previous="fetchUsersBySocietyIdWithUrl(users.previous)"
            @next="fetchUsersBySocietyIdWithUrl(users.next)"
          ></v-pagination>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
    export default {
        data: () => ({
            page: 1
        }),
        computed: {
            ...mapGetters('user', {
                users: 'getUsers'
            }),
            society_id() {
                return this.$route.params.society_id.split('-')[this.$route.params.society_id.split('-').length - 1]
            }
        },
        methods: {
            ...mapActions('user', [
                'fetchUsersBySocietyId',
                'fetchUsersBySocietyIdWithPage',
                'fetchUsersBySocietyIdWithUrl'
            ]),
            getDate(date) {
                return `${String(new Date(date).getDate()).padStart(2,'0')}/${String(new Date(date).getMonth()+1).padStart(2,'0')}/${new Date(date).getFullYear()}`
            },
            inputFetch(page, society_id) {
                this.fetchUsersBySocietyIdWithPage({page, society_id})
            }
        },
        mounted() {
            this.fetchUsersBySocietyId(this.society_id).then(() => {
                console.log('users', this.users)
            })
        }
    }
</script>

<style scoped>
    .table thead th{
        font-size:18px !important;
    }
</style>