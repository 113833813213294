var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{},[_c('v-row',{staticClass:"py-5",attrs:{"justify":"center","align":"center"}},[_c('searchBar',{attrs:{"label":"Residents","value":_vm.users.count},on:{"searching":function($event){_vm.searchQuery=$event}}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"table text-center",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center font-weight-bold"},[_vm._v("Full Name")]),_c('th',{staticClass:"text-center font-weight-bold"},[_vm._v("Contact")]),_c('th',{staticClass:"text-center font-weight-bold"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.filteredUser),function(user,index){return _c('tr',{key:index},[_c('td',[(user.full_name.length>10)?_c('span',[_vm._v(" "+_vm._s(user.full_name.slice(0,10)+'...')+" ")]):_c('span',[_vm._v(" "+_vm._s(user.full_name)+" ")])]),_c('td',[_vm._v(_vm._s(user.mobile_number))]),_c('td',{},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{staticStyle:{"width":"fit-content !important"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text rounded",staticStyle:{"min-width":"30px !important","min-height":"30px !important","width":"30px !important","height":"30px !important"},attrs:{"small":"","color":"black","to":{name: 'User', params: {id: user.id}}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("User Details")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text rounded",staticStyle:{"min-width":"30px !important","min-height":"30px !important","width":"30px !important","height":"30px !important"},attrs:{"color":"success"},on:{"click":function($event){return _vm.sendMessageToUser(user)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-text")])],1)]}}],null,true)},[_c('span',[_vm._v("Send Message")])])],1),(user.is_active)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-btn text-subtitle-2 px-0 font-weight-medium",attrs:{"x-small":"","plain":"","tile":"","color":"red"},on:{"click":function($event){return _vm.updateUserStatusProperty('deactive', user.id)}}},'v-btn',attrs,false),on),[_vm._v("Deactivate ")])]}}],null,true)},[_c('span',[_vm._v("Deactivate")])])],1):_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-btn text-subtitle-2 px-0 font-weight-medium",attrs:{"x-small":"","plain":"","tile":"","color":"green"},on:{"click":function($event){return _vm.updateUserStatusProperty('active', user.id)}}},'v-btn',attrs,false),on),[_vm._v("Approve")])]}}],null,true)},[_c('span',[_vm._v("Approve")])])],1)],1)],1)])}),0)]},proxy:true}])}),(_vm.users.data.length>0)?_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.users.count/10),"total-visible":7,"color":"primary"},on:{"input":function($event){return _vm.fetchUsersWithPage($event)},"previous":function($event){return _vm.fetchUsersWithUrl(_vm.users.previous)},"next":function($event){return _vm.fetchUsersWithUrl(_vm.users.next)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"50%","persistent":""},model:{value:(_vm.showMessageBox),callback:function ($$v) {_vm.showMessageBox=$$v},expression:"showMessageBox"}},[_c('MessageBox',{attrs:{"user":_vm.selectedUser},on:{"cancel-message":_vm.closeMessageBox}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }