<template>
  <v-container class="">
    <v-row class="py-5" justify="center" align="center">
      <searchBar label="New Events" :value="events.count" @searching="searchQuery=$event"></searchBar>
      <v-col cols="12" class="d-flex justify-end pr-0">
        <v-btn class="mr-0 rounded white--text" v-if="isAdmin" color="#67ac5b" @click="$router.push('/addevent')">
          <v-icon class="mx-1">mdi-plus</v-icon>Add New Event
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12" class="ml-0 pl-0 pt-0 mt-0">
        <v-card class="mb-3 ml-0 pl-0" elevation="0" v-for="(info, index) in filteredEvents" :key="index">
          <v-row class="pr-3 ml-0 pl-0" align="center">
            <v-col class="pa-0 ma-0" v-if="info.title || info.description">
              <v-row class="pa-0 ma-0">
                <v-card-title v-text="info.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                  style="font-size:18px !important;">
                </v-card-title>
                <v-card-text class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                  <span class="text-decoration-underline text-capitalize font-italic"></span>
                  <span class="font-italic">{{getDate(info.added)}}</span>
                </v-card-text>
                <v-card-text class="text-subtitle-1 font-weight-regular px-0 py-2">
                  <span>{{info.description.slice(0, 100)}}...</span>
                  <a @click="eventSelected(info)">{{linkText}}</a>
                </v-card-text>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end mt-4">
            <v-col cols="12" class="d-flex justify-end mr-0 pr-0">
              <v-btn small class="mr-2 pl-6 pr-6 black--text " color="#d0d0d0" @click="editData(info)"
                :disabled="disabledButtons">
                Edit
              </v-btn>
              <v-btn small class="white--text " color="#d63928" @click="deleteSelectedEvent(info)"
                :disabled="disabledButtons">
                Delete
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-0 my-0 mr-0 pr-0">
               <v-divider class="mb-6"></v-divider>
            </v-col>
          </v-row>
          <!-- <v-row> -->
          <!-- </v-row> -->
        </v-card>
        <v-row justify="center" align="center" v-if="events.data.length>0">
          <v-pagination v-model="page" :length="Math.ceil(events.count/10)" :total-visible="7" color="primary"
            @input="fetchEventsWithPage($event)" @previous="fetchEventsWithUrl(events.previous)"
            @next="fetchEventsWithUrl(events.next)"></v-pagination>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="editEvent" width="70%" persistent>
      <EditEvent :eventData="selectedEvent" :title="selectedEventTitle" :event_date="selectedEventDate"
        :event_time="selectedEventTime" :time_convention="selectedEventConvention"
        :description="selectedEventDescription" :imageUrl="selectedEvent.media_file"
        @title-changed="selectedEventTitle=$event" @description-changed="selectedEventDescription=$event"
        @date-changed="selectedEventDate=$event" @time-changed="selectedEventTime=$event"
        @convention-changed="selectedEventConvention=$event" @imageUrl-changed="selectedEvent.media_file=$event"
        @cancel-edit="editEvent=false"></EditEvent>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600" persistent>
      <v-card class="rounded-lg">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>Do you want to delete this event?</v-card-title>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="green--text" @click="confirmDelete">Yes</v-btn>
                <v-btn class="red--text" @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import EditEvent from '@/components/EditEvent.vue'
  import searchBar from '@/components/searchBar'
  export default {
    title() {
      return `Event Settings`
    },
    metaInfo: {
      title: 'Event Settings'
    },
    components: {
      EditEvent,
      searchBar
    },
    data: () => ({
      showMore: false,
      linkText: 'Read full event',
      searchQuery: '',
      overlay: false,
      editEvent: false,
      selectedEvent: {},
      selectedEventTitle: '',
      selectedEventDate: '',
      selectedEventTime: '',
      selectedEventConvention: '',
      selectedEventDescription: '',
      deleteDialog: false,
      disabledButtons: false,
      page: 1,
      eventPerPage: 10,
      eventItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ]
    }),
    computed: {
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin'
      }),
      ...mapGetters('event', {
        events: 'getEvents'
      }),
      filteredEvents() {
        if (this.searchQuery) {
          return this.events.data.filter((event) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return event.title.toLowerCase().includes(search) || event.description.toLowerCase().includes(
                search)
            })
          })
        } else {
          return this.events.data
        }
      },
      // currentPageEvents() {
      //   return this.eventPerPage != null ? this.filteredEvents.slice((this.page - 1) * this.eventPerPage, (this.page -
      //     1) * this.eventPerPage + this.eventPerPage) : this.filteredEvents
      // }
    },
    methods: {
      ...mapActions('event', [
        'fetchEvents',
        'deleteEvents',
        'fetchEventsWithPage',
        'fetchEventsWithUrl',
      ]),

      ...mapMutations('event', [
        'setEvent'
      ]),
      eventSelected(event) {
        this.setEvent(event)
        this.$router.push(`/event/${event.title.replace(/\s/g, '-')}-${event.id}/`)
      },

      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'Read full event'
        }
      },
      editData(event) {
        this.selectedEvent = event
        this.selectedEventTitle = event.title
        this.selectedEventDate = event.event_date
        this.selectedEventTime = event.event_time
        this.selectedEventDescription = event.description
        this.selectedEventConvention = event.time_convention
        this.editEvent = true
      },
      confirmDelete() {
        this.deleteEvents({
          event_id: this.selectedEvent.id
        }).then(() => {
          this.fetchEvents()
          this.deleteDialog = false
          this.disabledButtons = false
        })
      },
      cancelDelete() {
        this.deleteDialog = false
        this.disabledButtons = false
      },
      deleteSelectedEvent(event) {
        this.selectedEvent = event
        this.deleteDialog = true
        this.disabledButtons = true
      },

      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      }

    },
    mounted() {
      this.overlay = true
      this.fetchEvents().then(() => {
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .event-buttons {
    position: relative;
    margin: auto;
  }

  .text-card {
    overflow-y: scroll;
    padding: 0;
  }
</style>