<template>
  <v-container>
    <v-row no-gutters class="flex-column">
      <span
        class="
          text-h5
          font-weight-bold
          text-uppercase
          d-flex
          justify-start
          px-0
        "
        style="color: #62608b"
        >Setup Payment of fees
      </span>
      <span>How do you want the societies to pay the fees?</span>

      <v-checkbox
        v-model="enableNetbank"
        label="Enable Netbanking"
        @click="
          updateContactsProperty('enable_netbank', !!settings.enable_netbank)
        "
      ></v-checkbox>
      <v-expand-transition class="ma-0 pa-0 mb-0 pb-0">
        <v-card v-if="settings.enable_netbank" width="100%" elevation="0">
          <span
            class="pl-8 text-subtitle-1 font-weight-light"
            style="word-break: keep-all"
          >
            Enter the details of Bank Account where money should be transferred
            by the society members.
          </span>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                Account Type</v-col
              >
              <v-col style="max-width: 20%; min-width: 200px">
                <v-select
                  :items="account_type"
                  :value="settings.account_type"
                  label="Account Type"
                  outlined
                  dense
                  @input="updateContactsProperty('account_type', $event)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                Beneficiary Name</v-col
              >
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.beneficiary_name"
                  label="Beneficiary Name"
                  outlined
                  dense
                  @input="updateContactsProperty('beneficiary_name', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                Bank Name</v-col
              >
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.bank_name"
                  label="Bank Name"
                  outlined
                  dense
                  @input="updateContactsProperty('bank_name', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                Account Number</v-col
              >
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.account_number"
                  label="Account Number"
                  outlined
                  dense
                  @input="updateContactsProperty('account_number', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end"
                >IFSC Code</v-col
              >
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.ifsc_code"
                  label="IFSC Code"
                  outlined
                  dense
                  @input="updateContactsProperty('ifsc_code', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
      <v-checkbox
        v-model="enableUpi"
        label="Enable UPI"
        @click="updateContactsProperty('enable_upi', !!settings.enable_upi)"
      ></v-checkbox>
      <v-expand-transition class="ma-0 pa-0">
        <v-card v-if="settings.enable_upi" width="100%" elevation="0">
          <span
            class="pl-8 text-subtitle-1 font-weight-light"
            style="word-break: keep-all"
          >
            Enter the UPI ID or Mobile Number linked to your Bank Account
          </span>

          <v-card-text>
            <v-row no-gutters classs="mb-0 pb-0">
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                UPI ID:
              </v-col>
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.upi_id"
                  label="UPI ID"
                  outlined
                  dense
                  @input="updateContactsProperty('upi_id', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end"></v-col>
              <v-col style="max-width: 40%; min-width: 300px">
                <div class="divider">OR</div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2" class="mr-4 pt-2 d-flex justify-end">
                Mobile Number:
              </v-col>
              <v-col class="" style="max-width: 40%; min-width: 300px">
                <v-text-field
                  :value="settings.mobile_number"
                  label="Mobile Number"
                  outlined
                  dense
                  @input="updateContactsProperty('mobile_number', $event)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
      <v-checkbox
        v-model="enableGooglepay"
        label="Enable Google Pay"
        @click="
          updateContactsProperty(
            'enable_googlepay',
            !!settings.enable_googlepay
          )
        "
      ></v-checkbox>
      <v-expand-transition class="ma-0 pa-0">
        <v-card v-if="settings.enable_googlepay" width="100%" elevation="0">
          <span
            class="pl-8 text-subtitle-1 font-weight-light"
            style="word-break: keep-all"
          >
            Upload your Google Pay QR Code Image, so that society members can
            scan the code and make the payment
          </span>
          <v-card-text>
            <v-row no-gutters>
              <v-col class="pl-4" cols="12">
                <div v-if="typeCheck" class="red--text">
                  File must be the following format(jpg, jpeg, png, bmp, gif).
                </div>
                <div v-else>
                  <v-img
                    v-if="qrcodeUrl"
                    class="pt-2 pb-2"
                    :src="qrcodeUrl"
                    width="14%"
                    @click="selectImage('qr-code')"
                  ></v-img>
                  <v-img
                    v-else
                    class="pt-2 pb-2"
                    :src="qrcode"
                    width="14%"
                    @click="selectImage('qr-code')"
                  ></v-img>
                </div>
                <v-btn
                  class="white--text rounded mr-2"
                  color="primary"
                  @click="selectImage('qr-code')"
                >
                  Upload QR Code Image
                </v-btn>
                <v-file-input
                  id="qr-code"
                  @change="onAddPhoto"
                  truncate-length="15"
                  style="display: none"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>
<script>
import { requiredIf, numeric, requiredUnless } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
const phoneNumber = helpers.regex(
  "phoneNumber",
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
export default {
  props: ["settings"],
  data: () => ({
    expand_netbank: false,
    expand_upi: false,
    expand_googlepay: false,
    enable_netbank: false,
    enable_upi: false,
    enable_googlepay: false,
    account_type: ["Current", "Saving"],
    qrcode: "",
    typeCheck: false,
    netbank_details: {
      account_type: "",
      beneficiary_name: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
    },
    upi_details: {
      upi_id: "",
      mobile_number: "",
    },
    googlepay_details: {
      qr_code: undefined,
    },
  }),
  computed: {
    enableNetbank: {
      get() {
        return this.settings.enable_netbank;
      },
      set(value) {
        this.setSocietyFeesProperty({
          property: "enable_netbank",
          value,
        });
      },
    },
    enableUpi: {
      get() {
        return this.settings.enable_upi;
      },
      set(value) {
        this.setSocietyFeesProperty({
          property: "enable_upi",
          value,
        });
      },
    },
    enableGooglepay: {
      get() {
        return this.settings.enable_googlepay;
      },
      set(value) {
        this.setSocietyFeesProperty({
          property: "enable_googlepay",
          value,
        });
      },
    },
    qrcodeUrl() {
      if( this.settings.qr_code) {
        return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}${this.settings.qr_code}`
      }
      return ''
    }
  },
  validations() {
    return {
      netbank_details: {
        account_type: {
          required: requiredIf(() => {
            return this.enable_netbank;
          }),
        },
        beneficiary_name: {
          required: requiredIf(() => {
            return this.enable_netbank;
          }),
        },
        bank_name: {
          required: requiredIf(() => {
            return this.enable_netbank;
          }),
        },
        account_number: {
          required: requiredIf(() => {
            return this.enable_netbank;
          }),
          numeric,
        },
        ifsc_code: {
          required: requiredIf(() => {
            return this.enable_netbank;
          }),
        },
      },
      upi_details: {
        upi_id: {
          required: requiredUnless(() => {
            return this.upi_details.mobile_number === "" && this.enable_upi;
          }),
        },
        mobile_number: {
          required: requiredUnless(() => {
            return this.upi_details.upi_id === "" && this.enable_upi;
          }),
          phoneNumber,
        },
      },
      googlepay_details: {
        qr_code: {
          required: requiredIf(() => {
            return this.enable_googlepay;
          }),
        },
      },
    };
  },
  methods: {
    ...mapMutations("settings", [
      "setSocietyFeesProperty"
    ]),
    selectImage(elementId) {
      document.getElementById(elementId).click();
    },
    onAddPhoto(file) {
      const fileType = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
      if (!fileType.includes(file.type)) {
        this.typeCheck = true;
      } else {
        this.typeCheck = false;
        const reader = new FileReader();
        reader.addEventListener("load", (e) => (this.qrcode = e.target.result));
        reader.addEventListener("error", () => (this.qrcode = this.errorPhoto));
        reader.readAsDataURL(file);
        this.updateContactsProperty("qr_code", file);
      }
    },
    updateContactsProperty(property, value) {
      this.setSocietyFeesProperty({
        property,
        value,
      });
      // this.$v.changePassword[property].$touch()
    },
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: black;
  margin-top: -20px !important;
}

/* To show the lines on right 
and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border: 0.5px solid black;
  background: black;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}
</style>