<template>
  <v-container class="">
    <v-row class="py-5" justify="center" align="center">
      <searchBar :label="label" :value="complaints.count" @searching="searchQuery=$event">
      </searchBar>
      <v-col cols="12" class="d-flex justify-start pl-0" style="">
        <v-btn-toggle v-model="toggle_exclusive" color="#1976D2">
          <v-btn :value="1" class="mr-2" color="#d0d0d0" active-class="active-btn" @click="fetchComplaints">All
            Complaints</v-btn>
          <v-btn :value="2" class="mr-2 " color="#d0d0d0" active-class="active-btn" @click="fetchPendingComplaints">
            pending complaints
          </v-btn>
          <v-btn :value="3" class="mr-2" color="#d0d0d0" active-class="active-btn" @click="fetchClosedComplaints">
            closed complaints
          </v-btn>
          <v-btn :value="4" class="mr-2" color="#d0d0d0" active-class="active-btn" @click="fetchRejectedComplaints">
            rejected complaints
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row style="margin-bottom:0 !important;padding-bottom:0px !important">
      <v-col cols="12" class="ml-0 pl-0">
        <v-card class="mb-3 ml-0 pl-0" elevation="0" v-for="(complaint, index) in filteredComplaints" :key="index">

          <v-row class="ml-0 pl-0" align="start" justify="start" style="">
            <v-col class="ml-0 pl-0" v-if="complaint.title || complaint.description">

              <v-row class="ml-0 pl-0">
                <v-card-title v-text="complaint.title"
                  class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                  style="font-size:18px !important;"></v-card-title>
                <v-card-text class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                  <span class="text-decoration-underline text-capitalize font-italic">{{complaint.full_name}},</span>
                  <span class="pl-1 font-italic">{{getDate(complaint.added)}}</span>
                </v-card-text>
                <v-card-text class="text-subtitle-1 font-weight-regular text-justify px-0 py-2">
                  <span v-if="complaint.show_full_description">{{complaint.description}}...</span>
                  <span v-else>{{complaint.description.slice(0, 100)}}...</span>
                  <!-- <a @click="moreLess(index)">{{linkText(complaint)}}</a> -->
                  <a @click="complaintSelected(complaint)">{{linkText(complaint)}}</a>
                </v-card-text>
              </v-row>
            </v-col>

          </v-row>

          <v-row class="pt-4" style="margin-top:10px !important">
            <!-- <v-col cols="2"> -->
            <v-col cols="6" class="">
              <span class="text-capitalize font-italic" style="font-size:15px !important;">Comments({{complaint.complaint_comment.length}})</span>
            </v-col>
            <v-col cols="6" class="d-flex justify-end mr-0 pr-0">
              <router-link :to="{name: 'User', params: {id: complaint.user}}" tag="span" class="clickable mr-2">
                <v-btn small class="">User Details</v-btn>
              </router-link>
              <v-btn color="#67ac5b"
                v-if="(complaint.status=='new' || complaint.status=='rejected') && (complaint.solution_status!='solved')"
                :disabled="disabledButtons" @click="updateComplaintStatusProperty('approved', complaint.id)" small
                class="white--text mr-2 ">Approve</v-btn>
              <v-btn color="#ed6237"
                v-if="(complaint.status=='new' || complaint.status=='approved') && (complaint.solution_status!='solved')"
                :disabled="disabledButtons" @click="updateComplaintStatusProperty('rejected', complaint.id)" small
                class="white--text mr-2 ">Reject</v-btn>
              <v-btn color="#d0d0d0" v-if="complaint.solution_status=='unsolved'" :disabled="disabledButtons"
                @click="closeComplaint(complaint)" small class="white--text mr-2">Close</v-btn>
              <v-btn color="#d0d0d0" v-if="complaint.solution_status=='solved'" :disabled="disabledButtons"
                @click="updateComplaintSolutionStatusProperty('unsolved', complaint.id)" small
                class="white--text mr-2">Open</v-btn>
              <v-btn color="#d63928" :disabled="disabledButtons" @click="deletePost(complaint)" small
                class="white--text ">Delete</v-btn>
            </v-col>
            <!-- </v-col> -->
            <v-col cols="12" class="mt-0 pt-0 mr-0 pr-0">
               <v-divider class="mb-6"></v-divider>
            </v-col>
          </v-row>

          <!-- <v-divider class="mt-3 mb-6"></v-divider> -->

        </v-card>
        <v-row justify="center" align="center" class="pt-3 pb-3">
          <v-pagination v-model="page" :length="Math.ceil(complaints.count/10)" :total-visible="7" color="primary"
            @input="inputAction($event)" @previous="fetchAllComplaintsAndGrievancesWithUrl(complaints.previous)"
            @next="fetchAllComplaintsAndGrievancesWithUrl(complaints.next)"></v-pagination>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="600" persistent>
      <v-card>
        <v-card-title>Do you want to close this complaint?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="green--text" @click="confirmClose">Yes</v-btn>
          <v-btn small class="red--text" @click="cancelClose">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600" persistent>
      <v-card class="rounded-lg">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>Do you want to delete this complaint?</v-card-title>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="green--text" @click="confirmDelete">Yes</v-btn>
                <v-btn class="red--text" @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import searchBar from '@/components/searchBar';
  export default {
    title() {
      return `Complaint Settings`
    },
    components: {
      searchBar
    },
    metaInfo: {
      title: 'Complaint Settings'
    },
    data: () => ({
      showMore: false,
      // linkText: 'Read full complaint',
      disabledButtons: false,
      showDialog: false,
      deleteDialog: false,
      editComplaintId: new Number(),
      overlay: false,
      searchQuery: '',
      page: 1,
      complaintPerPage: 10,
      complaintItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ],
      toggle_exclusive: 1,
      label: 'New Complaints',
      tab: 1
    }),
    computed: {
      ...mapGetters('user', {
        complaints: 'getComplaints'
      }),
      filteredComplaints() {
        if (this.searchQuery) {
          return this.complaints.data.filter((complaint) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return complaint.title.toLowerCase().includes(search) || complaint.description.toLowerCase()
                .includes(search)
            })
          })
        } else {
          return this.complaints.data
        }
      }
    },
    methods: {
      ...mapActions('user', [
        'fetchAllComplaintsAndGrievances',
        'fetchAllComplaintsAndGrievancesWithPage',
        'fetchAllComplaintsAndGrievancesWithUrl',
        'updateComplaintsAndGrievancesStatus',
        'updateComplaintsAndGrievancesSolutionStatus',
        'deleteComplaintsAndGrievances',
        'fetchUserPandingComplaintsAndGrievances',
        'fetchUserPandingComplaintsAndGrievancesWithPage',
        'fetchUserClosedComplaintsAndGrievances',
        'fetchUserClosedComplaintsAndGrievancesWithPage',
        'fetchUserRejectedComplaintsAndGrievances',
        'fetchUserRejectedComplaintsAndGrievancesWithPage'
      ]),
      ...mapMutations('user', [
        'setComplaintStatusProperty',
        'setComplaintSolutionStatusProperty',
        'setComplaintsProperty',
        'setComplaint'
      ]),
      moreLess(index) {
        this.showMore = !this.showMore
        this.setComplaintsProperty({
          index: index,
          property: 'show_full_description',
          value: this.showMore
        })
      },
      linkText(complaint) {
        if (complaint.show_full_description) {
          return 'less'
        } else {
          return 'Read full complaint'
        }
      },
      updateComplaintStatusProperty(status, id) {
        this.disabledButtons = true
        this.setComplaintStatusProperty({
          status,
          id
        })
        this.updateComplaintsAndGrievancesStatus().then(() => {
          if(this.tab == 4) {
            this.fetchUserRejectedComplaintsAndGrievances()
          } else if(this.tab == 2) {
            this.fetchUserPandingComplaintsAndGrievances()
          } else {
            this.fetchAllComplaintsAndGrievances()
          }
          this.disabledButtons = false
        })
      },
      updateComplaintSolutionStatusProperty(status, id) {
        this.setComplaintSolutionStatusProperty({
          status,
          id
        })
        this.updateComplaintsAndGrievancesSolutionStatus().then(() => {
          this.fetchUserClosedComplaintsAndGrievances().then(() => {
            this.showDialog = false
            this.disabledButtons = false
          })
          // this.fetchAllComplaintsAndGrievances()
        })
      },
      deleteComplaint(id) {
        this.deleteComplaintsAndGrievances(id).then(() => {
          if(this.tab == 1) {
            this.fetchAllComplaintsAndGrievances()
          } else if(this.tab == 2) {
            this.fetchUserPandingComplaintsAndGrievances()
          } else if(this.tab == 3) {
            this.fetchUserClosedComplaintsAndGrievances()
          } else if(this.tab == 4) {
            this.fetchUserRejectedComplaintsAndGrievances()
          }
        })
      },
      deletePost(post) {
        this.editComplaintId = post.id
        this.deleteDialog = true
        this.disabledButtons = true
      },
      cancelDelete() {
        this.editComplaintId = new Number()
        this.deleteDialog = false
        this.disabledButtons = false

      },
      confirmDelete() {
        this.deleteComplaintsAndGrievances(this.editComplaintId).then(() => {
          this.fetchAllComplaintsAndGrievances()
          this.deleteDialog = false
          this.disabledButtons = false
        })
      },
      closeComplaint(complaint) {
        this.editComplaintId = complaint.id
        this.showDialog = true
        this.disabledButtons = true
      },
      cancelClose() {
        this.editComplaintId = new Number()
        this.showDialog = false
        this.disabledButtons = false
      },
      confirmClose() {
        this.updateComplaintSolutionStatusProperty('solved', this.editComplaintId)
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      },
      fetchComplaints() {
        this.page = 1
        this.tab = 1
        this.label = 'New Complaints'
        this.fetchAllComplaintsAndGrievances()
      },
      fetchPendingComplaints() {
        this.page = 1
        this.tab = 2
        this.label = 'Pending Complaints'
        this.fetchUserPandingComplaintsAndGrievances()
      },
      fetchClosedComplaints() {
        this.page = 1
        this.tab = 3
        this.label = 'Closed Complaints'
        this.fetchUserClosedComplaintsAndGrievances()
      },
      fetchRejectedComplaints() {
        this.page = 1
        this.tab = 4
        this.label = 'Rejected Complaints'
        this.fetchUserRejectedComplaintsAndGrievances()
      },
      inputAction(page) {
        if (this.toggle_exclusive == 1) {
          return this.fetchAllComplaintsAndGrievancesWithPage(page)
        } else if (this.toggle_exclusive == 4) {
          return this.fetchUserRejectedComplaintsAndGrievancesWithPage(page)
        } else if (this.toggle_exclusive == 2) {
          return this.fetchUserPandingComplaintsAndGrievancesWithPage(page)
        } else if (this.toggle_exclusive == 3) {
          return this.fetchUserClosedComplaintsAndGrievancesWithPage(page)
        } else {
          return null
        }
      },
      complaintSelected(complaint) {
        this.setComplaint(complaint)
        this.$router.push(`/complaint/${complaint.title.replace(/\s/g, '-')}-${complaint.id}/`)
      },
    },
    mounted() {
      this.overlay = true
      this.fetchAllComplaintsAndGrievances().then(() => {
        this.overlay = false
        for (let complaint in this.complaints.data) {
          this.setComplaintsProperty({
            index: complaint,
            property: 'show_full_description',
            value: false
          })
        }
      })
    }
  }
</script>
<style scoped>
  .text-card {
    overflow-y: scroll;
    padding: 0;
  }

  .row+.row {
    margin-top: 0 !important;
  }

  .btn-underline:hover {
    text-decoration: none !important;
  }

  .active-btn {
    background-color: #1976D2 !important;
    color: white !important;
  }
</style>