<template>
  <div class="mt-6">

    <v-row align="center" class="mb-3">
      <v-col cols="6" class="d-flex justify-start  mb-2 pb-0">
        <span class="font-weight-bold" style="font-size:34px !important;color:black !important">Active Discussions</span>
      </v-col>

      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0" color="primary"
          plain to="/discussions" :ripple="false">
          view all discussions
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="posts.length==0" >
      <v-col cols="12 pa-12" >

              <v-card-text class="text-h5 pa-0 text-capitalize font-weight-medium text-center font-italic "
              style="font-size:18px !important;">
                There are no discussions
              </v-card-text>
          
     
        </v-col> 
    </v-row>

    <v-row v-else align="center" class="px-3">
      <v-row align="center" v-for="(info, index) in posts" :key="index">


        <v-col class="pt-0 mt-0" cols="12">

          <div class="">
            <v-card-title v-text="info.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
              style="font-size:18px !important;">
            </v-card-title>
            <div style="">
              <v-card-text class="text-caption text-capitalize"
                style="font-size:15px !important;">
                  <span>{{info.description.slice(0, 100)}}...</span>
                  <a @click="postSelected(info)">{{linkText}}</a>
                </v-card-text>
            </div>

          </div>
        </v-col>


        <v-col cols="4" class="d-flex justify-start align-center pb-1 mb-1" >
          <span class="text-capitalize font-weight-bold" style="font-size:15px !important;color:#707070">
            Comments({{info.post_comment.length}}),
          </span>
          <span class="mx-2 font-weight-bold" style="color:#707070">
            <v-btn icon class="ma-0 pa-0" @click="likePost(info)">
              <v-icon color="#1a77d2" class="">mdi-thumb-up</v-icon> 
            </v-btn>
            {{get_like_count(info.post_response)}}
          </span>
          <span class="font-weight-bold" style="color:#707070">
            <v-btn icon class="ma-0 pa-0" @click="dislikePost(info)">
              <v-icon color="#1a77d2" class="">mdi-thumb-down</v-icon>
            </v-btn>
            {{get_dislike_count(info.post_response)}}
          </span>
        </v-col>

        <v-col cols="8" class="d-flex justify-end pb-1 mb-1">
          <span class="text-capitalize font-italic" style="font-size:15px !important;">
            Started by: {{info.full_name}} on {{getDate(info.added)}}
          </span>
        </v-col>


        <v-col class="mb-1 pb-1">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex'
  import polygon from '@/assets/Polygon.svg'
  import polygon_2 from '@/assets/Polygon-2.svg'

  export default {
    props: [
      'posts'
    ],
    data: () => ({
      showMore: false,
      linkText: 'View full discussion',
      polygon,
      polygon_2,

    }),
    computed: {
      ...mapGetters('JWT', {
        isAdmin: 'isAdmin',
        loggedIn: 'loggedIn',
        access_token: 'access_token'
      }),
    },
    methods: {
      ...mapActions('posts', [
        'likeDislike',
      ]),
      ...mapMutations('user', [
        'setPost'
      ]),
      ...mapMutations('posts', [
        'setLikeProperty'
      ]),
      getImgUrl(url) {
        return require('@/assets/' + url)
      },
      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'View full discussion'
        }
      },
      formatDate(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      postSelected(post) {
        this.setPost(post)
        this.$router.push(`/discussion/${post.title.replace(/\s/g, '-')}-${post.id}/`)
      },
      get_like_count(arr) {
        let likeCount = arr.filter((value) => {
          if(value.like) {
            return value
          }
        })
        return likeCount.length
      },
      get_dislike_count(arr) {
        let dislikeCount = arr.filter((value) => {
          if(value.dislike) {
            return value
          }
        })
        return dislikeCount.length
      },
      getDate(date) {
                return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      },
      likePost(discussion) {
        this.setLikeProperty({
          property: 'post',
          value: discussion.id
        })
        this.setLikeProperty({
          property: 'like',
          value: true
        })
        this.setLikeProperty({
          property: 'dislike',
          value: false
        })
        this.likeDislike().then(() => {
          this.$emit('liked')
        })
      },
      dislikePost(discussion) {
        this.setLikeProperty({
          property: 'post',
          value: discussion.id
        })
        this.setLikeProperty({
          property: 'like',
          value: false
        })
        this.setLikeProperty({
          property: 'dislike',
          value: true
        })
        this.likeDislike().then(() => {
          this.$emit('liked')
        })
      },
    },
  }
</script>

<style scoped>
  .background-images {
    height: 0;
  }

  .big-semi-circle {
    position: relative;
    right: 32%;
    top: 240px;
    width: 127px;
    height: 63px;
    transform: matrix(0, -1, -1, 0, 0, 0);
    background: rgba(183, 158, 138, 0.5);
  }

  .big-semi-circle-right {
    position: absolute;
    left: 93.3%;
    bottom: 13%;
    width: 127px;
    height: 63px;
    transform: matrix(0, 1, 1, 0, 0, 0);
    background: rgba(183, 158, 138, 0.5);
  }

  .small-semi-circle {
    position: relative;
    width: 41.71px;
    height: 20.86px;
    right: 21%;
    bottom: 56px;
    transform: rotate(-105.01deg);
    background: rgba(66, 61, 61, 0.35);
  }

  .small-semi-circle-right-upper {
    position: relative;
    width: 41.71px;
    height: 20.86px;
    left: 100%;
    top: 56px;
    transform: rotate(-105.01deg);
    background: rgba(66, 61, 61, 0.35);
  }

  .small-semi-circle-left-middle {
    position: relative;
    width: 41.71px;
    height: 20.86px;
    right: 16%;
    top: 680px;
    transform: rotate(-45deg);
    background: rgba(66, 61, 61, 0.35);
  }

  .semi-circle {
    border-radius: 150px 150px 0 0;
  }

  .upper-polygon {
    position: relative;
    width: 36.08px;
    height: 29.12px;
    top: 55px;
    left: 10%;
  }

  .middle-polygon-right {
    position: relative;
    width: 36.08px;
    height: 29.12px;
    top: 680px;
    left: 118%;
  }

  .lower-polygon {
    position: absolute;
    width: 36.08px;
    height: 29.12px;
    bottom: 9%;
    left: 4%;
  }

  .lower-polygon-right {
    position: absolute;
    width: 36.08px;
    height: 29.12px;
    bottom: 9%;
    right: 10%;
  }
</style>

<style scoped>
  .v-card__text {
    padding: 0px !important;
  }

  .community-info {
    padding: 55px 18% !important;
  }

  .community-info .image {
    margin-left: 16px !important;
  }

  .large-divider {
    width: 67px !important;
    height: 0px !important;
    border: 3px solid #423D3D;
    border-radius: 3px !important;
  }

  .small-divider {
    width: 15px !important;
    height: 0px !important;
    border: 3px solid #423D3D;
    border-radius: 3px !important;
  }

  .v-sheet.v-card {
    border-radius: 10px !important;
  }

  .user-info {
    position: absolute;
    bottom: 12px;
    width: 100%;
  }

  .user-info .col {
    max-width: fit-content !important;
  }

  .content-title {
    word-break: break-word;
  }
</style>