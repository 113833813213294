<template>
    <v-container>
        <v-row>
            <v-col align="center" cols="12">
                <h2 class="text-decoration-underline">Society List</h2>
            </v-col>
            <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
                <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.push('/super-admin-password')" :ripple="false">
                    Change Password
                </v-btn>
                <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.push('/super-admin-payment')" :ripple="false">
                    Payment
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-simple-table class="table text-center">
                    <template>
                        <thead>
                            <tr>
                                <th class="text-center">Society Name</th>
                                <th class="text-center">Society ID</th>
                                <th class="text-center">Joining Date</th>
                                <th class="text-center">Contact Number</th>
                                <th class="text-center">Transaction ID</th>
                                <th class="text-center">Order ID</th>
                                <th class="text-center">Invoice</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(society, index) in societies.data" :key="index">
                                <td>{{society.society_name}}</td>
                                <td>{{society.registration_number}}</td>
                                <td>{{getDate(society.date_of_join)}}</td>
                                <td>{{society.mobile_number}}</td>
                                <td>{{society.society_payment.length>0?society.society_payment[0].registration_payment_id:''}}</td>
                                <td>{{society.society_payment.length>0?society.society_payment[0].registration_order_id:''}}</td>
                               <td>
                                    <v-icon v-if="society.society_payment.length>0" class="" style="color:#bf3422 !important;font-size:30px" @click="getInvoice(society.society_payment[0].registration_order_id)">mdi-file-pdf</v-icon>
                                    <span v-else></span>
                               </td>
                                <td>
                                    <v-tooltip top color="green">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn x-small plain
                                                @click="getUserDetails(society)"
                                                class="table-btn text-subtitle-2 px-0 font-weight-medium" tile
                                                color="green" v-bind="attrs" to="/super-admin-society-member-list"
                                                v-on="on">Details</v-btn>
                                        </template>
                                        <span>View More Details</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" no-gutters v-if="societies.data.length>0">
          <v-pagination
            v-model="page"
            :length="Math.ceil(societies.count/10)"
            :total-visible="7"
            color="primary"
            @input="fetchSocietiesWithPage($event)"
            @previous="fetchSocietiesWithUrl(societies.previous)"
            @next="fetchSocietiesWithUrl(societies.next)"
          ></v-pagination>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
    export default {
        data: () => ({
            page: 1
        }),
        computed: {
            ...mapGetters('society', {
                societies: 'getSocieties'
            })
        },
        methods: {
            ...mapActions('society', [
                'fetchSocieties',
                'fetchSocietiesWithPage',
                'fetchSocietiesWithUrl'
            ]),
            getDate(date) {
                return `${String(new Date(date).getDate()).padStart(2,'0')}/${String(new Date(date).getMonth()+1).padStart(2,'0')}/${new Date(date).getFullYear()}`
            },
            getInvoice(order_id) {
                window.open(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/payment-api/v-1/payment/invoice/pdf/?order_id=${order_id}`)
            },
            getUserDetails(society) {
                this.$router.push(`/super-admin-society-member-list/${society.society_name.replace(/\s/g, '-')}-${society.id}/`)
            }
        },
        mounted() {
            this.fetchSocieties()
        }
    }
</script>

<style scoped>
    .table thead th {
        font-size: 18px !important;
    }
</style>