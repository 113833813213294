import Vue from 'vue'
import ApiService from '../../services/api.service'
// import { TokenService } from '@/services/token.service'
import _ from 'lodash'

const state = {
  user: {},
  changePassword: {
    password: '',
    new_password: '',
    confirm_password: ''
  },
  familyDetails: [],
  users: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  userStatus: {
    status: '',
    user_id: ''
  },
  posts: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  post: {},
  postStatus: {
    post_status: '',
    post_id: ''
  },
  complaints: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  complaint: {},
  complaintsStatus: {
    status: '',
    complaints_id: ''
  },
  complaintsSolutionStatus: {
    solution_status: '',
    complaints_id: ''
  },
  residents: {
    next: null,
    previous: null,
    data: [],
    count: 0
  },
  approved_residents:[]
}

const mutations = {
  setUser(state, user) {
    Vue.set(state, 'user', {...user})
  },
  resetUser(state) {
    Vue.set(state, 'user', {})
  },
  setUserProperty(state, { property, value }) {
    Vue.set(state.user, property, value)
  },
  setFamily(state, family) {
    Vue.set(state, 'familyDetails', [...family])
  },
  setFamilyProperty(state, { index, property, value }) {
    console.log('***', index, property, value)
    Vue.set(state.familyDetails[index], property, value)
  },
  addFamilyMember(state, user_id) {
    state.familyDetails.push({
      "member_name": "",
      "relation": "",
      "user": user_id
    })
  },
  removeFamilyMember(state, index) {
    state.familyDetails.splice(index, 1)
  },
  setChangePasswordProperty(state, { property, value }) {
    Vue.set(state.changePassword, property, value)
  },
  resetChangePassword(state) {
    Vue.set(state.changePassword, 'password', '')
    Vue.set(state.changePassword, 'new_password', '')
    Vue.set(state.changePassword, 'confirm_password', '')
  },
  setUsers(state, users) {
    Vue.set(state, 'users', {...users})
  },
  setUserStatusProperty(state, { status, user_id }) {
    Vue.set(state.userStatus, 'status', status)
    Vue.set(state.userStatus, 'user_id', user_id)
  },
  setPosts(state, posts) {
    Vue.set(state, 'posts', {...posts})
    // Vue.set(state.posts, 'count', posts.count)
    // Vue.set(state.posts, 'next', posts.next)
    // Vue.set(state.posts, 'prev', posts.previous)
  },
  setPost(state, post) {
    Vue.set(state, 'post', { ...post })
  },
  setPostStatusProperty(state, { status, id }) {
    Vue.set(state.postStatus, 'post_status', status)
    Vue.set(state.postStatus, 'post_id', id)
  },
  setComplaints(state, complaints) {
    Vue.set(state, 'complaints', {...complaints})
  },
  setComplaintStatusProperty(state, { status, id }) {
    Vue.set(state.complaintsStatus, 'status', status)
    Vue.set(state.complaintsStatus, 'complaints_id', id)
  },
  setComplaintSolutionStatusProperty(state, { status, id }) {
    Vue.set(state.complaintsSolutionStatus, 'solution_status', status)
    Vue.set(state.complaintsSolutionStatus, 'complaints_id', id)
  },
  setComplaint(state, complaint) {
    Vue.set(state, 'complaint', { ...complaint })
  },
  setResidents(state, residents) {
    Vue.set(state, 'residents', {...residents})
  },
  setApprovedResidents(state, residents) {
    Vue.set(state, 'approved_residents', {...residents})
  },
  setComplaintsProperty(state, { index, property, value }) {
    Vue.set(state.complaints.data[index], property, value)
  }
}

const actions = {
  // create user for everyone.
  signupUser({getters}, formData ) {
    console.log('data', getters, formData)
    return ApiService.post('/user-api/v-1/user/', _.omit(formData, 'confirmPassword'))
  },

  createFamilyMembers(_, data) {
    return ApiService.post('/user-api/v-1/user-family/', data)
  },
  updateFamilyMembers(_, data) {
    return ApiService.post('/user-api/v-1/update-user-family/', data)
  },
  deleteFamilyMember(_, data) {
    return ApiService.post('/user-api/v-1/delete-user-family-member/', data)
  },

  fetchUsers({ commit }) {
    return ApiService.get('/user-api/v-1/get-all-users/').then((response) => {
      commit('setUsers', response.data)
    })
  },
  fetchUsersWithPage({ commit }, page) {
    return ApiService.get(`/user-api/v-1/get-all-users/?page=${page}`).then((response) => {
      commit('setUsers', response.data)
    })
  },
  fetchUsersWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setUsers', response.data)
    })
  },

  fetchUsersBySocietyId({ commit }, society_id) {
    return ApiService.get(`/user-api/v-1/get-users-by-society-id/?society_id=${society_id}`).then((response) => {
      commit('setUsers', response.data)
    })
  },
  fetchUsersBySocietyIdWithPage({ commit }, data) {
    return ApiService.get(`/user-api/v-1/get-users-by-society-id/?society_id=${data.society_id}&page=${data.page}`).then((response) => {
      commit('setUsers', response.data)
    })
  },
  fetchUsersBySocietyIdWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setUsers', response.data)
    })
  },

  updateUser({getters}) {
    let formData = new FormData()
    if(typeof getters.getUser.profile_photo === 'string') {
      for (let key in _.omit(getters.getUser, 'society', 'profile_photo')) {
        if (getters.getUser[key] != null) {
          formData.append(key, getters.getUser[key])
        }
      }
    } else {
      for (let key in _.omit(getters.getUser, 'society')) {
        if (getters.getUser[key] != null) {
          formData.append(key, getters.getUser[key])
        }
      }
    }
    return ApiService.post(`/user-api/v-1/update-user/`, formData)
  },

  updatePassword({ getters}) {
    return ApiService.post(`/user-api/v-1/update-password/`, _.omit(getters.getChangePassword, 'confirm_password'))
  },

  fetchUser({commit}) {
    return ApiService.get('/user-api/v-1/get-user/').then((response) => {
      commit('setUser', response.data)
    })
  },

  async fetchUserById(_, id) {
    const response = await ApiService.post('/user-api/v-1/get-user-by-id/', {user_id: id})
    return response.data.data
  },

  // async fetchUserById(_, userId) {
  //   const response = await ApiService.get(`/user-api/v-1/user/${userId}/`);
  //   return response.data
  // },

  fetchFamily({commit}) {
    return ApiService.get('/user-api/v-1/user-family/').then((response) => {
      commit('setFamily', response.data)
    })
  },

  updateStatus({dispatch, getters}) {
    return ApiService.post('/user-api/v-1/update-user-status/', getters.getUserStatus).then(() => {
      dispatch('fetchUsers')
    })
  },

  postStory({dispatch}, data) {
    return ApiService.post('/post-api/v-1/discussion/', data).then(()=>{
      dispatch('fetchPosts')
    })
  },

  updateStory({dispatch}, data) {
    return ApiService.post('/post-api/v-1/update-discussion/', data).then(()=>{
      dispatch('fetchPosts')
    })
  },

  deleteStory(_, id) {
    console.log('post_id', id)
    return ApiService.post('/post-api/v-1/delete-discussion/', {post_id: id})
  },
  
  fetchPosts({commit}) {
    return ApiService.get('/post-api/v-1/discussion/').then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchPostsWithPage({commit}, page) {
    return ApiService.get(`/post-api/v-1/discussion/?page=${page}`).then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchPostsWithUrl({commit}, url) {
    return ApiService.get(url).then((response) => {
      commit('setPosts', response.data)
    })
  },

  fetchPostById({commit}, post_id) {
    return ApiService.get(`/post-api/v-1/discussion/${post_id}`).then((response) => {
      commit('setPost', response.data)
    })
  },

  fetchAllPosts({commit}) {
    return ApiService.get('/post-api/v-1/get-all-discussion/').then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchAllPostsWithPage({commit}, page) {
    return ApiService.get(`/post-api/v-1/get-all-discussion/?page=${page}`).then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchAllPostsWithUrl({commit}, url) {
    return ApiService.get(url).then((response) => {
      commit('setPosts', response.data)
    })
  },
  
  fetchLimitedPosts({commit}) {
    return ApiService.get('/post-api/v-1/get-limited-discussion/').then((response) => {
      commit('setPosts', response.data)
    })
  },

  fetchUserPosts({commit}) {
    return ApiService.get('/post-api/v-1/get-user-discussion/').then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchUserPostsWithPage({commit}, page) {
    return ApiService.get(`/post-api/v-1/get-user-discussion/?page=${page}`).then((response) => {
      commit('setPosts', response.data)
    })
  },
  fetchUserPostsWithUrl({commit}, url) {
    return ApiService.get(url).then((response) => {
      commit('setPosts', response.data)
    })
  },

  updatePostStatus({ getters }) {
    return ApiService.post('/post-api/v-1/approve-discussion/', getters.getPostStatus)
  },

  postComplaintsAndGrievances({ getters }, data) {
    console.log('****', getters, data)
    return ApiService.post('/complaints-and-grievances-api/v-1/complaints-and-grievances/', data)
  },
  updateComplaintsAndGrievances({ getters }, data) {
    console.log('****', getters, data)
    return ApiService.post('/complaints-and-grievances-api/v-1/update-complaints-and-grievances/', data)
  },
  fetchComplaintById({commit}, complaint_id) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/complaints-and-grievances/${complaint_id}`).then((response) => {
      commit('setComplaint', response.data)
    })
  },
  
  fetchComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchComplaintsAndGrievancesWithUrl({ commit },url) {
    return ApiService.get(url).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  
  fetchAllComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/get-all-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchAllComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/get-all-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchAllComplaintsAndGrievancesWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  
  fetchUserComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/get-user-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/get-user-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserComplaintsAndGrievancesWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setComplaints', response.data)
    })
  },

  fetchUserApprovedComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/get-user-approved-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserApprovedComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/get-user-approved-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserApprovedComplaintsAndGrievancesWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setComplaints', response.data)
    })
  },

  fetchUserPandingComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/panding-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserPandingComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/panding-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },

  fetchUserClosedComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/close-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserClosedComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/close-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },

  fetchUserRejectedComplaintsAndGrievances({ commit }) {
    return ApiService.get('/complaints-and-grievances-api/v-1/rejected-complaints-and-grievances/').then((response) => {
      commit('setComplaints', response.data)
    })
  },
  fetchUserRejectedComplaintsAndGrievancesWithPage({ commit }, page) {
    return ApiService.get(`/complaints-and-grievances-api/v-1/rejected-complaints-and-grievances/?page=${page}`).then((response) => {
      commit('setComplaints', response.data)
    })
  },
  
  updateComplaintsAndGrievancesStatus({ getters }) {
    return ApiService.post('/complaints-and-grievances-api/v-1/approve-complaints-and-grievances/', getters.getComplaintStatus)
  },
  
  updateComplaintsAndGrievancesSolutionStatus({ getters }) {
    return ApiService.post('/complaints-and-grievances-api/v-1/solution-status-complaints-and-grievances/', getters.getComplaintSolutionStatus)
  },
  deleteComplaintsAndGrievances(_, id) {
    return ApiService.post('/complaints-and-grievances-api/v-1/delete-complaint/', {complaints_id: id})
  },
  
  checkUserExist(_, { userData }) {
    return ApiService.post('/user-api/v-1/get-user-by-mobile-number/', userData)
  },
  forgotPassword(_, { userData }) {
    return ApiService.post('/user-api/v-1/forgot-password/', userData)
  },

  fetchResidents({ commit }) {
    return ApiService.get('/user-api/v-1/get-approved-user/').then((response) => {
      commit('setResidents', response.data)
    })
  },
  fetchApprovedResidents({ commit }) {
    return ApiService.get('/user-api/v-1/get-approved-residents/').then((response) => {
      commit('setApprovedResidents', response.data)
    })
  },
  fetchResidentsWithPage({ commit }, page) {
    return ApiService.get(`/user-api/v-1/get-approved-user/page=${page}`).then((response) => {
      commit('setResidents', response.data)
    })
  },
  fetchResidentsWithUrl({ commit }, url) {
    return ApiService.get(url).then((response) => {
      commit('setResidents', response.data)
    })
  },

  SendOTPUser(_, data) {
    return ApiService.post('/user-api/v-1/sent-otp/', data)
  },
  verifyOTPUser(_, data) {
    return ApiService.post('/user-api/v-1/verify-otp/', data)
  }
}

const getters = {
  getUser: state => state.user,
  getFamily: state => state.familyDetails,
  getChangePassword: state => state.changePassword,
  getUsers: state => state.users,
  getUserStatus: state => state.userStatus,
  getPosts: state => state.posts,
  getPost: state => state.post,
  getPostStatus: state => state.postStatus,
  getComplaints: state => state.complaints,
  getComplaintStatus: state => state.complaintsStatus,
  getComplaintSolutionStatus: state => state.complaintsSolutionStatus,
  getResidents: state => state.residents,
  getApprovedResidents: state => state.approved_residents,
  getComplaint: state => state.complaint
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
