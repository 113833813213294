<template>
    <v-container class="container">
        <v-row class="row">
            <v-col cols="6" class="d-flex justify-center">

                <v-card elevation="0">
                    <v-alert
                        color="warning"
                        dark
                        icon="mdi-alert"
                        dense
                    >
                        Please do not refresh this page to make successful payment.
                    </v-alert>
                    <v-card-text>
                        <span class="text-h5 font-weight-bold" style="color:#0d2366;">
                            National Society Registration
                        </span>
                        <hr class="line1">
                    </v-card-text>
                    <v-card-text>
                        <div class="text-h6 font-weight-medium" style="color:#0d2366;">
                            Program Name
                        </div>
                        <div style="color:#515978">#National Society</div>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-h6 font-weight-medium" style="color:#0d2366;">
                            Program Description
                        </div>
                        <div style="color:#515978">
                            Registration for {{payment.cust_name}}
                        </div>
                    </v-card-text>
                    <v-card-text>
                        <div class="text-h6 font-weight-medium" style="color:#0d2366;">
                            Contact Us:
                        </div>
                        <div style="color:#515978">
                            <v-icon class="icon">mdi-email</v-icon>
                            <span class="pl-2">info@nationalsociety.in</span>
                        </div>
                        <div class="mt-2" style="color:#515978">
                            <v-icon class="icon">mdi-phone</v-icon>
                            <span class="pl-2">8822774191</span>
                        </div>
                    </v-card-text>

                </v-card>

            </v-col>
            <v-col cols="6" class="">
                <v-card class="pl-3" max-width="450" outlined>
                    <v-card-title>
                        Payment Details
                    </v-card-title>
                    <hr class="line">
                    <v-card-text>
                        <v-row no-gutters class="my-0 py-0">
                            <v-col cols="2" class="mr-4 pt-2 d-flex justify-start"> Fee: </v-col>
                            <v-col class="" style="max-width:40%;min-width:300px">
                                <v-text-field label="Fee" prepend-inner-icon="mdi-currency-inr" outlined dense readonly :value="fee"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0 py-0">
                            <v-col cols="2" class="mr-4 pt-2 d-flex justify-start"> CGST: </v-col>
                            <v-col class="" style="max-width:40%;min-width:300px">
                                <v-text-field label="CGST" prepend-inner-icon="mdi-currency-inr" outlined dense readonly :value="gst"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0 py-0">
                            <v-col cols="2" class="mr-4 pt-2 d-flex justify-start"> SGST: </v-col>
                            <v-col class="" style="max-width:40%;min-width:300px">
                                <v-text-field label="SGST" prepend-inner-icon="mdi-currency-inr" outlined dense readonly :value="gst"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0 py-0">
                            <v-col cols="2" class="mr-4 pt-2 d-flex justify-start"> Total: </v-col>
                            <v-col class="" style="max-width:40%;min-width:300px">
                                <v-text-field label="Total" prepend-inner-icon="mdi-currency-inr" outlined dense readonly :value="total"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pl-0 ml-0" cols="8" style="background:#f5f6f7 !important">
                                <v-img :src="paymentLogo" contain></v-img>
                            </v-col>
                            <v-col  cols="4" class="text-uppercase font-weight-medium ml-0"
                                style="background: #1977d1; color:white; cursor: pointer;" @click="paymentStart">
                                 <span class="d-flex justify-center">
                                     Pay <v-icon class="rupee">mdi-currency-inr</v-icon>{{total}}
                                 </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
    import paymentLogo from '@/assets/pay_methods_branding.png'
    import PaymentMixins from '@/mixins/payment.js'
    import {
        mapActions, mapGetters, mapMutations
    } from 'vuex'
    export default {
        mixins: [
            PaymentMixins
        ],
        data: () => ({
            paymentLogo
        }),
        computed: {
            ...mapGetters('payment', {
                payment: 'getPayment'
            }),
            fee() {
                return Number(this.payment.amount)
            },
            gst() {
                return Number(this.payment.tax)/2
            },
            total() {
                return String(Math.ceil(Number(this.fee) + Number(this.payment.tax)))
            }
        },
        methods: {
            ...mapActions('payment', [
                'paymentInitiate',
                'paymentHandle',
                'invoiceLoader'
            ]),
            ...mapMutations('payment', [
                'resetPayment'
            ]),
            paymentStart() {
                console.log('JFJDSFKFJSDF DFSFJSA sdff')
                this.makePaymentCall(this.total, this.payment.order_id, 
                                    this.payment.cust_name, this.payment.cust_email,
                                    this.payment.cust_contact)
            },
            handleSuccess(response) {
                this.paymentHandle(response)
            },
            async makePaymentCall(amount, order_id, cust_name, cust_email, cust_contact) {
                console.log('***', amount)
                const result = await this.loadRazorPay()
                if (!result) {
                    alert('Failed to load razorpay script')
                    return
                }
                let optionsThis = this
                const options = {
                    key: process.env.VUE_APP_RAZORPAY_ID,
                    amount: amount,
                    currency: `INR`,
                    name: 'National Society',
                    description: `Society Registration Fees`,
                    order_id: order_id,
                    // image: `Your business logo`,
                    handler: function (response) {
                        // It is function executed on success
                        optionsThis.paymentHandle({
                            'response': response
                        }).then(() => {
                            // optionsThis.registrationSuccess = res.data['status']
                            window.open(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}/payment-api/v-1/payment/invoice/pdf/?order_id=${order_id}`)
                            optionsThis.resetPayment()
                            optionsThis.$router.push(`/registration?status=true`)
                        })
                    },
                    prefill: {
                        name: cust_name,
                        email: cust_email,
                        contact: cust_contact
                    },
                    modal: {
                        confirm_close: true,
                        animation: true,
                        handleback: true,
                        escape: true,
                        backdropclose: false
                    }
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.on('payment.failed', (response) => {
                    console.log('failed response', response)
                })
                paymentObject.open();
            },
        },
        mounted() {
            if(!this.payment.order_id) {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped>
    .icon {
        font-size: 14px;
        color: #0d2366;
    }
    .rupee{
        font-size: 14px;
        color: white;
    }

    .container::before {
        content: '';
        background-image: url('~@/assets/light_desktop.svg');
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background-position: right top;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: inherit;
    }


    .line {
        /* display: block; */
        width: 28px;
        border-color: #528ff0;
        border: 4px solid #528ff0;
        background: #528ff0;
        margin-left: 17px;
        margin-bottom: 20px;
    }

    .line1 {
        /* display: block; */
        width: 28px;
        border-color: #528ff0;
        border: 4px solid #528ff0;
        background: #528ff0;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .row {
        margin-top: 8%;
    }
</style>