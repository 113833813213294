<template>
    <v-container class="py-0 my-0">
        <v-row justify="center" v-show="check">
            <v-col cols="3" xl="2" class="pr-1 py-0">
                <v-card class="mx-0 left-container-card" height="100%" elevation="0">
                    <v-card-title class="text-center ma-auto" style="color:#62608B" >SOCIETY ID: {{society.registration_number}}</v-card-title>
                    <v-divider class="mr-2"></v-divider>
                    <v-list nav class="pl-0">
                        <v-list-item-group v-model="selectedListItem" color="primary">
                            <v-list-item class="py-0 my-0" v-for="(item, index) in profileList" :key="index"
                                :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item-group v-model="settingsListItem" color="primary">
                            <v-list-item class="py-0 my-0" v-for="(item, index) in settingsList" :key="index"
                                :disabled="item.disabled" :to="{path: '/account/'+item.path}">
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item-group v-model="bottomListItem" color="primary">
                            <v-list-item class="py-0 my-0" v-for="(item, index) in bottomList" :key="index"
                                :disabled="item.disabled" :to="{path: '/'+item.path}">
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="title-size" v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="9" xl="10" class="px-0 py-0">
                <v-card class="py-4 px-4" elevation="0">
                    <v-card-title class="text-center text-h4 font-weight-bold" style="color: #62608b">Add
                        Complaints</v-card-title>
                    <v-row align="center" justify="start" class="mx-1 mt-4">
                        <v-col cols="12" class="my-0 py-0">
                            <div v-if="!$v.title.required && $v.title.$dirty"
                                class="validation-text red--text text-body-2 error-span">Title required.</div>
                            <div v-if="!$v.title.maxLength" class="validation-text red--text text-body-2 error-span">
                                Title should
                                be 100 characters long.</div>
                            <v-text-field label="Title" class="rounded" v-model.trim="$v.title.$model" autofocus
                                outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                            <div v-if="!$v.description.required && $v.description.$dirty"
                                class="validation-text red--text text-body-2 error-span">Description required.</div>
                            <div v-if="!$v.description.maxLength"
                                class="validation-text red--text text-body-2 error-span">Description should be
                                {{$v.description.$params.maxLength.max}} characters long.</div>
                            <v-textarea label="What's your complaint?" class="rounded"
                                v-model.trim="$v.description.$model" auto-grow outlined dense>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                            <v-card-actions class="justify-start mx-0 px-0">
                                <v-btn color="#5da55b" min-width="100px" class="text-uppercase white--text"
                                    @click="postComplaint">Post</v-btn>
                                <v-btn color="error" min-width="100px" class="text-uppercase white--text"
                                    @click="$emit('cancel-add')">Cancel</v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="postComplete" width="500" persistent>
            <v-card justify="center" align="center" class="" rounded style="padding-top:40px;padding-bottom:40px">
                <v-card-text class="d-flex justify-center align-center px-0 py-0"
                    style="border-radius:50%; border:4px solid #f1f7ec;width:120px;height:120px;margin-bottom:25px">
                    <v-icon color="#abd193" style="font-size:80px">
                        mdi-check
                    </v-icon>
                </v-card-text>
                <v-card-text>
                    <span class="text-h4 font-weight-bold">Complaint posted successfully</span><br>
                    <span class="text-subtitle-2 text-capitalize">your complaint will be visible after admin approval</span>
                    <br>
                    <v-btn large depressed class="white--text rounded" color="#8bd4f5" style="margin-top:25px"
                        @click="finishedPost">
                        OK
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import {
        mapActions,
        mapGetters
    } from 'vuex'
    import {
        required,
        maxLength
    } from 'vuelidate/lib/validators'
    export default {
        data: () => ({
            title: '',
            description: '',
            postComplete: false,

            addComplaintDialog: false,
            showMore: false,
            linkText: 'more',
            overlay: false,
            page: 1,
            complaintPerPage: 5,
            selectedListItem: 0,
            settingsListItem: 0,
            bottomListItem: 0,
            profileList: [{
                    text: 'Profile',
                    icon: 'mdi-account',
                    disabled: false,
                    path: 'profile'
                },
                {
                    text: 'My Discussions',
                    icon: 'mdi-post',
                    disabled: false,
                    path: 'mydiscussion'
                },
                {
                    text: 'My Complaints',
                    icon: 'mdi-notebook-edit-outline',
                    disabled: false,
                    path: 'mycomplains'
                },
                {
                    text: 'Fees',
                    icon: 'mdi-currency-inr',
                    disabled: false,
                    path: 'fees'
                },
                // {
                //   text: 'Change Password',
                //   icon: 'mdi-lock',
                //   disabled: false,
                //   path: 'password'
                // },
            ],
            settingsList: [{
                text: 'Change Password',
                icon: 'mdi-lock',
                disabled: false,
                path: 'password'
            }, ],
            bottomList: [{
                text: 'Home',
                icon: 'mdi-home',
                disabled: false,
                path: ''
            }, ],
            showAlert: true,
            overlays: false,
            check: false
        }),
        computed: {
            ...mapGetters('user', {
                complaints: 'getComplaints'
            }),
            ...mapGetters('JWT', {
                isAdmin: 'isAdmin'
            }),
            ...mapGetters('settings', {
                settings: 'getSettings'
            }),
            ...mapGetters('society', {
                society: 'getSociety'
            }),
            currentPageComplaints() {
                return this.complaints.slice((this.page - 1) * this.complaintPerPage, (this.page - 1) * this
                    .complaintPerPage + this.complaintPerPage)
            }
        },
        validations() {
            return {
                title: {
                    required,
                    maxLength: maxLength(100)
                },
                description: {
                    required,
                    maxLength: maxLength(20000)
                }
            }
        },
        methods: {
            ...mapActions('user', [
                'postComplaintsAndGrievances',
                'fetchComplaintsAndGrievances',

                'fetchComplaintsAndGrievances',
                'fetchUserApprovedComplaintsAndGrievances',
            ]),
            ...mapActions('settings', [
                'fetchSettings',
            ]),
            ...mapActions('society', [
                'fetchSociety'
            ]),

            postComplaint() {
                let formData = new FormData()
                this.$v.title.$touch()
                this.$v.description.$touch()

                if (!this.$v.title.$invalid && !this.$v.description.$invalid && this.title && this.description) {
                    formData.append('title', this.title)
                    formData.append('description', this.description)
                    this.postComplaintsAndGrievances(formData)
                        .then(() => {
                            this.fetchComplaintsAndGrievances()
                            this.$emit('cancel-add')
                            this.postComplete = true
                        })
                }
            },

            finishedPost() {
                this.postComplete = false
                this.$router.push('/account/mycomplains')
            }
        },
        created() {
            this.overlays = true
            this.fetchSociety()
            this.fetchSettings().then(() => {
                if (this.isAdmin) {
                    this.profileList.shift()
                    this.profileList.shift()
                    this.profileList.shift()
                    this.profileList.shift()
                    this.settingsList.unshift({
                        'text': 'Settings',
                        'icon': 'mdi-cog-outline',
                        'disabled': this.isDefaultPassword,
                        'path': 'settings'
                    })
                    this.profileList.unshift({
                        'text': 'Fees',
                        'icon': 'mdi-currency-inr',
                        'disabled': this.isDefaultPassword,
                        'path': 'fees-settings'
                    })
                    this.profileList.unshift({
                        'text': 'Securities',
                        'icon': 'mdi-security',
                        'disabled': this.isDefaultPassword,
                        'path': 'security-setting'
                    })
                    if (this.settings.enable_events) {
                        this.profileList.unshift({
                            'text': 'Events',
                            'icon': 'mdi-calendar-today',
                            'disabled': this.isDefaultPassword || !this.settings.enable_events,
                            'path': 'events'
                        })
                    }
                    this.profileList.unshift({
                        'text': 'Complaints',
                        'icon': 'mdi-notebook-edit-outline',
                        'disabled': this.isDefaultPassword,
                        'path': 'complains'
                    })
                    if (this.settings.enable_posts) {
                        this.profileList.unshift({
                            'text': 'Discussion',
                            'icon': 'mdi-post',
                            'disabled': this.isDefaultPassword || !this.settings.enable_posts,
                            'path': 'discussion'
                        })
                    }

                    if (this.settings.enable_news) {
                        this.profileList.unshift({
                            'text': 'Notice Board',
                            'icon': 'mdi-information-outline',
                            'disabled': this.isDefaultPassword || !this.settings.enable_news,
                            'path': 'notice'
                        })
                    }
                    this.profileList.unshift({
                        'text': 'Residents',
                        'icon': 'mdi-account-check',
                        'disabled': this.isDefaultPassword,
                        'path': 'residents'
                    })
                } else {
                    if (!this.settings.enable_posts) {
                        this.profileList = this.profileList.filter((value) => {
                            return value.path !== 'myposts'
                        })
                    }
                }
                this.overlays = false
                this.check = true
            })
        }
    }
</script>
<style scoped>
    .validation-text {
        /* position: relative;
        bottom: 30px; */
    }

    .error-span {}

    .left-container-card {
        position: fixed;
        z-index: 1;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .title-size {
        font-size: 13px;
        text-transform: uppercase;
    }
</style>