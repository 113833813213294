<template>
  <v-container class="">
    <v-row class="py-5" justify="center" align="center">
      <searchBar label="Residents" :value="users.count" @searching="searchQuery=$event"></searchBar>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="table text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center font-weight-bold">Full Name</th>
                <th class="text-center font-weight-bold">Contact</th>
                <th class="text-center font-weight-bold">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in filteredUser" :key="index">
                <td>
                  <span v-if="user.full_name.length>10">
                    {{user.full_name.slice(0,10)+'...'}}
                  </span>
                  <span v-else>
                    {{user.full_name}}
                  </span>
                  </td>
                <td>{{user.mobile_number}}</td>
                <td class="">
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="2" style="width: fit-content !important;">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn small class="white--text rounded" color="black"
                            :to="{name: 'User', params: {id: user.id}}" v-bind="attrs" v-on="on" style="min-width: 30px !important; min-height:30px !important; width: 30px !important; height:30px !important;">
                            <v-icon dark>mdi-information-variant</v-icon>
                          </v-btn>

                        </template>
                        <span>User Details</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="white--text rounded" color="success"
                            @click="sendMessageToUser(user)" v-bind="attrs" v-on="on" style="min-width: 30px !important; min-height:30px !important; width: 30px !important; height:30px !important;">
                            <v-icon>mdi-comment-text</v-icon>
                          </v-btn>
                        </template>
                        <span>Send Message</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" v-if="user.is_active">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-subtitle-2 px-0 font-weight-medium" tile color="red"
                            @click="updateUserStatusProperty('deactive', user.id)" v-bind="attrs" v-on="on">Deactivate
                          </v-btn>
                        </template>
                        <span>Deactivate</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" v-else>
                      <v-tooltip top color="green">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small plain class="table-btn text-subtitle-2 px-0 font-weight-medium" tile color="green"
                            @click="updateUserStatusProperty('active', user.id)" v-bind="attrs" v-on="on">Approve</v-btn>
                        </template>
                        <span>Approve</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" align="center" no-gutters v-if="users.data.length>0">
          <v-pagination
            v-model="page"
            :length="Math.ceil(users.count/10)"
            :total-visible="7"
            color="primary"
            @input="fetchUsersWithPage($event)"
            @previous="fetchUsersWithUrl(users.previous)"
            @next="fetchUsersWithUrl(users.next)"
          ></v-pagination>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="showMessageBox" width="50%" persistent>
      <MessageBox :user="selectedUser" @cancel-message="closeMessageBox"></MessageBox>
    </v-dialog>
  </v-container>
</template>
<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import MessageBox from '@/components/MessageBox'
  import searchBar from '@/components/searchBar';

  export default {
    title: () => ('Resident Settings'),
    metaInfo: {
      title: 'Resident Settings'
    },
    components: {
      MessageBox,
      searchBar
    },
    data: () => ({
      overlay: true,
      searchQuery: '',
      showMessageBox: false,
      selectedUser: {},
      page: 1,
      userPerPage: 10,
      userItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ]
    }),
    computed: {
      ...mapGetters('user', {
        users: 'getUsers'
      }),
      filteredUser() {
        if (this.searchQuery) {
          return this.users.data.filter((user) => {
            return user.full_name.toLowerCase().startsWith(this.searchQuery.toLowerCase()) || user.mobile_number
              .toLowerCase().startsWith(this.searchQuery.toLowerCase()) || user.address.toLowerCase().startsWith(
                this.searchQuery.toLowerCase())
          })
        } else {
          return this.users.data
        }
      },
      // currentPageUsers() {
      //   return (this.userPerPage != null) ? this.filteredUser.slice((this.page - 1) * this.userPerPage, (this.page -
      //     1) * this.userPerPage + this.userPerPage) : this.filteredUser
      // }
    },
    methods: {
      ...mapActions('user', [
        'fetchUsers',
        'updateStatus',
        'fetchUsersWithPage',
        'fetchUsersWithUrl'
      ]),
      ...mapMutations('user', [
        'setUserStatusProperty'
      ]),
      updateUserStatusProperty(status, user_id) {
        this.setUserStatusProperty({
          status,
          user_id
        })
        this.updateStatus()
      },
      sendMessageToUser(user) {
        this.selectedUser = user
        this.showMessageBox = true
      },
      closeMessageBox() {
        this.selectedUser = {}
        this.showMessageBox = false
      }
    },
    mounted() {
      this.overlay = true
      this.fetchUsers().then(() => {
        this.overlay = false
      })
    }
  }
</script>
<style scoped>
  .table th {
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .table td {
    font-size: 14px !important;
    text-transform: uppercase;
  }

  .table-btn:hover {
    text-decoration: underline;
  }
</style>