<template>
  <v-container class="pt-10 ml-0 pl-0">
    <v-row>

      <v-col cols="12" class="ml-0 pl-0">
        <v-row>
          <v-col cols="12">
            <searchBar label="My Discussion" :value="posts.count" @searching="searchQuery=$event">
            </searchBar>
          </v-col>
          <v-col cols="6" class="pb-0 mb-0 pl-0 ml-0">
            <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline ml-0 pl-3" color="primary"
              plain to="/discussions" :ripple="false">
              view all discussions
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-0 mr-0 pb-0 mb-0">
            <v-btn class="mr-3 white--text rounded" color="#67ac5b" @click="$router.push('/addiscussion')">
              <v-icon class="mx-1">mdi-plus</v-icon>Add Discussion
            </v-btn>
          </v-col>
        </v-row>
        <v-card class="mb-3 my-6" elevation="0" v-for="(info, index) in filteredPosts" :key="index">

          <v-row class="ml-0 pl-0" align="center">

            <v-col class="ml-0 pl-0" cols="12" v-if="info.title || info.description">
              <v-row class="ml-0 pl-0">
                <v-card-title v-text="info.title" class="text-h5 pa-0 text-capitalize font-weight-medium text-justify"
                  style="font-size:18px !important;">
                </v-card-title>
                <v-card-text class="text-caption pa-0 text-capitalize" style="font-size:15px !important;">
                  <span class="text-decoration-underline text-capitalize font-italic">{{info.full_name}},</span>
                  <span class="pl-1 font-italic">{{getDate(info.added)}}</span>
                </v-card-text>
                <v-card-text class="text-subtitle-1 font-weight-regular px-0 py-2">
                  <span>{{info.description.slice(0, 100)}}...</span>
                  <a @click="postSelected(info)">{{linkText}}</a>
                </v-card-text>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="" justify="space-between">
            <v-col cols="6" class="pt-5">
              <span class="text-capitalize font-italic"
                style="font-size:15px !important;">Comments({{info.post_comment.length}})</span>
            </v-col>

            <v-col cols="6" class="d-flex justify-end mt-0 pt-0 mr-0 pr-0">

              <v-chip outlined label
                :color="info.post_status=='rejected'? 'red': info.post_status=='new'?'warning':'green'"
                class="mr-2 my-2 rounded text-uppercase subtitle-1">
                {{info.post_status=='new'?'pending':info.post_status}}</v-chip>

              <v-btn color="#7b7a81" @click="editPost(info)" small class="mx-2 my-2 ml-0 white--text ">Edit</v-btn>
              <v-btn color="#d63928" @click="deletePost(info)" small class="mx-2 my-2 ml-0 white--text ">Delete</v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-1 mb-3"></v-divider>

        </v-card>

        <v-row justify="center" align="center" class="mt-1 mb-3">
          <v-pagination v-model="page" :length="Math.ceil(posts.count/10)" :total-visible="7" color="primary"
            @next="fetchUserPostsWithUrl(posts.next)" @previous="fetchUserPostsWithUrl(posts.previous)"
            @input="fetchUserPostsWithPage($event)"></v-pagination>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showEdit" width="70%" persistent>
      <EditPost :post_id="editedPostId" :title="editedTitle" :description="editedDescription" :imageUrl="editedImage"
        @cancel-edit="showEdit=false" @title-changed="editedTitle=$event"
        @description-changed="editedDescription=$event" @imageUrl-changed="editedImage=$event"></EditPost>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600" persistent>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>Do you want to delete this discussion?</v-card-title>
            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="green--text" @click="confirmDelete">Yes</v-btn>
                <v-btn class="red--text" @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import EditPost from '@/components/EditPost.vue'
  import searchBar from '@/components/searchBar';
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'
  export default {
    title: () => ('My Posts'),
    metaInfo: {
      title: 'My Discussion'
    },
    components: {
      EditPost,
      searchBar
    },
    data: () => ({
      showMore: false,
      linkText: 'Read full discussion',
      disabledButtons: false,
      showEdit: false,
      editedPostId: new Number(),
      editedTitle: '',
      editedDescription: '',
      editedImage: undefined,
      deleteDialog: false,
      deletedPostId: new Number(),
      overlay: false,
      page: 1,
      postPerPage: 5,
      searchQuery: '',
      postItemsPerPage: [{
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '15',
          value: 15
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        },
        {
          text: 'All',
          value: null
        },
      ]
    }),
    computed: {
      ...mapGetters('user', {
        posts: 'getPosts'
      }),
      // currentPagePosts() {
      //   return this.postPerPage != null ? this.posts.data.slice((this.page - 1) * this.postPerPage, (this.page - 1) * this
      //     .postPerPage + this.postPerPage) : this.posts.data
      // }
      filteredPosts() {
        if (this.searchQuery) {
          return this.posts.data.filter((post) => {
            return this.searchQuery.toLowerCase().split(' ').every((search) => {
              return post.title.toLowerCase().includes(search) || post.description
                .toLowerCase().includes(
                  search) || post.full_name.toLowerCase().includes(search)
            })
          })
        } else {
          return this.posts.data
        }
      },
    },
    methods: {
      ...mapActions('user', [
        'fetchUserPosts',
        'updatePostStatus',
        'deleteStory',
        'fetchUserPostsWithPage',
        'fetchUserPostsWithUrl'
      ]),
      ...mapMutations('user', [
        'setPostStatusProperty',
        'setPost'
      ]),
      moreLess() {
        this.showMore = !this.showMore
        if (this.showMore) {
          this.linkText = 'less'
        } else {
          this.linkText = 'more'
        }
      },
      updatePostStatusProperty(status, id) {
        this.setPostStatusProperty({
          status,
          id
        })
        this.updatePostStatus().then(() => {
          this.fetchUserPosts()
        })
      },
      editPost(post) {
        this.editedPostId = post.id
        this.editedTitle = post.title
        this.editedDescription = post.description
        this.editedImage = post.media_file
        this.showEdit = true
      },
      deletePost(post) {
        this.deletedPostId = post.id
        this.deleteDialog = true
      },
      cancelDelete() {
        this.deletedPostId = new Number()
        this.deleteDialog = false
      },
      confirmDelete() {
        this.deleteStory(this.deletedPostId).then(() => {
          this.fetchUserPosts()
          this.deleteDialog = false
        })
      },
      postSelected(post) {
        this.setPost(post)
        this.$router.push(`/discussion/${post.title.replace(/\s/g, '-')}-${post.id}/`)
      },
      getDate(date) {
        return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
      },
    },
    mounted() {
      this.overlay = true
      this.fetchUserPosts().then(() => {
        this.overlay = false
        console.log('***', this.posts)
      })
    }
  }
</script>
<style scoped>
  .text-card {
    overflow-y: scroll;
    padding: 0;
  }
</style>