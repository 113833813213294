<template>
    <v-container class="notices">
        <v-row class="py-5" justify="center" align="center"
            style="margin-bottom:0 !important;padding-bottom:0px !important">

            <v-col xs="12" sm="12" md="3" lg="3" xl="3" class="d-flex justify-lg-start " style="padding-top:15px;">
                <p class="font-weight-bold text-caption pt-2" style="font-size:18px !important;">
                    Notices({{ notices.count }})
                </p>
            </v-col>

            <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="searchDiv " style="padding-top:15px">
                <v-text-field rounded color="#FFA500" v-model="searchQuery" dense label="Search" outlined
                    append-icon="mdi-magnify" single-line hide-details></v-text-field>
            </v-col>


            <v-col cols="12" class="d-flex justify-end pb-0 mb-0 mt-0 pt-0">
                <v-btn class="text-decoration-underline text-capitalize text-subtitle-1 btn-underline mr-0 pr-0"
                    color="primary" plain @click="$router.back()" :ripple="false">
                    Back
                </v-btn>
            </v-col>

        </v-row>


        <v-row align="center" class="pt-0 mt-0">

            <v-col v-if="filteredInformations.length==0">
                <v-card elevation="4" class="text-card" color="#fcefe9" height="">

                    <v-card-text class="text-h5 pa-0 text-capitalize font-weight-medium text-center font-italic pa-16"
                        style="font-size:18px !important;">
                        There are no notices to display
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col lg="4" md="6" sm="12" xs="12" v-else v-for="(item, index) in filteredInformations" :key="index">

                <v-card elevation="4" class="text-card pa-4" color="#fcefe9" height="">

                    <v-card-title v-if="item.title.length>33" v-text="item.title.slice(0,33) + '...'"
                        class="text-h5 pa-0 text-capitalize font-weight-medium text-justify "
                        style="font-size:18px !important;">
                    </v-card-title>
                    <v-card-title v-else v-text="item.title"
                        class="text-h5 pa-0 text-capitalize font-weight-medium text-justify "
                        style="font-size:18px !important;">
                    </v-card-title>
                    <div class="" style="height: 100px;">

                        <v-card-text class="text-subtitle-1 font-weight-regular pl-0 py-2 text-justify">
                            <span>{{item.description.slice(0, 100)}}...</span>
                            <a @click="newsClicked(item)">{{linkText}}</a>
                        </v-card-text>
                    </div>



                    <div class="" style="position:relative; bottom:0;">
                        <v-card-text class="text-caption text-capitalize pl-0 pb-0" style="font-size:15px !important">
                            <span class="text-capitalize font-italic">by Admin,</span>
                        </v-card-text>
                        <v-card-text class="text-caption text-capitalize pl-0 py-1 pb-0"
                            style="font-size:15px !important;">
                            <span class="text-capitalize font-italic">{{ getDate(item.added)}}</span>
                        </v-card-text>
                    </div>


                </v-card>

            </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="notices.data.length>0">

            <v-pagination 
                v-model="page"
                :length="Math.ceil(notices.count/10)"
                :total-visible="7"
                color="primary"
                @input="fetchNoticesWithPage($event)"
                @previous="fetchNoticesWithPage(notices.previous)"
                @next="fetchNoticesWithPage(notices.next)"
            ></v-pagination>
            <!-- <v-col cols="1">
                <v-select v-model="newsPerPage" :items="newsItemsPerPage" item-text="text" item-value="value"></v-select>
            </v-col> -->
        </v-row>


    </v-container>
</template>
<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex'
    export default {
        title: () => ('News Settings'),
        metaInfo: {
            title: 'Notices for National Society'
        },
        data: () => ({
            showMore: false,
            linkText: 'View full notice',
            searchQuery: '',
            overlay: false,
            editInformation: false,
            deleteDialog: false,
            disabledButtons: false,
            page: 1,
            newsPerPage: 10,
            newsItemsPerPage: [{
                    text: '5',
                    value: 5
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '15',
                    value: 15
                },
                {
                    text: '20',
                    value: 20
                },
                {
                    text: '100',
                    value: 100
                },
                {
                    text: 'All',
                    value: null
                },
            ]
        }),
        computed: {
            ...mapGetters('JWT', {
                isAdmin: 'isAdmin'
            }),
            ...mapGetters('event', {
                notices: 'getNotices',
                info: 'getNotice'
            }),
            filteredInformations() {
                if (this.searchQuery) {
                    return this.notices.data.filter((info) => {
                        return this.searchQuery.toLowerCase().split(' ').every((search) => {
                            return info.title.toLowerCase().includes(search) || info.description
                                .toLowerCase().includes(
                                    search)
                        })
                    })
                } else {
                    return this.notices.data
                }
            },
            // currentPageNews() {
            //     return this.newsPerPage != null ? this.filteredInformations.slice((this.page - 1) * this.newsPerPage, (
            //         this
            //         .page - 1) * this.newsPerPage + this.newsPerPage) : this.filteredInformations
            // }
        },
        methods: {
            ...mapActions('event', [
                'fetchNotices',
                'deleteNotice',
                'fetchNoticesWithPage',
                'fetchNoticesWithUrl'
            ]),
            ...mapMutations('event', [
                'setNotice',
                'setNoticeProperty'
            ]),
            newsClicked(item) {
                this.setNotice(item)
                this.$router.push(`/notice/${item.title.replace(/\s/g, '-')}-${item.id}/`)
            },
            moreLess() {
                this.showMore = !this.showMore
                if (this.showMore) {
                    this.linkText = 'less'
                } else {
                    this.linkText = 'View full notice'
                }
            },
            editData(info) {
                this.setNotice(info)
                this.editInformation = true
            },
            confirmDelete() {
                this.deleteNotice({
                    event_id: this.selectedInformation.id
                }).then(() => {
                    this.fetchNotices()
                    this.deleteDialog = false
                    this.disabledButtons = false
                })
            },
            cancelDelete() {
                this.deleteDialog = false
                this.disabledButtons = false
            },
            deleteSelectedInformation(event) {
                this.selectedInformation = event
                this.deleteDialog = true
                this.disabledButtons = true
            },
            getDate(date) {
                return `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
            }
        },
        mounted() {
            this.overlay = true
            this.fetchNotices().then(() => {
                console.log('notice', this.notices);
                this.overlay = false
            })
        }
    }
</script>
<style scoped>
 .btn-underline:hover {
        text-decoration: none !important;
    }
</style>