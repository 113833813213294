<template>
    <v-container class="space national-society">
      <v-row justify="space-between">
        <v-col xs="12" sm="4" md="4" lg="4" xl="4" class="py-16">
            <div class="text-left text-h3 font-weight-bold" style="color: #62608b">
                Your Society Your Life....
            </div>
            <v-form v-model="formValid" id="signin-form" @submit.prevent="signinFunction">
              <v-row>
                  <v-col class="" cols="12" style="padding-top:40px !important">
                      <p class="font-weight-light text-caption p-size" style="font-size:15.5px !important;">
                          Your society just got smarter with <strong>National Society.</strong>
                          Enjoy a hassle free life from the comfort of anywhere.
                      </p>
                  </v-col>

                  <v-col style="margin-top:-10px !important;" class="my-0 py-0">
                    <p class="font-weight-bold text-caption heading-size" style="font-size: 18px !important; color: #62608b;">
                        Society Login
                    </p>
                    <v-chip
                      class="float-start my-1"
                      color="#ed6237"
                      v-if="showError"
                      text-color="white"
                      label
                      style="width:100%;"
                      @click:close="showError=false"
                    >Wrong credentials.</v-chip>
                  </v-col>
                  <v-col cols="12" class="my-0 mt-1 py-0">
                    <span v-if="!$v.signinData.mobile_number.required && $v.signinData.mobile_number.$dirty" class="validation-text red--text text-body-2">Mobile number required.</span>
                    <span v-if="!$v.signinData.mobile_number.phoneNumber" class="validation-text red--text text-body-2">Mobile Number Must be valid.</span>
                    <v-text-field
                      autofocus
                      outlined
                      dense
                      v-model.trim="$v.signinData.mobile_number.$model"
                      label="Mobile Number"
                    ></v-text-field>
                  </v-col>

                  <v-col class="my-0 py-0" cols="12">
                    <v-radio-group v-model="typeOfAuth" column class="ma-0" @change="changeAuthType($event)">
                      <v-radio
                        label="Sign in with password"
                        value="password"
                      ></v-radio>
                      <v-radio
                        label="Sign in with OTP"
                        value="OTP"
                      ></v-radio>
                    </v-radio-group>
                    <!-- <otp /> -->
                  </v-col>

                  <v-col class="form-col my-0 py-0" v-if="typeOfAuth=='password'" cols="12">
                    <span v-if="!$v.signinData.password.required && $v.signinData.password.$dirty" class="validation-text red--text text-body-2">Password required.</span>
                    <span v-if="!$v.signinData.password.minLength" class="validation-text red--text text-body-2">Password must be atleast 8 charecter long.</span>
                    <v-text-field 
                      v-model="signinData.password"
                      label="Password"
                      outlined
                      dense
                      :type="showPassword ? 'text': 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>

                  <v-col v-else cols="12" class="my-0 py-0">
                    <span v-if="!$v.signinData.otp.required && $v.signinData.otp.$dirty" class="validation-text red--text text-body-2">OTP required.</span>
                    <span v-if="!$v.signinData.otp.numeric" class="validation-text red--text text-body-2">OTP should be numarical.</span>
                    <span v-if="!$v.signinData.otp.minLength || !$v.signinData.otp.maxLength" class="validation-text red--text text-body-2">OTP must be 4 charecter long.</span>
                    <v-text-field 
                      v-model="signinData.otp"
                      label="OTP"
                      outlined
                      dense
                      class="rounded"
                      :type="showPassword ? 'text': 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-btn plain color="#003366" width="30%" style="margin-top: -30px;" @click="sendOTP" class="text-uppercase d-flex justify-start px-0 mb-2 rounded">Resend</v-btn>
                  </v-col>

                  <v-col cols="12" class="py-0 my-0" v-if="typeOfAuth=='password'" >
                    <v-checkbox class="py-0 my-0" v-model="rememberMe" label="Remember me" :ripple="false"></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0 my-0" style="margin-top:-20px !important;">
                    <v-btn plain color="#003366" class="ma-0 pa-0 text-capitalize text-body-2 text-decoration-underline text-underline-btn" elevation="0" to="forgot-password" :ripple="false">Forgot password?</v-btn>
                  </v-col>

                  <v-col class="form-col my-0 py-0" cols="12">
                      <v-btn class="white--text btn-width" color="#477fca" type="submit" for="signin-form">
                          Continue
                      </v-btn>
                  </v-col>
              </v-row>
            </v-form>
            
        </v-col>
        <v-col xs="12" sm="8" md="8" lg="8" xl="8" style="height: 100vh">
            <v-img
              :lazy-src="building"
              height="100%"
              max-width="" 
              :src="building"
            ></v-img>
        </v-col>
      </v-row>
      <v-dialog v-model="sendOTPDialog" persistent width="30%">
        <v-card class="pb-5 rounded-lg">
          <v-card-title class="text-left text-h5 font-weight-bold py-5" style="color: #62608b">Do you want to sign in with OTP?</v-card-title>
          <v-card-actions class="mx-2">
            <!-- <v-spacer></v-spacer> -->
            <v-btn color="success" class="white--text text-subtitle-1 text-capitalize" min-width="100px" @click="sendOTP">Send OTP</v-btn>
            <v-btn color="#ed6237" class="white--text text-subtitle-1 text-capitalize" min-width="100px" @click="sendOTPDialog=false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
  // import otp from '@/components/otp.vue'
  // import building from '@/assets/building02.jpg'
  import building from '@/assets/building1.jpg'
  import { mapActions } from 'vuex'
  import { required, requiredIf, minLength, numeric, maxLength } from 'vuelidate/lib/validators'
  import { helpers } from 'vuelidate/lib/validators'

  const phoneNumber = helpers.regex('phoneNumber', (/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))

  export default {
    title: 'National Society',
    metaInfo: {
      title: 'National Society'
    },
    components: {
        // otp
    },
    data: () => ({
      building,
      signinData: {
        mobile_number: '' || localStorage.getItem('mobile_number'),
        password: '' || localStorage.getItem('password'),
        otp: ''
      },
      formValid: false,
      showError: false,
      rules: {
        phoneNumber: val => (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val) || /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(val)) || 'Phone Number Must be valid',
      },
      showPassword: false,
      isAdminSignin: false,
      rememberMe: false,
      typeOfAuth: "password",
      sendOTPDialog: false
    }),
    validations() {
      return {
        signinData: {
          mobile_number: {
            required,
            phoneNumber
          },
          password: {
            required: requiredIf(() => {
              return this.typeOfAuth == 'password'
            }),
            minLength: minLength(8)
          },
          otp: {
            required: requiredIf(() => {
              return this.typeOfAuth == 'OTP'
            }),
            numeric,
            minLength: minLength(4),
            maxLength: maxLength(4)
          }
        }
      }
    },
    watch: {
      rememberMe() {
        if(this.rememberMe) {
          localStorage.setItem('mobile_number', this.signinData.mobile_number)
          localStorage.setItem('password', this.signinData.password)
        } else {
          localStorage.removeItem('mobile_number')
          localStorage.removeItem('password')
        }
      },
      // showError() {
      //   if(this.showError) {
      //     setTimeout(()=> {
      //       this.showError = false
      //     }, 3000)
      //   }
      // }
    },
    computed: {
      mobileNumberError() {
        return this.$v.signinData.mobile_number.required
      }
    },
    methods: {
      ...mapActions('user', [
        'SendOTPUser'
      ]),
      ...mapActions('JWT', [
        'fetchJWT'
      ]),
      signinFunction() {
        this.$v.$touch()
        if(!this.$v.$invalid) {
          if(this.rememberMe) {
            localStorage.setItem('mobile_number', this.signinData.mobile_number)
            localStorage.setItem('password', this.signinData.password)
          } else {
            localStorage.removeItem('mobile_number')
            localStorage.removeItem('password')
          }
          if(this.typeOfAuth=='password') {
            delete this.signinData.otp
          } else if(this.typeOfAuth == 'OTP') {
            delete this.signinData.password
          }
          console.log('signin data', this.signinData)
          this.fetchJWT({formData: this.signinData})
          .then(() => {
            this.formValid = true
            this.showError = false
            // this.$router.push({path: '/'})
            location.reload()
          })
          .catch(() => {
            this.formValid = false
            this.showError = true
          })
        }
      },
      reset() {
        if(this.typeOfAuth=="password") {
          this.signinData.password = ''
        } else {
          this.signinData.otp = ''
        }
        this.signinData.mobile_number = ''
        this.$v.$reset()
      },
      forgotPassword() {
        console.log('clicked')
      },
      changeAuthType(authType) {
        // this.signinData = this.$options.data(this.signinData).signinData
        if(authType=='password') {
          this.sendOTPDialog = false
        } else if(authType == 'OTP') {
          this.sendOTPDialog = true
        }
        this.$v.$reset()
      },
      sendOTP() {
        delete this.signinData.password
        this.signinData.otp_type = 'signup'
        this.SendOTPUser(this.signinData).then(() => {
          this.sendOTPDialog = false
        })
      }
    },
    mounted() {
      if(localStorage.getItem('mobile_number') && localStorage.getItem('password')) {
        this.rememberMe = true
      } else {
        this.rememberMe = false
      }
    }
  };
</script>
<style scoped>
/* .national-society {
  padding-top: 65px;
} */
.text-underline-btn:hover {
  text-decoration: none !important;
}
</style>

